import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import VaccineHeader from '../components/Service/Vaccine/VaccineHeader';
import Vaccineinfo from '../components/Service/Vaccine/VaccineInfo';
import VaccineDocuments from '../components/Service/Vaccine/VaccineDocuments';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';

export default function VaccineCertification() {
    return (
        <>
            <Header />
            <VaccineHeader />
            <Vaccineinfo />
            <VaccineDocuments />
            <Share />
            <Footer />
            <SideOverlap/>
        </>
    )
}
import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, TextField } from "@mui/material";
import { Clear } from "@mui/icons-material";
import Admin from "../Dashboard";
import { RobotoTypo } from "../../../utils/Typographies";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function StudentList() {
  const navigate = useNavigate();
  const [StudentsList, setStudentsList] = useState([]);

  const getCarousel = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getAllStudent`
      );
      console.log("response get ", response);
      if (response.status == 200) {
        setStudentsList(response.data);
      } else if (response.status == 403) {
        localStorage.clear();
        navigate("/admin/login");
      }
    } catch (error) {
      console.log("er ", error);
    }
  };

  useEffect(() => {
    getCarousel();
  }, []);
  console.log("StudentsList ", StudentsList);
  const handleEdit = (id) => {
    navigate(`/admin/studentDetails/${id}`);
  };
  return (
    <Admin>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sno</TableCell>
              <TableCell>Institute Name</TableCell>
              <TableCell>Student Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {StudentsList.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  {row?.StudentDetails?.personalDetails?.school_name ||
                    row?.StudentDetails?.personalDetails?.selected_school}
                </TableCell>
                <TableCell>
                  {row?.StudentDetails?.personalDetails?.name ||
                    row?.StudentDetails?.personalDetails?.student_name}
                </TableCell>
                <TableCell>
                  {row?.StudentDetails?.personalDetails?.email}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      handleEdit(row._id);
                    }}
                    aria-label="edit"
                  >
                    <VisibilityIcon/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Admin>
  );
}

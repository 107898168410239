import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import AcademicAssesmentHeader from '../components/Service/AcademicAssesment/AcademicAssesmentHeader';
import AcademicAssesmentForm from '../components/Service/AcademicAssesment/AcademicAssesmentForm';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';

export default function AcademicAssesment() {
    return (
        <>
            <Header />
            <AcademicAssesmentHeader />
            <AcademicAssesmentForm />
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}
import { Typography, styled } from '@mui/material';

export const BaskerTypo = styled(Typography)({
  fontFamily: 'Libre Baskerville, serif !important',
});

export const InterTypo = styled(Typography)({
  fontFamily: 'Inter, sans-serif !important',
});

export const RobotoTypo = styled(Typography)({
  fontFamily: 'Roboto, sans-serif !important',
});

export const TimesNewTypo = styled(Typography)({
  fontFamily: 'Times New Roman, Times, serif !important',
});

export const NanoSans = styled(Typography)({
  fontFamily: "Noto Sans TC, serif !important"
});

export const GenYo = styled(Typography)({
  fontFamily: "GenYoGothic TW TTF !important"
});

export const ErrTypo = styled(Typography)({
  fontFamily: 'Roboto, sans-serif !important',
  color: "#FF202E",
  fontSize: "14px",
  paddingTop: "6px"
})
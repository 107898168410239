import { Box, Button } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { RobotoTypo } from "../../utils/Typographies";
import curtin from "../../assets/Home/Recommendation/curtlinImg.jpg";
import jcu from "../../assets/Home/Recommendation/jcuImg.jpg";
import kaplan from "../../assets/Home/Recommendation/kaplanImg.jpg";
import psb from "../../assets/Home/Recommendation/psbImg.jpg";
import sim from "../../assets/Home/Recommendation/simImg.jpg";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    position: "relative",
  },
  carousel: {
    marginBottom: "15px",
    position: "relative",
  },
  controlUl: {
    display: "flex",
    justifyContent: "center",
    listStyleType: "none",
  },
  controlDots: {
    position: "absolute !important",
    bottom: "0 !important",
    display: "flex !important",
    justifyContent: "center !important",
    listStyleType: "none !important",
    padding: 0,
  },
  controlDot: {
    margin: "0 4px",
    cursor: "pointer",
    width: "10px",
    height: "10px",
    borderRadius: "50px",
  },
}));

const Recommendation = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const carouselRef = useRef();
  const classes = useStyles();
  const [schoolList, setSchoolList] = useState([]);
  const [data, setData] = useState([]);
  const language = useSelector((state) => state.language.language);
  const navigate = useNavigate();
  const [carouselKey, setCarouselKey] = useState(0);

  const handlePageChange = (number) => {
    carouselRef.current.moveTo(number - 1);
  };

  const getUniversity = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getUniversities`
      );
      if (response.status === 200) {
        setSchoolList(response?.data);
        getData(
          Array.isArray(response?.data) ? response?.data : [response.data]
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUniversity();
  }, []);

  const getData = (data) => {
    let languageData;
    if (language === "en") {
      languageData =
        data?.length !== 0 &&
        data.map((item) => ({
          id: item._id,
          data: item.en,
        }));
    } else {
      languageData =
        data?.length !== 0 &&
        data.map((item) => ({
          id: item._id,
          data_en: item.en,
          data: item.ch,
        }));
    }
    setData(languageData);
    setCarouselKey((prevKey) => prevKey + 1); // Force re-render of carousel
  };

  useEffect(() => {
    if (schoolList?.length !== 0) {
      getData(schoolList);
    }
  }, [language]);

  const CustomIndicator = ({ length }) => {
    return (
      <ul className={classes.controlUl}>
        {[...Array(length)].map((_, index) => (
          <li
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
              backgroundColor:
                index + 1 === currentPage ? "#FFC424" : "#D9D9D9",
            }}
            className={`${classes.controlDot}`}
            onClick={() => handlePageChange(index + 1)}
          ></li>
        ))}
      </ul>
    );
  };

  return (
    <Box sx={{ position: "relative", backgroundColor: "#F7F7F7" }}>
      <Box
        sx={{
          display: "flex",
          position: "relative",
          width: "100%",
          gap: { xs: "30px", sm: "30px", md: "80px" },
          flexDirection: { xs: "column", sm: "column", md: "row" },
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: {
              xs: "none",
              md: "none",
              lg: "block",
              xl: "block",
              xxl: "block",
            },
            width: "90%",
            borderLeft: "8px solid white",
            borderColor: "#FFFFFF",
            position: "absolute",
            top: "0",
            right: "0",
            zIndex: 10000,
          }}
        ></Box>
        <Box
          sx={{
            border: "8px solid #B1D0E0",
            borderBottomRightRadius: { xs: "0", sm: "0", md: "30px" },
            height: "100px",
            width: { xs: "auto", sm: "auto", md: "30%", lg: "30%" },
            background: "#B1D0E0",
            display: "flex",
          }}
        ></Box>
        <Box
          sx={{
            xs: "100%",
            display: "flex",
            justifyContent: { xs: "center", sm: "center", md: "flex-start" },
            width: { xs: "100%", sm: "100%", md: "60%", lg: "60%" },
            alignItems: "center",
            ml: {
              xs: "0px",
              md: language === "ch" ? "12%" : "4%",
              xl: language === "ch" ? "16%" : "12%",
            },
          }}
        >
          <RobotoTypo
            sx={{
              color: "#003B5C",
              fontWeight: "700",
              fontSize: { xs: 24, md: 36 },
              textAlign: "center",
            }}
          >
            {t("Home.Recommendation.title")}{" "}
          </RobotoTypo>
        </Box>
      </Box>
      <Box
        className={classes.carouselContainer}
        sx={{ padding: "4%", cursor: "pointer" }}
      >
        <Carousel
          key={carouselKey} // Force re-render by changing key
          className={classes.carousel}
          showThumbs={false}
          showArrows={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={3500}
          showStatus={false}
          ref={carouselRef}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              setCurrentPage(index + 1);
            }
            return null;
          }}
        >
          {data?.map((item, index) => (
            <Box
              key={index}
              sx={{ borderRadius: "20px" }}
              onClick={() => navigate(`/school/${item.id}`)}
            >
              <Box
                component="img"
                sx={{
                  maxWidth: "1167px",
                  maxHeight: "374px",
                  borderRadius: "20px",
                  cursor: "pointer",
                }}
                src={`${process.env.REACT_APP_FILE_URL}${item.data.school_banner}`}
                alt="Carousel Image"
              />
            </Box>
          ))}
        </Carousel>
        <Box>
          <CustomIndicator length={data.length} />
        </Box>
      </Box>
    </Box>
  );
};

export default Recommendation;

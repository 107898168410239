import React from 'react'
import { Box } from '@mui/material';
import { RobotoTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';

export default function UniversityInfo() {
    const { t } = useTranslation();
    return (
        <Box sx={{ padding: '4%' }}>
            <RobotoTypo sx={{ fontWeight: '700', letterSpacing: '0.05em', color: '#003B5C', fontSize: { xs: '20px', sm: '25px', md: '28px', lg: '30px' }, textAlign: 'left' }}>
                University Tution
            </RobotoTypo><br />
            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: '16px', textAlign: 'left' }}>
            Our university Tuition services focus on providing students with professional, personalized tutoring to help them achieve better academic performance in various subject areas. We have an experienced education team who are proficient in the knowledge of various university courses and adopt targeted teaching methods. Whether it is psychology, information engineering or art design, business and marketing, etc., our tutoring covers a variety of university courses. Through regular assessments and personalized guidance, we ensure that students deeply understand the course content and formulate corresponding study plans. We aim to inspire students' passion for learning and enhance their abilities in various subject areas to help them achieve greater success on their academic path.
            </RobotoTypo>
        </Box>
    )
}

import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import EnglsihTrailHeader from '../components/StudyingAboard/EnglishTrailClass/EnglsihTrailHeader';
import EnglishTrailInfo from '../components/StudyingAboard/EnglishTrailClass/EnglishTrailInfo';
import EnglishTrailServiceIntro from '../components/StudyingAboard/EnglishTrailClass/EnglishTrailServiceIntro';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';

export default function EnglishTrailClass() {
    return (
        <>
            <Header />
            <EnglsihTrailHeader />
            <EnglishTrailInfo />
            <EnglishTrailServiceIntro />
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}
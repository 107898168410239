import React from 'react'
import StudyingHeader from '../components/StudyingAboard/StudyingHeader'
import Header from '../components/Header'
import Footer from '../components/Footer'
import EducationalCards from '../components/StudyingAboard/EducationalCards'
import Share from '../components/Share'
import SideOverlap from './sideOverlap';


export default function StudyingAboard() {
    return (
        <>
            <Header/>
            <StudyingHeader />
            <EducationalCards />
            <Share />
            <Footer />
            <SideOverlap/>
        </>
    )
}

import { Box, Typography, Link, Stack } from "@mui/material";
import React from "react";
import { BaskerTypo, RobotoTypo } from "../../utils/Typographies";
import VingaLogo from "../../assets/png/VingaLogo.png";
import { FooterInfo, CompanyIcon } from "../FooterData";
import { useTranslation } from "react-i18next";
import FooterShare from "./FooterShare";

const FooterBrandings = () => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        width: "100%",
        borderBottom: { xs: "none", md: "1px solid white" },
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "100%", md: "50% 50%" },
          gap: { xs: 4, md: 0 },
          p: { xs: 2, sm: 4 },
          placeItems: "center",
        }}
      >
        <Box
          sx={{
            color: "white",
            display: "grid",
            placeItems: "center",
            gridTemplateColumns: { xs: "auto", md: "70% 30%" },
            gap: { xs: 4, sm: 2 },
            justifyContent: "space-evenly",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                borderRight: "1px solid white ",
                pr: 2,
              }}
            >
              <BaskerTypo
                sx={{ fontSize: { xs: "38px", md: "64px" }, lineHeight: 1 }}
              >
                DR
              </BaskerTypo>
              <BaskerTypo
                sx={{
                  fontSize: { xs: "10px", md: "12px" },
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                Pacific Holdings
              </BaskerTypo>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
              <Typography sx={{ fontSize: { xs: 12, md: 14, lg: 16 } }}>
                迪傲太平洋控股有限公司
              </Typography>
              <RobotoTypo sx={{ fontSize: { xs: 12, md: 14, lg: 16 } }}>
                DR Pacific Holdings Pte Ltd
              </RobotoTypo>
            </Box>
          </Box>
          <img
            src={VingaLogo}
            alt="vinga"
            style={{
              objectFit: "contain",
              height: "auto",
              width: "auto",
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            pl: { xs: 0, sm: 4 },
          }}
        >
          {FooterInfo.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                color: "white",
                gap: 1,
              }}
            >
              {item.Icon}
              {item?.type ? (
                <RobotoTypo sx={{ fontSize: { xs: "12px", sm: "14px" } }}>
                  <Link
                    sx={{
                      textDecoration: "none",
                      color: "white !important",
                      "&:hover": {
                        textDecoration: "underline",
                        color: "#ffc424 !important",
                      },
                    }}
                    href={`mailto:${item.data}`}
                  >
                    {" "}
                    {item.data}
                  </Link>
                </RobotoTypo>
              ) : (
                <RobotoTypo sx={{ fontSize: { xs: "12px", sm: "14px" } }}>
                  {item.data}
                </RobotoTypo>
              )}
            </Box>
          ))}
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{ gap: { xs: 1, sm: 2 } }}
          >
            <Box sx={{ width: { xs: "20px", sm: "30px" }, ml: "6px" }}>
              {CompanyIcon.Icon}
            </Box>
            <RobotoTypo
              sx={{
                display: "flex",
                color: "white",

                mt: 0.5,
                fontSize: { xs: "12px", sm: "14px" },
              }}
            >
              {t("companyRegistration")} 200821037N
            </RobotoTypo>
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"flex-start"}
        sx={{
          width: "100%",

          px: { xs: 2, sm: 6 },
        }}
      >
        <FooterShare />
      </Box>
    </Stack>
  );
};

export default FooterBrandings;

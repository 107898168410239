import React from 'react'
import { Box } from '@mui/material';
import { RobotoTypo, TimesNewTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Circle } from '../../../assets/ServicesImages/StudentPassApplicationImages/circle.svg';


export default function LongTermPassInfo() {
    const { t } = useTranslation();
    return (
        <Box sx={{ padding: '4% 7%' }}>
            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', letterSpacing: '0.05em', fontSize: { xs: '22px', sm: '25px', md: '28px', lg: '30px' }, textAlign: 'left' }}>
                {t('service.longTermPassApplication.info', { returnObjects: true }).title}
            </RobotoTypo><br />
            {/* <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: '16px', textAlign: 'left' }}>
                {t('service.longTermPassApplication.info', { returnObjects: true }).description1}
            </RobotoTypo> */}
            <Box sx={{  display: 'flex', flexDirection: 'column',}}>
                {t('service.longTermPassApplication.info.bullets', { returnObjects: true }).map((item, index) => (
                    <Box key={index} sx={{ margin: '10px', display: 'flex', alignItems: 'center', gap: '20px' }}>
                        <Box

                        ><Circle /></Box>
                        <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '28px', color: '#242424', fontSize: { xs: '16px', md: '16px', lg: '16px' }, textAlign: 'left' }}>
                            {item}
                        </RobotoTypo>

                    </Box>
                ))}
            </Box>
        </Box>
    )
}

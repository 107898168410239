import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import OLevelHeader from '../components/StudyingAboard/OLevelPreCourse/OLevelHeader';
import OLevelInfo from '../components/StudyingAboard/OLevelPreCourse/OLevelInfo';
import OLevelServiceIntro from '../components/StudyingAboard/OLevelPreCourse/OLevelServiceIntro';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';

export default function OLevelPreCourse() {
    return (
        <>
            <Header />
            <OLevelHeader />
            <OLevelInfo />
            <OLevelServiceIntro/>
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}
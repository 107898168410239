import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import RentingApplicationHeader from '../components/Service/RentingApplication/RentingApplicationHeader';
import RentingApplicationForm from '../components/Service/RentingApplication/RentingApplicationForm';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';

export default function RentingApplication() {
    return (
        <>
            <Header />
            <RentingApplicationHeader />
            <RentingApplicationForm />
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}
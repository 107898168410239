import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import JobSeekingHeader from '../components/Service/JobSeeking/JobSeekingHeader';
import JobSeekingInfo from '../components/Service/JobSeeking/JobSeekingInfo';
import Share from '../components/Share';
import SideOverlap from './sideOverlap'
import JobSeekingServiceIntro from '../components/Service/JobSeeking/JobSeekingServiceIntro';

export default function JobSeeking() {
    return (
        <>
            <Header/>
            <JobSeekingHeader />
            <JobSeekingInfo />
            <JobSeekingServiceIntro/>
            <Share />
            <Footer />
            <SideOverlap/>
        </>
    )
}
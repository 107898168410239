import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { Slider } from '@mui/material';


const theme = createTheme({
    components: {
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
            },
            styleOverrides: {
                root: {
                    marginTop: '10px',
                    borderRadius: '5px',
                    backgroundColor: 'white',
                    boxShadow: '2px 2px 4px 0px #00000040 inset',
                    "& fieldset": { border: "none", },
                    "& .MuiInputBase-input": {
                        padding: '14px 14px',
                    },

                }
            }
        },
        MuiSelect: {
            defaultProps: {
                fullWidth: true,
            },
            styleOverrides: {
                root: {
                    marginTop: '10px',
                    borderRadius: '5px',
                    backgroundColor: 'white',
                    boxShadow: '2px 2px 4px 0px #00000040 inset',
                    "& fieldset": { border: "none", },
                    "& .MuiInputBase-input": {
                        padding: '14px 14px',
                    },

                }
            }
        },
        MuiButton: {
            styleOverrides: {
                Standard: {
                    width: "fit-content",
                    border: 'none',
                    color: "white",
                    backgroundColor: "#177D84",
                    textTransform: 'none',
                    fontSize: '18px',
                    borderRadius: '6px',
                    "&:hover": {
                        backgroundColor: '#177D84',
                        border: 'none',
                    }
                }

            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    border: "1px solid #eceef3",
                    width: "100%"
                }
            }
        }

    }

});



export const customSlider = {
    color: '#52af77',
    height: 8,
    "& .MuiSlider-rail": {
        backgroundColor: "#52af77",
        opacity: 1,
    },
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&::before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#52af77',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&::before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
}



export default theme;
import React, { useState } from 'react'
import { Box, TextField, RadioGroup, FormControlLabel, Radio, Button, useMediaQuery } from '@mui/material'
import { RobotoTypo } from '../../../utils/Typographies'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
    TextField: {
        "& .MuiInputBase-input": { padding: '10px 10px', borderRadius: '5px', backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset' },
        "& fieldset": { border: "none", }
    },

    selectIcon: {
        '& .MuiRadio-root': {
            color: 'transparent',
        },
        // '& .MuiRadio-root.Mui-checked': {
        //     color: "red"
        // }
    },
    label6: {
        "& .MuiFormControlLabel-label": {
            marginRight: "-40px"
        }
    },
    label9: {
        "& .MuiFormControlLabel-label": {
            marginRight: "-20px"
        }
    },
    label11: {
        "& .MuiFormControlLabel-label": {
            marginRight: "-18px"
        }
    },
    label15: {
        "& .MuiFormControlLabel-label": {
            marginRight: "-36px"
        }
    },

    radio: {
        '&$checked': {
            color: 'red',
        },
        '& .MuiIconButton-root': {
            padding: '10px',
            borderRadius: '50%',
            backgroundColor: 'white',
            boxShadow: '2px 2px 4px 0px #00000040 inset',
            border: 'none',
        },
        '& .MuiIconButton-root': {
            padding: '10px',
            borderRadius: '50%',
            backgroundColor: 'white',
            boxShadow: '2px 2px 4px 0px #00000040 inset',
            border: 'none',
        },

    },
    radios: {
        '&$checked': {
            color: 'red', // Set the color when the radio button is checked here
        },
    },

}));


export default function RentingForm() {
    const [qualification, setQualification] = React.useState('');
    const classes = useStyles();
    const { t } = useTranslation();
    const isExtraSmallScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    console.log("isEsc ", isExtraSmallScreen);

    const handleAgeChange = (event) => {
        setQualification(event.target.value);
    };


    const ageOptions = ['SSLC', 'HSC', 'BACHELOR DEGREE', 'MASTER DEGREE']

    return (
        <Box sx={{ padding: '4% 10%', backgroundColor: '#F5DFBA' }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '30px',
                backgroundColor: '#F7F7F7',
                padding: { xs: "20px 30px", sm: "40px 50px" },
                borderRadius: { xs: '30px', sm: "50px" },
            }}>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" }, justifyContent: "space-around" }}>
                    <Box sx={{ gap: "5px", width: { xs: "100%", lg: "45%" }, alignItems: { xs: "center", lg: "start" }, display: 'flex', flexDirection: { xs: 'column' } }}>
                        <RobotoTypo sx={{ fontWeight: '700', color: '#003B5C', fontSize: '22px', textAlign: { xs: 'center', sm: "left" } }}>
                            {t('service.rentingconsultation.rentingForm', { returnObjects: true }).title}
                        </RobotoTypo><br /><br />
                        <RobotoTypo sx={{ fontWeight: '400', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('service.rentingconsultation.rentingForm', { returnObjects: true }).RadioTitle1}<Box sx={{ color: '#FF202E', display: 'inline-block' }}>*</Box>
                        </RobotoTypo>
                        <RadioGroup
                            className={classes.selectIcon}
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            row={isExtraSmallScreen}
                            sx={{ width: "80%" }}
                        >
                            <FormControlLabel variant="outlined" InputProps={{ border: 'none' }} sx={{ color: "#003B5C", gap: "10px", flex: '1', width: '100%', marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio1value1} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset'
                            }} />} label={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio1value1} />
                            <FormControlLabel variant="outlined" InputProps={{ border: 'none' }} sx={{ color: "#003B5C", gap: "10px", flex: '1', width: '100%', marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio1value2} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset'
                            }} />} label={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio1value2} />
                            <FormControlLabel variant="outlined" InputProps={{ border: 'none' }} sx={{ color: "#003B5C", gap: "10px", flex: '1', width: '100%', marginBottom: { xs: "20px", lg: "0px" }, marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio1value3} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset'
                            }} />} label={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio1value3} />
                        </RadioGroup><br />
                        <RobotoTypo sx={{ fontWeight: '400', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('service.rentingconsultation.rentingForm', { returnObjects: true }).RadioTitle2}<Box sx={{ color: '#FF202E', display: 'inline-block' }}>*</Box>
                        </RobotoTypo>
                        <RadioGroup
                            className={classes.selectIcon}
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            row={isExtraSmallScreen}
                            sx={{ width: "80%" }}
                        >
                            <FormControlLabel variant="outlined" InputProps={{ border: 'none' }} sx={{ color: "#003B5C", gap: "10px", flex: '1', width: '100%', marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio1value1} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset'
                            }} />} label={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio2value1} />
                            <FormControlLabel variant="outlined" InputProps={{ border: 'none' }} sx={{ color: "#003B5C", gap: "10px", flex: '1', width: '100%', marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio1value2} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset'
                            }} />} label={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio2value2} />
                            <FormControlLabel className={!isExtraSmallScreen ? classes.label6 : ""} variant="outlined" InputProps={{ border: 'none' }} sx={{ marginBottom: { xs: "20px", lg: "0px" }, color: "#003B5C", gap: "10px", flex: '1', width: '100%', marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio1value3} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset',
                            }} />} label={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio2value3} />
                        </RadioGroup><br />
                        <RobotoTypo sx={{ fontWeight: '400', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('service.rentingconsultation.rentingForm', { returnObjects: true }).RadioTitle3}<Box sx={{ color: '#FF202E', display: 'inline-block' }}>*</Box>
                        </RobotoTypo>
                        <RadioGroup
                            className={classes.selectIcon}
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            row={isExtraSmallScreen}
                            sx={{ width: "80%" }}
                        >
                            <FormControlLabel variant="outlined" InputProps={{ border: 'none' }} sx={{ color: "#003B5C", gap: "10px", flex: '1', width: '100%', marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio1value1} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset'
                            }} />} label={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio3value1} />
                            <FormControlLabel variant="outlined" InputProps={{ border: 'none' }} sx={{ color: "#003B5C", gap: "10px", flex: '1', width: '100%', marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio1value2} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset'
                            }} />} label={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio3value2} />
                            <FormControlLabel className={!isExtraSmallScreen ? classes.label9 : ""} variant="outlined" InputProps={{ border: 'none' }} sx={{ marginBottom: { xs: "20px", lg: "0px" }, color: "#003B5C", gap: "10px", flex: '1', width: '100%', marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio1value3} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset'
                            }} />} label={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio3value3} />
                        </RadioGroup><br />
                        <RobotoTypo sx={{ fontWeight: '400', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('service.rentingconsultation.rentingForm', { returnObjects: true }).RadioTitle4}<Box sx={{ color: '#FF202E', display: 'inline-block' }}>*</Box>
                        </RobotoTypo>
                        <RadioGroup
                            className={classes.selectIcon}
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            row={isExtraSmallScreen}
                            sx={{ width: "55%" }}
                        >
                            <FormControlLabel variant="outlined" InputProps={{ border: 'none' }} sx={{ color: "#003B5C", gap: "10px", flex: '1', width: '100%', marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio1value1} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset'
                            }} />} label={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio4value1} />
                            <FormControlLabel className={!isExtraSmallScreen ? classes.label11 : ""} variant="outlined" InputProps={{ border: 'none', }} sx={{ color: "#003B5C", gap: "10px", flex: '1', width: '100%', marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio1value2} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset'
                            }} />} label={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio4value2} />

                        </RadioGroup>

                    </Box>
                    <Box sx={{ gap: "8px", width: { xs: "100%", lg: "45%" }, display: "flex", flexDirection: "column", marginTop: { xs: "40px", lg: "80px" }, }}>
                        <Box sx={{}}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('service.rentingconsultation.rentingForm', { returnObjects: true }).TextField1} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>*</Box>
                            </RobotoTypo>
                            <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                        </Box><br />
                        <Box sx={{}}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('service.rentingconsultation.rentingForm', { returnObjects: true }).TextField2} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>*</Box>
                            </RobotoTypo>
                            <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ marginBottom: { xs: "20px", lg: "0px" }, flex: '1', width: '100%', marginTop: "10px" }} />
                        </Box><br />
                        <RobotoTypo sx={{ fontWeight: '400', color: '#003B5C', fontSize: '18px', textAlign: { xs: "center", lg: 'left' } }}>
                            {t('service.rentingconsultation.rentingForm', { returnObjects: true }).RadioTitle5}<Box sx={{ color: '#FF202E', display: 'inline-block' }}>*</Box>
                        </RobotoTypo>
                        <RadioGroup
                            className={classes.selectIcon}
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            row={isExtraSmallScreen}
                            sx={{ width: "80%", alignSelf: { xs: "center", lg: "start" } }}
                        >
                            <FormControlLabel variant="outlined" InputProps={{ border: 'none' }} sx={{ color: "#003B5C", gap: "10px", flex: '1', width: '100%', marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio1value1} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset'
                            }} />} label={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio5value1} />
                            <FormControlLabel variant="outlined" InputProps={{ border: 'none' }} sx={{ color: "#003B5C", gap: "10px", flex: '1', width: '100%', marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio1value2} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset'
                            }} />} label={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio5value2} />
                            <FormControlLabel className={!isExtraSmallScreen ? classes.label15 : ""} variant="outlined" InputProps={{ border: 'none' }} sx={{ color: "#003B5C", gap: "10px", flex: '1', width: '100%', marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio1value3} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset'
                            }} />} label={t('service.rentingconsultation.rentingForm', { returnObjects: true }).Radio5value3} />
                        </RadioGroup><br />

                    </Box>

                </Box>

                <Box sx={{ textAlign: 'center' }}>
                    <Button disableElevation variant='contained' sx={{
                        mt: 5, borderRadius: '50px', textAlign: 'center', backgroundColor: '#FFC424',
                        '&:hover': {
                            backgroundColor: '#FFC424',
                        },
                    }} >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px', padding: { xs: '8px 10px', lg: "10px 16px" } }}>
                            <RobotoTypo sx={{ color: '#FFFFFF', letterSpacing: '0.02em', textTransform: 'none', lineHeight: '32px', fontWeight: '500', fontSize: { xs: 18, sm: 18, md: 21, lg: 22 } }}>{t('service.rentingconsultation.rentingForm', { returnObjects: true }).submit}</RobotoTypo>
                        </Box>
                    </Button>
                </Box>

            </Box>
        </Box >
    )
}

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  InputAdornment,
  IconButton,
} from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import OtpInput from "react18-input-otp";

const ForgotOtp = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [triggerOtp, setTriggerOtp] = useState(false);
  const [ini, setIni] = useState(true);
  const [timer, setTimer] = useState(60); // 1 minute timer
  const [showResend, setShowResend] = useState(false);
  const navigate = useNavigate();
  const [showResetPass, setShowResetPass] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const sendOtp = async () => {
    setError("");
    setSuccess("");
    setOtp("");
    setShowResend(false);
    setTimer(60);

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/forgot-password`,
        { email }
      );
      //   setSuccess("OTP sent successfully. Please check your email.");
      if (response.status === 200) {
        setTriggerOtp(true);
        setIni(false);
      }
    } catch (err) {
      setError(
        err.response?.data?.message || "Failed to send OTP. Please try again."
      );
    }
  };

  useEffect(() => {
    let timerInterval;
    if (triggerOtp && timer > 0) {
      timerInterval = setInterval(() => setTimer((prev) => prev - 1), 1000);
    } else if (timer === 0) {
      setShowResend(true);
      clearInterval(timerInterval);
    }
    return () => clearInterval(timerInterval);
  }, [triggerOtp, timer]);

  const handleOtpChange = (otp) => {
    setOtp(otp);
    setError("");
  };

  useEffect(() => {
    if (otp.length === 6) {
      verifyOtp();
    }
  }, [otp]);

  const verifyOtp = async () => {
    setError("");
    setSuccess("");

    if (otp.length !== 6) {
      setError("Please enter a 6-digit OTP.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/verify-otp-forgot`,
        { email, otp }
      );
      if (response.status === 200 && response.data?.verified) {
        setShowResetPass(true);
        setTriggerOtp(false);
      }
    } catch (err) {
      setError(err.response?.data?.message || "Invalid OTP. Please try again.");
    }
  };

  const handleUpadatePassword = async () => {
    setError("");
    setSuccess("");

    if (!password) {
      setError("Please enter a password.");
      return;
    }

    if (!confirmPassword) {
      setError("Please confirm the password.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/update-password`,
        { email, password }
      );
      if (response.status === 200) {
        setSuccess("Password reset successfully.");
        setTimeout(() => navigate("/login"), 2000);
      }
    } catch (err) {
      setError(
        err.response?.data?.message ||
          "Failed to reset password. Please try again."
      );
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#F5DFBA",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "10px",
          width: "400px",
          gap: "20px",
          padding: "40px",
          boxShadow: "0px 4px 8px 0px rgba(0,0,0,0.2)",
        }}
      >
        <Box>
          <ArrowBack onClick={() => navigate("/login")} />
        </Box>
        {ini && (
          <Box>
            <Box sx={{ marginTop: "10px" }}>
              <Typography
                sx={{ color: "#101828", fontSize: "20px", fontWeight: 600 }}
              >
                Recover Password
              </Typography>
            </Box>
            <Box sx={{ marginTop: "10px" }}>
              <Typography
                sx={{ color: "#101828", fontSize: "14px", fontWeight: 400 }}
              >
                Please enter your email address below.
              </Typography>
            </Box>

            <Box sx={{ marginTop: "10px" }}>
              <TextField
                sx={{
                  mt: 2,
                  mb: 2,
                  "& .MuiInputBase-root": { color: "#111927" },
                  backgroundColor: "#F9FAFB",
                }}
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError("");
                }}
                error={!!error}
              />
            </Box>

            <Button
              sx={{ marginTop: "10px", borderRadius: "6px" }}
              variant="contained"
              onClick={sendOtp}
            >
              Send OTP
            </Button>
            {error && (
              <Alert severity="error" sx={{ marginTop: "20px" }}>
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success" sx={{ marginTop: "20px" }}>
                {success}
              </Alert>
            )}
          </Box>
        )}
        {triggerOtp && (
          <>
            <Box sx={{ marginTop: "10px" }}>
              <Typography
                sx={{ color: "#101828", fontSize: "20px", fontWeight: 600 }}
              >
                Please enter your OTP
              </Typography>
            </Box>
            <Box sx={{ marginTop: "10px", display: "flex" }}>
              <Typography
                sx={{ color: "#101828", fontSize: "14px", fontWeight: 400 }}
              >
                An OTP has been sent to your email{" "}
                <span
                  style={{
                    color: "#101828",
                    fontSize: "15px",
                    fontWeight: 500,
                  }}
                >
                  {email}
                </span>
              </Typography>
            </Box>
            <OtpInput
              isInputNum={true}
              errorStyle={{
                backgroundColor: "#FEE4E2",
                color: "#912018",
                fontSize: "30px",
                fontWeight: 500,
              }}
              numInputs={6}
              value={otp}
              onChange={handleOtpChange}
              inputStyle={{
                border: "none",
                height: "60px",
                width: "55px",
                borderRadius: "7px",
                color: "#6C737F",
                fontSize: "30px",
                fontWeight: 500,
                backgroundColor: "#FCFCFD",
                margin: "30px 10px 0px 0px",
                marginBottom: "30px",
              }}
            />
            {timer > 0 && (
              <Typography sx={{ marginTop: "10px", color: "#6C737F" }}>
                Resend OTP in {timer} seconds
              </Typography>
            )}
            {showResend && (
              <Button
                sx={{ marginTop: "10px", borderRadius: "6px" }}
                variant="contained"
                onClick={sendOtp}
              >
                Resend OTP
              </Button>
            )}
            {error && (
              <Alert severity="error" sx={{ marginTop: "20px" }}>
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success" sx={{ marginTop: "20px" }}>
                {success}
              </Alert>
            )}
          </>
        )}
        {showResetPass && (
          <>
            <Box sx={{ marginTop: "10px" }}>
              <Typography
                sx={{ color: "#101828", fontSize: "20px", fontWeight: 600 }}
              >
                Update Password
              </Typography>
            </Box>
            <Box sx={{ marginTop: "20px" }}>
              <Typography>Password*</Typography>
              <TextField
                sx={{
                  mt: 1,
                  mb: 2,
                  "& .MuiInputBase-root": { color: "#111927" },
                  backgroundColor: "#F9FAFB",
                }}
                variant="outlined"
                fullWidth
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError("");
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box sx={{ marginTop: "10px" }}>
              <Typography> Confirm Password*</Typography>
              <TextField
                sx={{
                  mt: 1,
                  mb: 2,
                  "& .MuiInputBase-root": { color: "#111927" },
                  backgroundColor: "#F9FAFB",
                }}
                variant="outlined"
                fullWidth
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setError("");
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={toggleConfirmPasswordVisibility}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box sx={{ marginTop: "10px" }}>
              <Button
                sx={{
                  marginTop: "10px",
                  borderRadius: "6px",
                  backgroundColor: "#101828",
                }}
                variant="contained"
                onClick={handleUpadatePassword}
              >
                Update
              </Button>
            </Box>
            {success && (
              <Alert severity="success" sx={{ mt: 2 }}>
                {success}
              </Alert>
            )}
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ForgotOtp;

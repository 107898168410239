import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ExtraCurricularHeader from '../components/Service/Extracurricular/ExtraCurricularHeader';
import ExtraCurricularInfo from '../components/Service/Extracurricular/ExtraCurricularInfo';
import ExtraCurricularServiceIntro from '../components/Service/Extracurricular/ExtraCurricularServiceIntro';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';
import ExtraCurricularActivityTable from './ExtraCurricularActivityTable';

export default function ExtraCurricularActivity() {
    return (
        <>
            <Header />
            <ExtraCurricularHeader />
            <ExtraCurricularInfo />
            <ExtraCurricularServiceIntro/>
            <ExtraCurricularActivityTable/>
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}
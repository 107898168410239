import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import GuardianServiceHeader from '../components/Service/GuardianService/GuardianServiceHeader';
import GuardianServiceInfo from '../components/Service/GuardianService/GuardianServiceInfo';
import GuardianServiceIntro from '../components/Service/GuardianService/GuardianServiceIntro';
import Share from '../components/Share';
import SideOverlap from './sideOverlap'

export default function GuardianService() {
    return (
        <>
            <Header/>
            <GuardianServiceHeader />
            <GuardianServiceInfo />
            <GuardianServiceIntro />
            <Share />
            <Footer />
            <SideOverlap/>
        </>
    )
}
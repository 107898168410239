import React, { useState, useEffect } from "react";
import { Typography, Button, Menu, MenuItem, Avatar } from "@mui/material";
import { Box } from "@mui/system";
import { Outlet, useParams } from "react-router-dom";
import { RobotoTypo } from "../../utils/Typographies";
import { useNavigate } from "react-router-dom";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../redux/slice";
import { useTranslation } from "react-i18next";

function StudentDashboard() {
  const userData = localStorage.getItem("student_data")
    ? JSON.parse(localStorage.getItem("student_data"))
    : "";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language.language);
  const triggerOmise = useSelector((state) => state.language.triggerOmise);
  console.log(triggerOmise);
  const [lang, setLang] = useState(language);
  const boxStyle = {
    "& #nav-circle": {
      display: "none",
      textAlign: "center",
      transition: "display 0.5s",
    },
    "&:hover #nav-circle": {
      display: "block",
      position: "absolute",
      left: "25px",
    },
  };

  const langs = ["ch", "en"];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (item) => {
    if (item == "ch" || item == "en") {
      i18n.changeLanguage(item);
      setLang(item);
      dispatch(actions.toggleLanguage(item));
      setAnchorEl(null);
    }
    setAnchorEl(null);
  };
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://cdn.omise.co/omise.js";
  //   script.async = true;
  //   script.onload = () => {
  //     if (window.OmiseCard) {
  //       window.OmiseCard.configure({
  //         publicKey: "pkey_test_60fen2ih2w9ofin05qf",
  //       });
  //     }
  //   };
  //   document.body.appendChild(script);
  // }, []);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.omise.co/omise.js";
    script.async = true;
    script.onload = () => {
      if (window.OmiseCard) {
        window.OmiseCard.configure({
          publicKey: "pkey_test_60fen2ih2w9ofin05qf",
        });
      }
    };
    document.body.appendChild(script);
  }, []);
  useEffect(() => {}, [triggerOmise]);

  const navItems = [
    {
      name: "Profile",
      route: `/student/profile`,
      icon: <PermIdentityOutlinedIcon />,
    },
    {
      name: "Applications",
      route: `/student/applications`,
      icon: <AssignmentOutlinedIcon />,
    },
    {
      name: "Payments",
      route: `/student/payment`,
      icon: <AttachMoneyOutlinedIcon />,
    },
  ];
  const [currentPath, setCurrentPath] = useState(
    () => localStorage.getItem("currentPath") || `/student/profile`
  );

  useEffect(() => {
    navigate(currentPath);
  }, []);

  //   useEffect(() => {
  //     if (localStorage.getItem("token") == null) {
  //       navigate("/login");
  //     }
  //   }, []);

  useEffect(() => {
    localStorage.setItem("currentPath", currentPath);
  }, [currentPath]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#167d84",
          width: "100%",
          p: 0,
          m: 0,
          borderBottom: "2px solid #66c0b7",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            color: "white",
            p: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "17%",
          }}
        >
          <RobotoTypo sx={{ fontWeight: 700, fontSize: "40px" }}>DR</RobotoTypo>
          <RobotoTypo>Pacific Holdings</RobotoTypo>
        </Box>
        <Box sx={{ mx: 2 }}>
          <div>
            <Avatar
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{
                bgcolor: "#FFC424",
                cursor: "pointer",
                textTransform: "uppercase",
              }}
            >
              {lang}
            </Avatar>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {langs.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleClose(item)}
                  sx={{ textTransform: "uppercase" }}
                >
                  {item}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Box>
      </Box>

      <Box sx={{ width: "100%", display: "flex", p: 0, m: 0 }}>
        <Box
          sx={{
            borderRight: "1px solid #e5e5e5",
            height: "100vh",
            width: "17%",
            overflowY: "auto",
          }}
        >
          {navItems.map((data, i) => (
            <Box
              key={i}
              sx={{
                alignItems: "center",
                display: "flex",
                transition: "all 300ms ease-in-out",
                borderLeft:
                  currentPath === data.route ? "2px solid #167d84" : "",
                background: currentPath === data.route ? "#f2f2f2" : "",
                color: "",
                p: 1.5,
                ":hover": { cursor: "pointer" },
              }}
              onClick={() => {
                navigate(data.route);
                setCurrentPath(data.route);
              }}
            >
              <RobotoTypo
                sx={{
                  width: "20%",
                  color: currentPath === data.route ? "" : "#656565",
                }}
              >
                {data.icon}
              </RobotoTypo>
              <RobotoTypo
                sx={{
                  fontWeight: 700,
                  color: currentPath === data.route ? "black" : "#656565",
                }}
              >
                {data.name}
              </RobotoTypo>
            </Box>
          ))}
        </Box>

        <Box sx={{ p: 2, width: "83%" }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}

export default StudentDashboard;

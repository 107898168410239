import React, { useEffect, useState } from "react";
import Admin from "../../Dashboard";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteSchoolModal from "../../School/DeleteSchoolModal";

const EnrollmentApplication = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteSchool, setDeleteSchool] = useState("");
  const [deleteSchoolId, setDeleteSchoolId] = useState("");

  const getEnrollmentDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getEnrollment`
      );
      if (response.status == 200) {
        setData(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getEnrollmentDetails();
  }, []);
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/deleteEnrollment/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 200) {
        getEnrollmentDetails();
      }
    } catch (error) {
      if (error.response.status == 403) {
        localStorage.clear();
        navigate("/admin/login");
      }
    }
  };
  const handleDeleteSchool = (school, schoolId) => {
    setDeleteSchool(school);
    setDeleteSchoolId(schoolId);
    setOpen(true);
  };
  return (
    <Admin>
      <DeleteSchoolModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        school={deleteSchool}
        handleDelete={handleDelete}
        deleteSchoolId={deleteSchoolId}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          mb: 1,
        }}
      >
        <Button
          component="span"
          s
          variant="contained"
          sx={{
            alignSelf: "center",
            mt: 1,
            width: "fit-content",
            backgroundColor: "#177D84",
            "&:hover": { backgroundColor: "#177D84" },
          }}
          onClick={() => {
            navigate("/admin/addEnrollmentApplication");
          }}
        >
          Add
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sno</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length != 0 &&
              data?.map((team, index) => (
                <TableRow key={team._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{team.en.name}</TableCell>

                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteSchool(team.en.name, team._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton aria-label="edit">
                      <EditIcon
                        onClick={() => {
                          navigate(
                            `/admin/updateEnrollmentApplication/${team._id}`
                          );
                        }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Admin>
  );
};

export default EnrollmentApplication;

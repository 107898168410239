import React, { useState } from "react";
import {
  Box,
  TextField,
  Select,
  FormControl,
  Stack,
  MenuItem,
  InputLabel,
  Button,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@mui/material";
import { RobotoTypo, ErrTypo } from "../utils/Typographies";
import { makeStyles } from "@mui/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { ReactComponent as Hover1 } from "../assets/ContactUs/DateIcon.svg";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import formChat from "../assets/png/form_chat.png";
import formfb from "../assets/png/form_fb.png";
import formline from "../assets/png/form_line.png";
import formwp from "../assets/png/form_wp.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { emailRegex } from "../utils/common";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import ToastAlert from "../utils/Alert";

const useStyles = makeStyles((theme) => ({
  TextField: {
    "& .MuiInputBase-input": {
      padding: "10px 10px",
      borderRadius: "5px",
      backgroundColor: "white",
      boxShadow: "2px 2px 4px 0px #00000040 inset",
    },
    "& fieldset": { border: "none", padding: "0" },
  },

  selectIcon: {
    "& .MuiInputBase-input": {
      padding: "10px 10px",
      borderRadius: "5px",
      backgroundColor: "white",
      boxShadow: "2px 2px 4px 0px #00000040 inset",
    },
    "& fieldset": { border: "none" },
    "& .MuiSvgIcon-root.MuiSelect-icon": {
      color: "#177D84", // Set your desired color here
    },
  },
  adornment: {
    paddingRight: 0, // Adjust the padding around the InputAdornment
  },
}));

export default function Register({ userDetails, setUserDetails }) {
  const [qualification, setQualification] = React.useState("");
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const [registerDetails, setRegisterDetails] = useState({
    username: "",
    name: "",
    email: "",
    country_code: "",
    phone: "",
    contact_number: "",
    password: "",
    confirm_password: "",
  });
  const [errtext, seterrtext] = useState({
    username: "",
    name: "",
    email: "",
    country_code: "",
    phone: "",
    contact_number: "",
    password: "",
    confirm_password: "",
  });
  const [toastStatus, setToastStatus] = useState({
    color: "",
    status: false,
    message: "",
    icon: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleAgeChange = (event) => {
    setQualification(event.target.value);
  };

  const handleToggle = () => {
    setTermsAndCondition(!termsAndCondition);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevPassword) => !prevPassword);
  };

  const handleChange = (field, e) => {
    const { value } = e.target;
    setRegisterDetails((prev) => ({ ...prev, [field]: value }));
    seterrtext((prev) => ({ ...prev, [field]: "" }));
  };

  const handlephoneInput = (value, country, e) => {
    setRegisterDetails((prev) => ({
      ...prev,
      contact_number: value,
      country_code: `+ ${country.dialCode}`,
      phone: value.slice(country.dialCode.length),
    }));
    seterrtext((prev) => ({
      ...prev,
      contact_number: "",
      country_code: "",
      phone: "",
    }));
  };

  const validate = () => {
    let isvalid = true;

    console.log("validate");

    for (const key in registerDetails) {
      if (registerDetails[key] == "" && key != "username") {
        seterrtext((prev) => ({ ...prev, [key]: "Required Field!" }));
        isvalid = false;
      } else {
        if (key == "email" && !emailRegex.test(registerDetails.email)) {
          seterrtext((prev) => ({ ...prev, [key]: "Invalid mail id!" }));
          isvalid = false;
        }

        if (registerDetails[key] != "" && key == "confirm_password") {
          if (registerDetails[key] != registerDetails?.password) {
            seterrtext((prev) => ({ ...prev, [key]: "Password Mismatched" }));
            isvalid = false;
          }
        }
      }
    }
    return isvalid;
  };

  const AddStudentCredentials = async (data) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/addStudendCredentials`,
        data
      );
      console.log("resssssss ", response);
      if (response?.status === 201) {
        setIsLoading(false);
        setToastStatus({
          color: "green",
          status: true,
          icon: "tick",
          message: "Registered Successfully!",
        });
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }

      setUserDetails(response?.data?.data);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error.response?.status === 400) {
        setToastStatus({
          color: "red",
          status: true,
          icon: "error",
          message: error.response.data?.message || "Invalid data provided!",
        });
      } else {
        setToastStatus({
          color: "red",
          status: true,
          icon: "error",
          message: "Please try again later!",
        });
      }
    }
  };
  const handleConfirm = () => {
    if (validate()) {
      AddStudentCredentials(registerDetails);
    }
  };

  return (
    <Header>
      <ToastAlert
        message={toastStatus?.message}
        status={toastStatus?.status}
        color={toastStatus?.color}
        icon={toastStatus?.icon}
        closeFunc={() => setToastStatus({ color: "", status: false })}
      />
      <Box
        sx={{
          padding: { xs: "4% 10%", sm: "4% 8%", md: "4% 4%" },
          backgroundColor: "#F5DFBA",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            width: { xs: "100%", md: "50%" },
            backgroundColor: "#F7F7F7",
            padding: "30px",
            borderRadius: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "90px",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                gap: "30px",
              }}
            >
              <RobotoTypo
                sx={{
                  fontWeight: "700",
                  color: "#003B5C",
                  fontSize: "25px",
                  textAlign: "left",
                }}
              >
                Register
              </RobotoTypo>

              <Box
                rowGap={3}
                sx={{
                  display: "flex",
                  flexDirection: { md: "row", xs: "column" },
                  gap: "10px",
                }}
              >
                <Box sx={{ flex: "1" }}>
                  <RobotoTypo
                    sx={{
                      fontWeight: "500",
                      color: "#003B5C",
                      fontSize: "18px",
                      textAlign: "left",
                    }}
                  >
                    Username (passport name)
                  </RobotoTypo>
                  <TextField
                    onChange={(e) => handleChange("username", e)}
                    className={classes.TextField}
                    variant="outlined"
                    InputProps={{ border: "none" }}
                    sx={{ flex: "1", width: "100%", marginTop: "10px" }}
                  />
                </Box>
                <Box sx={{ flex: "1" }}>
                  <RobotoTypo
                    sx={{
                      fontWeight: "500",
                      color: "#003B5C",
                      fontSize: "18px",
                      textAlign: "left",
                    }}
                  >
                    Name{" "}
                    <Box sx={{ color: "#FF202E", display: "inline-block" }}>
                      {" "}
                      *
                    </Box>
                  </RobotoTypo>
                  <TextField
                    onChange={(e) => handleChange("name", e)}
                    className={classes.TextField}
                    variant="outlined"
                    InputProps={{ border: "none" }}
                    sx={{ flex: "1", width: "100%", marginTop: "10px" }}
                  />
                  <ErrTypo>{errtext.name}</ErrTypo>
                </Box>
              </Box>

              <Box sx={{ flex: "1" }}>
                <RobotoTypo
                  sx={{
                    fontWeight: "500",
                    color: "#003B5C",
                    fontSize: "18px",
                    textAlign: "left",
                  }}
                >
                  Email{" "}
                  <Box sx={{ color: "#FF202E", display: "inline-block" }}>
                    {" "}
                    *
                  </Box>
                </RobotoTypo>
                <TextField
                  onChange={(e) => handleChange("email", e)}
                  className={classes.TextField}
                  variant="outlined"
                  InputProps={{ border: "none" }}
                  sx={{ flex: "1", width: "100%", marginTop: "10px" }}
                />
                <ErrTypo>{errtext?.email}</ErrTypo>
              </Box>

              <Box sx={{ flex: "1" }}>
                <RobotoTypo
                  sx={{
                    fontWeight: "500",
                    color: "#003B5C",
                    fontSize: "18px",
                    textAlign: "left",
                  }}
                >
                  Contact Number{" "}
                  <Box sx={{ color: "#FF202E", display: "inline-block" }}>
                    {" "}
                    *
                  </Box>
                </RobotoTypo>
                {/* <TextField onChange={(e) => handleChange('contact_number', e)} className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} /> */}
                <PhoneInput
                  value={registerDetails?.contact_number}
                  onChange={(value, country, e) => {
                    handlephoneInput(value, country, e);
                  }}
                  // inputProps={{ name: "phone", maxLength: 20 }}
                  country={"cn"}
                  buttonStyle={{ border: "none" }}
                  inputStyle={{
                    height: "54px",
                    width: "100%",
                    marginTop: "10px",
                    borderRadius: "5px",
                    backgroundColor: "white",
                    boxShadow: "2px 2px 4px 0px #00000040 inset",
                    "& fieldset": { border: "none" },
                    "& .MuiInputBase-input": {
                      padding: "14px 14px",
                    },
                    // "&>.react-tel-input .flag-dropdown ": {
                    //     backgroundColor: 'white',
                    // }
                  }}
                  countryCodeEditable={false}
                />
                <ErrTypo>{errtext?.contact_number}</ErrTypo>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "90px",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box sx={{ flex: "1" }}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                Password{" "}
                <Box sx={{ color: "#FF202E", display: "inline-block" }}> *</Box>
              </RobotoTypo>
              <Box
                sx={{
                  position: "relative",
                  flex: "1",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <TextField
                  value={registerDetails?.password}
                  onChange={(e) => handleChange("password", e)}
                  InputProps={{
                    border: "none",
                    type: showPassword ? "text" : "password", // Toggle between text and password type
                  }}
                  className={classes.TextField}
                  variant="outlined"
                  sx={{ flex: 1, width: "100%" }}
                />
                <IconButton
                  className={classes.iconButton}
                  sx={{ position: "absolute", right: "20px", top: "15px" }}
                  onClick={togglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
                <ErrTypo>{errtext?.password}</ErrTypo>
              </Box>
            </Box>
          </Box>

          <Box sx={{ flex: "1" }}>
            <RobotoTypo
              sx={{
                fontWeight: "500",
                color: "#003B5C",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              Confirm Password{" "}
              <Box sx={{ color: "#FF202E", display: "inline-block" }}> *</Box>
            </RobotoTypo>
            <Box
              sx={{
                position: "relative",
                flex: "1",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <TextField
                value={registerDetails?.confirm_password}
                onChange={(e) => handleChange("confirm_password", e)}
                InputProps={{
                  border: "none",
                  type: showConfirmPassword ? "text" : "password", // Toggle between text and password type
                }}
                className={classes.TextField}
                variant="outlined"
                sx={{ flex: 1, width: "100%" }}
              />
              <IconButton
                className={classes.iconButton}
                sx={{ position: "absolute", right: "20px", top: "15px" }}
                onClick={toggleConfirmPasswordVisibility}
                edge="end"
              >
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              <ErrTypo>{errtext?.confirm_password}</ErrTypo>
            </Box>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={termsAndCondition}
              onChange={handleToggle}
              size="small"
            />{" "}
            <RobotoTypo
              sx={{
                fontWeight: "500",
                color: "#003B5C",
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              I have read and agreed to{" "}
              <a href="#" style={{ color: "#177D84" }}>
                the terms
              </a>{" "}
              and{" "}
              <a href="#" style={{ color: "#177D84" }}>
                conditions .
              </a>
            </RobotoTypo>
          </Box>

          <Box sx={{ textAlign: "center" }}>
            <Button
              onClick={() => handleConfirm()}
              disabled={!termsAndCondition}
              variant="contained"
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                borderRadius: "50px",
                textAlign: "center",
                backgroundColor: "#177d84",
                "&:hover": {
                  backgroundColor: "#177d84",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                  padding: "6px 5px",
                }}
              >
                <RobotoTypo
                  sx={{
                    color: "#FFFFFF",
                    letterSpacing: "0.02em",
                    textTransform: "none",
                    lineHeight: "32px",
                    fontWeight: "500",
                    fontSize: { xs: 16, sm: 18 },
                  }}
                >
                  Register
                </RobotoTypo>
              </Box>
              {isLoading && (
                <Box
                  sx={{
                    display: "flex",
                    alignSelf: "flex-end",
                    alignSelf: "center",
                    width: "30px",
                  }}
                >
                  <CircularProgress size={30} sx={{ color: "white" }} />
                </Box>
              )}
            </Button>
          </Box>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            columnGap={2}
          >
            <RobotoTypo
              sx={{
                fontWeight: "500",
                color: "#003B5C",
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              Already have an account ?
            </RobotoTypo>
            <Button
              onClick={() => navigate("/login")}
              variant="contained"
              sx={{
                borderRadius: "50px",
                textAlign: "center",
                backgroundColor: "#ffc424",
                "&:hover": {
                  backgroundColor: "#ffc424",
                },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <RobotoTypo
                  sx={{
                    padding: "0px 14px",
                    color: "#FFFFFF",
                    textTransform: "none",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  {" "}
                  Login Now !
                </RobotoTypo>
              </Box>
            </Button>
          </Stack>
        </Box>
        {/* <Stack sx={{
                    backgroundColor: "#b1d0e0", display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    gap: '30px',
                    width: { xs: '100%', md: '50%' },
                    backgroundColor: '#b1d0e0',
                    padding: '30px',
                    borderRadius: '0 0 15px 15px'
                }}>
                    <RobotoTypo sx={{ width: "fit-content", fontWeight: '400', borderRadius: "20px", backgroundColor: "#003b5c", color: 'white', fontSize: '16px', textTransform: "none", padding: '6px 14px' }}>
                        Other Login Method
                    </RobotoTypo>
                    <Stack direction="row" columnGap={2}>
                        <Box component="img" src={formChat} sx={{ width: "62px", height: "60px" }}></Box>
                        <Box component="img" src={formfb} sx={{ width: "62px", height: "60px" }}></Box>
                        <Box component="img" src={formline} sx={{ width: "62px", height: "60px" }}></Box>
                        <Box component="img" src={formwp} sx={{ width: "62px", height: "60px" }}></Box>
                    </Stack>
                </Stack> */}
      </Box>
      <Footer />
    </Header>
  );
}

import React, { useEffect, useState } from "react";
import Admin from "../Dashboard";
import { Box, Button, Grid, IconButton, Stack, TextField } from "@mui/material";
import { RobotoTypo } from "../../../utils/Typographies";
import { useNavigate, useParams } from "react-router-dom";
import { Clear } from "@mui/icons-material";
import ReactQuill from "react-quill";
import axios from "axios";

const AddHigherOfficial = () => {
  const [data, setData] = useState({
    role_en: "",
    role_ch: "",
    name_en: "",
    description_en: "",
    name_ch: "",
    description_ch: "",
  });
  const [dataError, setDataError] = useState({
    role: "",
    name_en: "",
    description_en: "",
    name_ch: "",
    description_ch: "",
  });
  const [images, setImages] = useState();
  const [file, setFile] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [fileError, setFileError] = useState("");
  const handleInputChange = (e, reqName) => {
    if (reqName == "description_en" || reqName == "description_ch") {
      setData((prev) => ({ ...prev, [reqName]: e }));
      setDataError((prev) => ({ ...prev, [reqName]: "" }));
      return;
    } else {
      const { name, value } = e.target;
      setData((prev) => ({ ...prev, [name]: value }));
      setDataError((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const addSchool = async (reqData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/addHeaderOfficial`,
        reqData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 201) {
        navigate('/admin/higherOfficial')
        
      }
    } catch (error) {
      if (error.response.status == 403) {
        localStorage.clear();
      }
    }
  };
  const updateSchool = async (reqData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/UpdateHeaderOfficial/${id}`,
        reqData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 201) {
        navigate('/admin/higherOfficial')
      }
    } catch (error) {
      if (error.response.status == 403) {
        localStorage.clear();
      }
    }
  };

  const validate = () => {
    let error = false;
    if (data.role_en == "") {
      setDataError((prev) => ({ ...prev, role_en: "Role is required" }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, role_en: "" }));
      error = false;
    }
    if (data.role_ch == "") {
      setDataError((prev) => ({ ...prev, role_ch: "Role is required" }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, role_ch: "" }));
      error = false;
    }
    if (data.name_en == "") {
      setDataError((prev) => ({ ...prev, name_en: "Name is required" }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, name_en: "" }));
      error = false;
    }
    if (data.name_ch == "") {
      setDataError((prev) => ({ ...prev, name_ch: "Name is required" }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, title_ch: "" }));
      error = false;
    }
    if (data.description_en == "") {
      setDataError((prev) => ({
        ...prev,
        description_en: "Description is required",
      }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, description_en: "" }));
      error = false;
    }
    if (data.description_ch == "") {
      setDataError((prev) => ({
        ...prev,
        description_ch: "Description is required",
      }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, description_ch: "" }));
      error = false;
    }

    console.log("length ", file?.length);
    if (imageUrl == "" && file?.length == 0) {
      setFileError("Please upload image");
      error = true;
    } else {
      setFileError("");
      error = false;
    }
    return error;
  };

  const toolbarOptions = [
    ["bold", "italic", "underline", "link"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ color: [] }, { background: [] }],
  ];
  const module = { toolbar: toolbarOptions };
  const { id } = useParams();
  const navigate = useNavigate();
  const getHeaderOfficialById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getHeaderDetailsById/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 200) {
        const headerOfficialData = response.data;

        setData({
          name_en: headerOfficialData.en.name,
          name_ch: headerOfficialData.ch.name,
          description_en: headerOfficialData.en.description,
          description_ch: headerOfficialData.ch.description,
          role_ch: headerOfficialData.ch.role,
          role_en: headerOfficialData.en.role,
          
        });
        setImageUrl(headerOfficialData.en.image);
      }
    } catch (error) {
      if (error.response.status == 403) {
        localStorage.clear();
        navigate("/admin/login");
      }
    }
  };
  useEffect(()=>{
    if(id){
      getHeaderOfficialById()
    }
  },[])
  const handleSubmit = () => {
    const validation = validate();
    console.log("va ", validation);
    if (!validation) {
      const form = new FormData();
        form.append("name_ch", data.name_ch);
        form.append("name_en", data.name_en);
        form.append("description_ch", data.description_ch);
        form.append("description_en", data.description_en);
        form.append("role_ch", data.role_ch);
        form.append("role_en", data.role_en);
        if(imageUrl == "") {
          console.log('with')
          form.append('image',file)
      } else {
          console.log('else')
          form.append('image',imageUrl)
      }
      if (!id) {
       
        // const formData = {
        //   name_ch: data.name_ch,
        //   name_en: data.name_en,
        //   description_ch: data.description_ch,
        //   description_en: data.description_en,
        //   role_ch: data.role_ch,
        //   role_en: data.role_en,
        //   image: file,
        // };
        console.log(file);

        addSchool(form);
      }
      else{
       updateSchool(form)
      }

    }
  };

  const handleImageChange = (e) => {
    console.log("beforeArray", e.target.files);
    const files = Array.from(e.target.files);
    console.log("files", files);
    const imagesArray = files.map((file) => URL.createObjectURL(file));

    setImages(imagesArray);
    console.log(images);
    setFile(e.target.files[0]);
    setImageUrl("");
  };
  const handleRemoveImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setFile([]);
  };
  return (
    <Admin>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Box sx={{ display: "flex", gap: "50px" }}>
          <Stack sx={{ width: "50%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 20, sm: 24 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              English
            </RobotoTypo>

            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Role
            </RobotoTypo>
            <TextField
              name="role_en"
              value={data.role_en}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.role_en != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                {dataError.role_en}
              </RobotoTypo>
            )}
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Name
            </RobotoTypo>
            <TextField
              name="name_en"
              value={data.name_en}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.name_en != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                {dataError.name_en}
              </RobotoTypo>
            )}
          </Stack>
          <Box sx={{ width: "50%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 24 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Chinese
            </RobotoTypo>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Role
            </RobotoTypo>
            <TextField
              name="role_ch"
              value={data.role_ch}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.role_ch != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                {dataError.role_ch}
              </RobotoTypo>
            )}
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Name
            </RobotoTypo>
            <TextField
              name="name_ch"
              value={data.name_ch}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.name_ch != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                {dataError.name_ch}
              </RobotoTypo>
            )}
          </Box>
        </Box>

        <input
          type="file"
          accept="image/*"
          // multiple
          style={{ display: "none" }}
          id="image-input"
          onChange={handleImageChange}
        />
        <label htmlFor="image-input">
          <Button
            component="span"
            variant="contained"
            sx={{
              mb: 2,
              backgroundColor: "#177D84",
              "&:hover": { backgroundColor: "#177D84" },
            }}
          >
            Select Image
          </Button>
          {fileError != "" && (
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 14, sm: 18 },
                color: "red",
                fontWeight: 500,
                my: 1,
              }}
            >
              {fileError}
            </RobotoTypo>
          )}
        </label>
        <Grid container spacing={2}>
          {imageUrl ? (
            <Grid item xs={6} md={4}>
              <Box style={{ position: "relative" }}>
                <Box
                  component="img"
                  src={`${process.env.REACT_APP_FILE_URL}${imageUrl}`}
                  alt={`Preview ${imageUrl}`}
                  style={{ width: "100%", height: "auto" }}
                />
                <IconButton
                  style={{ position: "absolute", top: 0, right: 0 }}
                  onClick={() => setImageUrl("")}
                >
                  <Clear />
                </IconButton>
              </Box>
            </Grid>
          ) : (
            images?.map((image, index) => (
              <Grid item key={index} xs={6} md={4}>
                <Box style={{ position: "relative" }}>
                  <Box
                    component="img"
                    src={image}
                    alt={`Preview ${index}`}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <IconButton
                    style={{ position: "absolute", top: 0, right: 0 }}
                    onClick={() => handleRemoveImage(index)}
                  >
                    <Clear />
                  </IconButton>
                </Box>
              </Grid>
            ))
          )}
        </Grid>

        <RobotoTypo
          sx={{
            textAlign: "center",
            width: "fit-content",
            fontSize: { xs: 18, sm: 20 },
            color: "#003B5C",
            fontWeight: 700,
          }}
        >
          English description
        </RobotoTypo>

        <ReactQuill
          value={data?.description_en}
          name="description_en"
          onChange={(e) => handleInputChange(e, "description_en")}
          modules={module}
          theme="snow"
          style={{ height: "150px", marginTop: "0px", marginBottom: "40px" }}
          placeholder=""
        />
        {dataError.description_en != "" && (
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 14, sm: 18 },
              color: "red",
              fontWeight: 500,
              my: 1,
            }}
          >
            {dataError.description_en}
          </RobotoTypo>
        )}
        <RobotoTypo
          sx={{
            textAlign: "center",
            width: "fit-content",
            fontSize: { xs: 18, sm: 20 },
            color: "#003B5C",
            fontWeight: 700,
            mt: 1,
          }}
        >
          Chinese description
        </RobotoTypo>

        <ReactQuill
          value={data?.description_ch}
          name="description_ch"
          onChange={(e) => handleInputChange(e, "description_ch")}
          modules={module}
          theme="snow"
          style={{ height: "150px", marginTop: "0px", marginBottom: "40px" }}
          placeholder=""
        />
        {dataError?.description_ch != "" && (
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 14, sm: 18 },
              color: "red",
              fontWeight: 500,
              my: 1,
            }}
          >
            {dataError?.description_ch}
          </RobotoTypo>
        )}
        <Button
          component="span"
          onClick={handleSubmit}
          variant="contained"
          sx={{
            alignSelf: "center",
            mt: 1,
            width: "fit-content",
            backgroundColor: "#177D84",
            "&:hover": { backgroundColor: "#177D84" },
          }}
        >
          {id ? "Edit" : "Add"}
        </Button>
      </Box>
    </Admin>
  );
};

export default AddHigherOfficial;

import React from "react";
import { Box } from "@mui/material";
import { RobotoTypo } from "../../../utils/Typographies";
import { useTranslation } from "react-i18next";
import { ReactComponent as Circle } from "../../../assets/ServicesImages/StudentPassApplicationImages/circle.svg";
import { ReactComponent as Airport } from "../../../assets/ServicesImages/LandingServiceImages/Airport.svg";
import { ReactComponent as Appointment } from "../../../assets/ServicesImages/LandingServiceImages/Appointment.svg";
import { ReactComponent as CollectMedical } from "../../../assets/ServicesImages/LandingServiceImages/CollectMedical.svg";
import { ReactComponent as MedicalCheckup } from "../../../assets/ServicesImages/LandingServiceImages/MedicalCheckup.svg";
import { ReactComponent as NIR } from "../../../assets/ServicesImages/LandingServiceImages/NIR.svg";
import { ReactComponent as Simcard } from "../../../assets/ServicesImages/LandingServiceImages/Simcard.svg";
import { ReactComponent as StudentPass } from "../../../assets/ServicesImages/LandingServiceImages/StudentPass.svg";
import { ReactComponent as UploadMedical } from "../../../assets/ServicesImages/LandingServiceImages/UploadMedical.svg";
import BankAccountImg from "../../../assets/ServicesImages/LandingServiceImages/bankaccount.png";
import SingPassAccount from "../../../assets/ServicesImages/LandingServiceImages/singpassaccount.png";

export default function LandingServiceCards() {
  const { t } = useTranslation();
  console.log(
    "iconsd ",
    t("service.LandingService.landingCards", { returnObjects: true })
  );
  const Icons = [
    <Airport />,
    <Simcard />,
    <MedicalCheckup />,
    <NIR />,
    <CollectMedical />,
    <UploadMedical />,
    <StudentPass />,
    <Appointment />,
    BankAccountImg,
    SingPassAccount,
  ];
  return (
    <Box>
      <RobotoTypo
        sx={{
          textAlign: "center",
          fontSize: { xs: 20, sm: 24, lg: 32 },
          color: "#003B5C",
          fontWeight: 700,
          my: 4,
        }}
      >
        {t("landingServices")}
      </RobotoTypo>

      <Box
        sx={{
          background: "#F7F7F7",
          zIndex: "10000",
          display: "flex",
          justifyContent: "center",
          padding: { xs: "15% 0% 8% 0%", md: "8% 0% 5% 0%" },
          gap: { xs: "50px", lg: "60px" },
          width: "100%",
          rowGap: "100px",
          flexWrap: "wrap",
        }}
      >
        {Icons?.map(
          (
            item,
            index // maxWidth: '280px',
          ) => (
            <Box
              key={index}
              sx={{
                minWidth: { xs: "240px", sm: "280px", xl: "unset" },
                maxWidth: { xs: "240px", sm: "280px", xl: "unset" },
                margin: { xs: "0px 20px", sm: "0px" },
                width: { xs: "auto", xl: "18%" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "3px solid #177D84",
                borderRadius: "25px",
                position: "relative",
                backgroundColor: "white",
              }}
            >
              <Box sx={{ position: "absolute", top: "-40px" }}>
                <Box
                  sx={{
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    background: "white",
                    justifyContent: "center",
                    border: "2px solid #177D84",
                    width: "80px",
                    height: "80px",
                    borderRadius: "50px",
                  }}
                >
                  <Circle style={{ position: "absolute", left: -4 }} />
                  <RobotoTypo
                    sx={{
                      color: "#003B5C",
                      fontWeight: "700",
                      letterSpacing: "0.02em",
                      lineHeight: "38px",
                      fontSize: { xs: "20px", sm: "24px", md: "32px" },
                    }}
                  >
                    {
                      t("service.LandingService.landingCards", {
                        returnObjects: true,
                      })[index].number
                    }
                  </RobotoTypo>
                </Box>
              </Box>

              <Box
                sx={{
                  textAlign: "center",
                  padding: {
                    xs: "45px 30px 30px 30px",
                    sm: "45px 30px 15px 30px",
                    lg: "45px 30px 15px 30px",
                  },
                }}
              >
                <Box>
                  {index != 8 && index != 9 ? (
                    item
                  ) : (
                    <Box
                      sx={{
                        height: "89px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{ width: "55px", height: "55px" }}
                        component={"img"}
                        src={item}
                      />
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "103px",
                  }}
                >
                  <RobotoTypo
                    sx={{
                      color: "#003B5C",
                      fontWeight: "700",
                      letterSpacing: "0.02em",
                      lineHeight: "38px",
                      fontSize: { xs: "18px", sm: "18px", md: "20px" },
                    }}
                  >
                    {
                      t("service.LandingService.landingCards", {
                        returnObjects: true,
                      })[index].title
                    }
                  </RobotoTypo>
                  <Box>
                    <RobotoTypo
                      sx={{
                        color: "#177D84",
                        fontWeight: "400",
                        letterSpacing: "0.02em",
                        lineHeight: "27px",
                        fontSize: "10px",
                      }}
                    >
                      {
                        t("service.LandingService.landingCards", {
                          returnObjects: true,
                        })[index].subTitle
                      }
                    </RobotoTypo>
                  </Box>
                </Box>
              </Box>
            </Box>
          )
        )}
      </Box>
    </Box>
  );
}

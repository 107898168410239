import React, { useState, useEffect } from 'react'
import { Box, TextField, Select, FormControl, MenuItem, InputLabel, Button, InputAdornment, IconButton, Checkbox } from '@mui/material'
import { RobotoTypo, TimesNewTypo } from '../utils/Typographies'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { ReactComponent as Hover1 } from '../assets/ContactUs/DateIcon.svg';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ReactComponent as RightArrow } from '../assets/StudyingAboard/RightSideArrow.svg';
import Header from '../components/Header';
import AboutHeader from '../components/AboutUs/AboutHeader';
import Enquire from '../components/AboutUs/Enquire';
import Services from '../components/Services';
import Teams from '../components/AboutUs/Teams';
import Footer from '../components/Footer';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';
import FieldBasedCategories from "../components/FieldBasedCategories"


export default function Psychology() {

    const language = useSelector((state) => state.language.language);
    const { id } = useParams()
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [schoolList, setSchoolList] = useState([])
    const [data, setData] = useState([])

    const getSchoolbyCategory = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getSchoolByCatId/${id}`);
            if (response.status == 200) {
                setSchoolList(response?.data)
                console.log('response?.data one', typeof response?.data)
                getData(Array.isArray(response?.data) ? response?.data : [response.data])
            }
        } catch (err) {
            console.log(err);
        }
    }

    console.log(language);

    const getData = (data) => {
        console.log('lang data ', data)
        //  const languageData = data?.map((item) => {
        let languageData
        if (data.length != 0) {
            if (language == 'en') {
                languageData = data?.length != 0 && data.map((item) => {
                    return {
                        id: item._id,
                        data: item.en
                    }
                })
            } else {
                languageData = data?.length != 0 && data.map((item) => {
                    return {
                        id: item._id,
                        data_en: item.en,
                        data: item.ch
                    }
                })
            }
            setData(languageData)

        }
        // })
        console.log(languageData);
    }

    useEffect(() => {
        getSchoolbyCategory()
    }, [])

    useEffect(() => {
        if (schoolList?.length != 0) {
            console.log('daad ', schoolList)
            getData(schoolList)
        }
    }, [language])

    return (
        <>
            <Header />
            {data?.length != 0 && <FieldBasedCategories data={data} />}
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}

import React from 'react'
import { Box } from '@mui/material';
import Map1 from './Map1';
import Map2 from './Map2';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    '@media (max-width: 1024px)': {
        mapView: {
            flexDirection: 'column',
            rowGap: '50px',
            alignItems: 'center',
            marginBottom: '30px !important'
        },

    },
});
export default function MapContainer() {
    const classes = useStyles();

    return (
        <Box className={classes.mapView} sx={{ display: 'flex', justifyContent: "space-around", margin: '2% 5%', position: 'relative' }}>
            <Map1 />
            <Map2 />
        </Box>
    );
}



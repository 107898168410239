import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PaymentHeader from '../components/Service/Payment/PaymentHeader';
import PaymentServiceIntro from '../components/Service/Payment/PaymentServiceIntro';
import PaymentForm from '../components/Service/Payment/PaymentForm';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';

export default function Payment() {
    return (
        <>
            <Header />
            <PaymentHeader />
            <PaymentServiceIntro/>
            <PaymentForm/>
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}
import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import AirportPickupHeader from '../components/Service/AirportPickup/AirportPickupHeader';
import AirportPickupInfo from '../components/Service/AirportPickup/AirportPickupInfo';
import AirportPickupServicerIntro from '../components/Service/AirportPickup/AirportServiceIntro';
import AirportPickupServiceInfo from '../components/Service/AirportPickup/AirportPickupServiceInfo';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';

export default function AirportPickup() {
    return (
        <>
            <Header />
            <AirportPickupHeader />
            <AirportPickupInfo />
            <AirportPickupServicerIntro />
            <AirportPickupServiceInfo/>
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}
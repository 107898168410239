import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Share from '../components/Share'
import SideOverlap from './sideOverlap'
import UniversityApplicationForm from '../components/ApplicationForm/University'
  

export default function UniversityApplication() {
    return (
        <>
            <Header />
            <UniversityApplicationForm />
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}

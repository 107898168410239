import React, { useState } from 'react'
import { Box, TextField, Select, FormControl, MenuItem, InputLabel, Button } from '@mui/material'
import { RobotoTypo } from '../../../utils/Typographies'
import { makeStyles } from '@mui/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { ReactComponent as Hover1 } from '../../../assets/ContactUs/DateIcon.svg';
import MasterClassImg from "../../../assets/png/payment1.png"
import VisaImg from "../../../assets/png/payment2.png";
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
    TextField: {
        "& .MuiInputBase-input": { padding: '10px 10px', borderRadius: '5px', backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset' },
        "& fieldset": { border: "none", }
    },

    selectIcon: {
        "& .MuiInputBase-input": { padding: '10px 10px', borderRadius: '5px', backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset' },
        "& fieldset": { border: "none", },
        '& .MuiSvgIcon-root.MuiSelect-icon': {
            color: '#177D84', // Set your desired color here
        },
    },



}));


export default function PaymentForm() {
    const [qualification, setQualification] = React.useState('');
    const classes = useStyles();
    const { t } = useTranslation();

    const handleAgeChange = (event) => {
        setQualification(event.target.value);
    };


    return (
        <Box sx={{ padding: '4% 10%', backgroundColor: '#f5dfba' }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '30px',
                backgroundColor:'#F7F7F7',
                padding:'50px 30px',
                borderRadius: '50px',
            }}>
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('Payment.paymentForm', { returnObjects: true }).title}
                </RobotoTypo>
                <Box sx={{display:'flex', gap:'20px', alignItems:'flex-end'}}>
                    <Box 
                    component='img'
                    src={MasterClassImg}
                    sx={{width:{xs:'40px',height:'40px', sm:'auto'}}}
                    />
                     <Box 
                    component='img'
                    src={VisaImg}
                    />
                </Box>
            <Box sx={{display:'flex',flexDirection:'column', gap:'50px'}}>
                <Box sx={{ display: 'flex', gap: '90px', flexDirection: { xs: 'column', sm: 'row' } }}>
                    <Box sx={{ flex: '1' }}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            {t('Payment.paymentForm', { returnObjects: true }).inputField1} 
                        </RobotoTypo>
                        <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                    </Box>
                    <Box sx={{ flex: '1' }}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            {t('Payment.paymentForm', { returnObjects: true }).inputField3} 
                        </RobotoTypo>
                        <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                    </Box>
                  
                </Box>
                <Box sx={{ display: 'flex', gap: '90px', flexDirection: { xs: 'column', sm: 'row' } }}>
                   
                    <Box sx={{ flex: '1' }}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            {t('Payment.paymentForm', { returnObjects: true }).inputField2} 
                        </RobotoTypo>
                        <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                    </Box>
                    <Box sx={{ flex: '1' }}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            {t('Payment.paymentForm', { returnObjects: true }).inputField4}
                        </RobotoTypo>
                        <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                    </Box>
                </Box>
                </Box>

                <Box sx={{ textAlign: 'center' }}>
                    <Button disableElevation variant='contained' sx={{
                        mt: 5, borderRadius: '50px', textAlign: 'center', backgroundColor: '#FFC424',
                        '&:hover': {
                            backgroundColor: '#FFC424',
                        },
                    }} >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px', padding: '6px 5px' }}>
                            <RobotoTypo sx={{ color: '#FFFFFF', letterSpacing: '0.02em', textTransform: 'none', lineHeight: '32px', fontWeight: '700', fontSize: { xs: 16, sm: 18, md: 18, lg: 18 } }}>{t('Payment.paymentForm', { returnObjects: true }).PaymentBtn}</RobotoTypo>
                        </Box>
                    </Button>
                </Box>

            </Box>
        </Box >
    )
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";
import PrimarySchoolHeader from "../../components/StudyingAboard/EducationalPages/PrimarySchool/PrimarySchoolHeader";
import PrimarySchoolBody from "../../components/StudyingAboard/EducationalPages/PrimarySchool/PrimarySchoolBody";
import Share from "../../components/Share";
import NewsInformationBody from "./NewsInformationBody";
import NewsInformationListHeader from "./NewsInformationListHeader";
import { useSelector } from "react-redux";
import Footer from "../../components/Footer";
import SideOverlap from "../sideOverlap";

const NewsIndividualList = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const language = useSelector((state) => state.language.language);
  const [news, setNews] = useState(null);
 
  const getNewsById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getNewsById/${id}`
      );
      console.log("response get ", response?.data);
      if (response.status == 200) {
        if (Array.isArray(response?.data)) {
          if (response?.data != "") {
            setData(response.data);
          } else {
            setData([]);
          }
        } else if (
          typeof response?.data === "object" &&
          response?.data !== null
        ) {
          if (response?.data != "" && response?.data != null) {
            setData(response.data);
            getData(response.data);
          } else {
            setData([]);
          }
        }
      }
    } catch (error) {}
  };
  const getData = (data) => {
    console.log("news data ", data);
    const languageData = language == "en" ? data.en : data.ch;
   
    setNews(languageData);
  };
  useEffect(() => {
    getNewsById();
  }, []);
  useEffect(() => {
    if (news !== null) {
      getData(data);
    }
  }, [language]);

  return (
    <>
      <Header />
      <NewsInformationListHeader data={news} />
      <NewsInformationBody data={news} />
      <Share />
      <SideOverlap />
      <Footer />
    </>
  );
};

export default NewsIndividualList;

import React from 'react'
import { Box, Button, IconButton } from '@mui/material';
import { RobotoTypo, TimesNewTypo } from '../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import PrimarySchool from '../../assets/StudyingAboard/primarySchool.png';
import HighSchool from '../../assets/StudyingAboard/highSchool.png';
import InternationalSchool from '../../assets/StudyingAboard/internationalSchool.png';
import University from '../../assets/StudyingAboard/university.png';
import { ReactComponent as UserIcon } from '../../assets/StudyingAboard/userIcon.svg';
import { useNavigate } from 'react-router-dom';

export default function () {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const educationalIndustries = [PrimarySchool, HighSchool, InternationalSchool, University]
    console.log('navigat ', t('StudyingAboard.educationalIndustries', { returnObjects: true })[0].goto)

    return (
        <Box sx={{ position: 'relative', backgroundColor: '#F7F7F7' }}>
            <Box
                sx={{
                    height: { xs: '3%', sm: "15%", md: '12%', lg: "100%", xl: '100%' },
                    display: { xs: 'none', md: 'none', lg: 'flex', xl: 'flex' },
                    width: { xs: '15%', sm: "15%", md: '12%', lg: "10%", xl: '10%' },
                    borderRight: '8px solid white',
                    borderColor: '#F5DFBA',
                    position: 'absolute',
                    bottom: '0px',
                    top: '0px',
                }}
            ></Box>
            <Box sx={{ padding: '4% 6%', display: 'flex', flexDirection: 'column', gap: '30px', }}>
                {educationalIndustries?.map((item, index) => (
                    <Box key={index} sx={{
                        display: 'flex',
                        backgroundColor: '#FFFFFF',
                        flexDirection: { xs: 'column', lg: 'row' },
                        borderRadius: '24px',
                        boxShadow: '0px 4px 4px 0px #00000040'
                    }}>


                        <Box
                            sx={{ width: { xs: '100%', lg: '45%' }, zIndex: 1 }}
                        >
                            <img
                                src={item}
                                alt='Studing Aboard'
                                style={{
                                    objectFit: 'cover',
                                    height: '100%',
                                    width: '100%',
                                    borderRadius: '24px'
                                }}
                            />
                        </Box>


                        {/* {t('Service.header', { returnObjects: true }).map((item, index) => ( */}
                        <Box
                            sx={{
                                width: { xs: '100%', lg: '55%' },

                            }}
                        >
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 3,
                                padding: '50px 25px'
                            }}>
                                <RobotoTypo sx={{ fontWeight: '700', letterSpacing: '0.02em', lineHeight: '32px', color: '#003B5C', fontSize: { xs: '22px', md: '26px', lg: '26px' }, textAlign: 'left' }}>
                                    {t('StudyingAboard.educationalIndustries', { returnObjects: true })[index].title}
                                </RobotoTypo>
                                <Box sx={{ display: 'flex', gap: 3 }}>
                                    <UserIcon />
                                    <RobotoTypo sx={{ fontWeight: '500', letterSpacing: '0.03em', lineHeight: '24px', color: '#177D84', fontSize: { xs: '20px', md: '22px', lg: '22px' }, textAlign: 'left' }}>
                                        {t('StudyingAboard.educationalIndustries', { returnObjects: true })[index].age}
                                    </RobotoTypo>
                                </Box>
                                <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '22px', color: '#242424', fontSize: { xs: '12px', md: '13px', lg: '13px' }, textAlign: 'left' }}>
                                    {t('StudyingAboard.educationalIndustries', { returnObjects: true })[index].description}
                                </RobotoTypo>

                                <Button onClick={() => navigate(t('StudyingAboard.educationalIndustries', { returnObjects: true })[index].goto)} sx={{
                                    alignSelf: { xs: 'start', sm: 'end' }, border: '1px solid #177D84', padding: '9px 18px', color: 'white', borderRadius: '60px', backgroundColor: '#177D84', ":hover": {
                                        bgcolor: "white",
                                        color: "#177D84",

                                    }
                                }} >
                                    <RobotoTypo sx={{ fontWeight: '500', letterSpacing: '0.03em', lineHeight: '24px', fontSize: { xs: '16px', md: '18px', lg: '18px' }, textAlign: 'center' }}>
                                        {t('StudyingAboard.educationalIndustries', { returnObjects: true })[index].moreInfo}
                                    </RobotoTypo>
                                </Button>
                            </Box>
                        </Box>
                        {/* ))} */}

                    </Box>
                ))}


            </Box>
        </Box >
    )
}

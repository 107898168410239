import React, { useState } from 'react'
import { Box, TextField, Select, FormControl, MenuItem, InputLabel,  RadioGroup, FormControlLabel, Radio, Button, useMediaQuery } from '@mui/material'
import { RobotoTypo } from '../../../utils/Typographies'
import { makeStyles } from '@mui/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { ReactComponent as Hover1 } from '../../../assets/ContactUs/DateIcon.svg';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
    TextField: {
        "& .MuiInputBase-input": { padding: '10px 10px', borderRadius: '5px', backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset' },
        "& fieldset": { border: "none", }
    },

    selectIcon1: {
        "& .MuiInputBase-input": { padding: '10px 10px', borderRadius: '5px', backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset' },
        "& fieldset": { border: "none", },
        '& .MuiSvgIcon-root.MuiSelect-icon': {
            color: '#177D84', // Set your desired color here
        },
    },
    selectIcon: {
        '& .MuiRadio-root': {
            color: 'transparent',
        },
        // '& .MuiRadio-root.Mui-checked': {
        //     color: "red"
        // }
    },
    label6: {
        "& .MuiFormControlLabel-label": {
            marginRight: "-40px"
        }
    },
    label9: {
        "& .MuiFormControlLabel-label": {
            marginRight: "-20px"
        }
    },
    label11: {
        "& .MuiFormControlLabel-label": {
            marginRight: "-18px"
        }
    },
    label15: {
        "& .MuiFormControlLabel-label": {
            marginRight: "-36px"
        }
    },

    radio: {
        '&$checked': {
            color: 'red',
        },
        '& .MuiIconButton-root': {
            padding: '10px',
            borderRadius: '50%',
            backgroundColor: 'white',
            boxShadow: '2px 2px 4px 0px #00000040 inset',
            border: 'none',
        },
        '& .MuiIconButton-root': {
            padding: '10px',
            borderRadius: '50%',
            backgroundColor: 'white',
            boxShadow: '2px 2px 4px 0px #00000040 inset',
            border: 'none',
        },

    },
    radios: {
        '&$checked': {
            color: 'red', // Set the color when the radio button is checked here
        },
    },
    
}));


export default function RentingApplicationForm() {
    const [qualification, setQualification] = React.useState('');
    const classes = useStyles();
    const { t } = useTranslation();
    const isExtraSmallScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));

    const handleAgeChange = (event) => {
        setQualification(event.target.value);
    };


    const ageOptions = ['SSLC', 'HSC', 'BACHELOR DEGREE', 'MASTER DEGREE']

    return (
        <Box sx={{ padding: {xs:'4% 6%',sm:'4%'}, backgroundColor: '#F7F7F7' }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                // gap: '30px',
            }}>
                <Box sx={{display:'flex', gap:'20px', position:'relative'}}>
                    <Box sx={{minWidth:'80px', display: {xs:'none', sm:'flex'}, alignItems: 'center', background: 'white', justifyContent: 'center', border: '2px solid #177D84', width: '80px', height: '80px', borderRadius: '50px' }}>
                        <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '20px', sm: '24px', md: '32px' } }}>01</RobotoTypo>
                        <Box sx={{position:'absolute', border:'1px solid #177d84', height:'69%', bottom:0}}/>
                    </Box>
                    <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'30px', mb:4}}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('RentingApplication.form', { returnObjects: true }).title1} 
                        </RobotoTypo>
                        <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' } }}>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).onefield1} 
                                </RobotoTypo>
                                <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none', outline: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                            </Box>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).onefield2} 
                                </RobotoTypo>
                                <FormControl fullWidth>
                                <Select
                                    sx={{ marginTop: "10px", }}
                                    className={classes.selectIcon1}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={qualification}
                                    onChange={handleAgeChange}
                                >
                                    {ageOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                                {/* <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} /> */}

                            </Box>
                        </Box>
                     
               
                        <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' } }}>
                        <Box sx={{ flex: '1' }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).onefield3} 
                            </RobotoTypo>
                            <FormControl fullWidth>
                                <Select
                                    sx={{ marginTop: "10px", }}
                                    className={classes.selectIcon1}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={qualification}
                                    onChange={handleAgeChange}
                                >
                                    {ageOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ flex: '1' }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).onefield4} 
                            </RobotoTypo>
                            <FormControl fullWidth>
                                <Select
                                    sx={{ marginTop: "10px", }}
                                    className={classes.selectIcon1}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={qualification}
                                    onChange={handleAgeChange}
                                >
                                    {ageOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        </Box>
                    </Box>  
                </Box>
                <Box sx={{display:'flex', gap:'20px', position:'relative'}}>
                    <Box sx={{minWidth:'80px',display: {xs:'none', sm:'flex'}, alignItems: 'center', background: 'white', justifyContent: 'center', border: '2px solid #177D84', width: '80px', height: '80px', borderRadius: '50px' }}>
                        <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '20px', sm: '24px', md: '32px' } }}>02</RobotoTypo>
                        <Box sx={{position:'absolute', border:'1px solid #177d84', height:'90.8%', bottom:0}}/>
                    </Box>

                    <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'30px', my:2}}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('RentingApplication.form', { returnObjects: true }).title2} 
                        </RobotoTypo>
                        <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' } }}>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).twofield1} 
                                </RobotoTypo>
                                <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                            </Box>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).twofield2} 
                                </RobotoTypo>
                                <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' } }}>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).twofield3} 
                                </RobotoTypo>
                                <Box className={classes.TextField} >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker sx={{ width: '100%' }}
                                                renderInput={(params) => <TextField {...params} />}
                                                format="YYYY/MM/DD" // Set the desired date format here
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Box>
                            </Box>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).twofield4} 
                                </RobotoTypo>
                                <FormControl fullWidth>
                                <Select
                                    sx={{ marginTop: "10px", }}
                                    className={classes.selectIcon1}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={qualification}
                                    onChange={handleAgeChange}
                                >
                                    {ageOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row',width:'100%' } }}>
                            <Box sx={{display:'flex', flexDirection:{xs:'column', sm:'row'}, gap:'10px', width:{xs:'100%', sm:'49%'}}}>
                                <Box sx={{ flex: '1', width:{xs:'100%', sm:'50%'} }}>
                                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                    <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).twofield5} 
                                    </RobotoTypo>
                                    <FormControl fullWidth>
                                        <Select
                                            sx={{ marginTop: "10px", }}
                                            className={classes.selectIcon1}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={qualification}
                                            onChange={handleAgeChange}
                                        >
                                        {['singapore','china','korea'].map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                        </Select>
                            </FormControl>
                                </Box>
                                <Box sx={{ flex: '1', width:{xs:'100%', sm:'50%'}  }}>
                                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                    <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).twofield6} 
                                    </RobotoTypo>
                                    <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                                </Box>
                                
                            </Box>
                            <Box sx={{ flex: '1', width:{xs:'100%', sm:'50%'}  }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).twofield7} 
                                </RobotoTypo>
                                <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' } }}>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).twofield8} 
                                </RobotoTypo>
                                <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                            </Box>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).twofield9} 
                                </RobotoTypo>
                                <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' } }}>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).twofield10} 
                                </RobotoTypo>
                                <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                            </Box>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).twofield11} 
                                </RobotoTypo>
                                <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' } }}>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).twofield12} 
                                </RobotoTypo>
                                <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                            </Box>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).twofield13} 
                                </RobotoTypo>
                                <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                            </Box>
                        </Box>
                        <Box sx={{ flex: '1', }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).twofield14} 
                            </RobotoTypo>
                            <TextField
                                className={classes.TextField}
                                // variant="outlined"
                                multiline
                                rows={4}
                                InputProps={{ style: { border: 'none', height: '100px', padding: '10px 0' } }}
                                sx={{ flex: '1', width: '100%', marginTop: "10px", padding: '10px 0' }}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box sx={{display:'flex', gap:'20px', position:'relative'}}>
                    <Box sx={{minWidth:'80px', display: {xs:'none', sm:'flex'}, alignItems: 'center', background: 'white', justifyContent: 'center', border: '2px solid #177D84', width: '80px', height: '80px', borderRadius: '50px' }}>
                        <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '20px', sm: '24px', md: '32px' } }}>03</RobotoTypo>
                        <Box sx={{position:'absolute', border:'1px solid #177d84', height:'90.8%', bottom:0}}/>
                    </Box>
                
                <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'30px', my:2}}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('RentingApplication.form', { returnObjects: true }).title3} 
                    </RobotoTypo>
                    <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' } }}>
                        <Box sx={{  width:{xs:'100%', sm:'49%'} }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).thirdfield1} 
                            </RobotoTypo>
                            <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                        </Box>
                    
                    </Box>

                    <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' } }}>
                        <Box sx={{ flex: '1' }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).thirdfield2} 
                            </RobotoTypo>
                            <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                        </Box>
                        <Box sx={{ flex: '1' }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).thirdfield3} 
                            </RobotoTypo>
                            <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' } }}>
                        <Box sx={{ flex: '1' }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).thirdfield4} 
                            </RobotoTypo>
                            <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                        </Box>
                        <Box sx={{ flex: '1' }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).thirdfield5} 
                            </RobotoTypo>
                            <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' } }}>
                        <Box sx={{ flex: '1' }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).thirdfield6} 
                            </RobotoTypo>
                            <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                        </Box>
                        <Box sx={{ flex: '1' }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).thirdfield7} 
                            </RobotoTypo>
                            <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' },  width:{xs:'100%', sm:'49%'} }}>
                        <Box sx={{ flex: '1', }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).thirdfield8} 
                            </RobotoTypo>
                            <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                        </Box>
                    
                    </Box>

                    <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' } }}>
                        <Box sx={{ flex: '1' }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).thirdfield9} 
                            </RobotoTypo>
                            <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                        </Box>
                        <Box sx={{ flex: '1' }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).thirdfield10} 
                            </RobotoTypo>
                            <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' } }}>
                        <Box sx={{ flex: '1' }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).thirdfield11} 
                            </RobotoTypo>
                            <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                        </Box>
                        <Box sx={{ flex: '1' }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).thirdfield12} 
                            </RobotoTypo>
                            <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' } }}>
                        <Box sx={{ flex: '1' }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).thirdfield13} 
                            </RobotoTypo>
                            <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                        </Box>
                        <Box sx={{ flex: '1' }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).thirdfield14} 
                            </RobotoTypo>
                            <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                        </Box>
                    </Box>
                </Box>

                </Box>

                <Box sx={{display:'flex', gap:'20px', position:'relative'}}>
                    <Box sx={{ minWidth:'80px', display: {xs:'none', sm:'flex'}, alignItems: 'center', background: 'white', justifyContent: 'center', border: '2px solid #177D84', width: '80px', height: '80px', borderRadius: '50px' }}>
                        <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '20px', sm: '24px', md: '32px' } }}>04</RobotoTypo>
                        <Box sx={{position:'absolute', border:'1px solid #177d84', height:{xs:'89%', sm:'87%', md:'86%', lg:'85%'}, bottom:0}}/>
                    </Box>

                    <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'30px', my:2}}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('RentingApplication.form', { returnObjects: true }).title4} 
                        </RobotoTypo>

                        <RadioGroup
                            className={classes.selectIcon}
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            row={isExtraSmallScreen}
                            sx={{width:'fit-content',  display:'flex',flexDirection:'column', gap:'10px', alignItems:'flex-start' }}
                        >
                            <FormControlLabel variant="outlined" InputProps={{ border: 'none' }} sx={{ color: "#003B5C", gap: "10px",   marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('RentingApplication.form', { returnObjects: true }).fourthfield1} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset'
                            }} />} label={t('RentingApplication.form', { returnObjects: true }).fourthfield1} />
                            <FormControlLabel variant="outlined" InputProps={{ border: 'none' }} sx={{ color: "#003B5C", gap: "10px",  marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('RentingApplication.form', { returnObjects: true }).fourthfield2} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset'
                            }} />} label={t('RentingApplication.form', { returnObjects: true }).fourthfield2} />
                            <FormControlLabel variant="outlined" InputProps={{ border: 'none' }} sx={{ color: "#003B5C", gap: "10px",   marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('RentingApplication.form', { returnObjects: true }).fourthfield3} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset'
                            }} />} label={t('RentingApplication.form', { returnObjects: true }).fourthfield3} />
                                <FormControlLabel variant="outlined" InputProps={{ border: 'none' }} sx={{ color: "#003B5C", gap: "10px",   marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('RentingApplication.form', { returnObjects: true }).fourthfield4} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset'
                            }} />} label={t('RentingApplication.form', { returnObjects: true }).fourthfield4} />
                                <FormControlLabel variant="outlined" InputProps={{ border: 'none' }} sx={{ color: "#003B5C", gap: "10px",   marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('RentingApplication.form', { returnObjects: true }).fourthfield5} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset'
                            }} />} label={t('RentingApplication.form', { returnObjects: true }).fourthfield5} />
                                <FormControlLabel variant="outlined" InputProps={{ border: 'none' }} sx={{ color: "#003B5C", gap: "10px",   marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('RentingApplication.form', { returnObjects: true }).fourthfield6} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset'
                            }} />} label={t('RentingApplication.form', { returnObjects: true }).fourthfield6} />
                            <FormControlLabel  variant="outlined" InputProps={{ border: 'none' }} sx={{ color: "#003B5C", gap: "10px",  marginTop: "10px", justifyContent: { xs: 'center', sm: "start" } }} value={t('RentingApplication.form', { returnObjects: true }).fourthfield7} control={<Radio sx={{
                                border: "none", backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset'
                            }} />} label={t('RentingApplication.form', { returnObjects: true }).fourthfield7} />
                        </RadioGroup><br />
                    </Box>
            </Box>

            <Box sx={{display:'flex', gap:'20px', position:'relative'}}>

                <Box sx={{minWidth:'80px',display: {xs:'none', sm:'flex'}, alignItems: 'center', background: 'white', justifyContent: 'center', border: '2px solid #177D84', width: '80px', height: '80px', borderRadius: '50px' }}>
                    <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '20px', sm: '24px', md: '32px' } }}>05</RobotoTypo>
                    <Box sx={{position:'absolute', border:'1px solid #177d84', height:'72%', bottom:0}}/>
                </Box>

                <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'30px', my:2}}>
                    <Box sx={{display:'flex', flexDirection:'column', gap:'5px'}}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('RentingApplication.form', { returnObjects: true }).title5} 
                        </RobotoTypo>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#177D84', fontSize: '18px', textAlign: 'left' }}>
                            {t('RentingApplication.form', { returnObjects: true }).subTitle5} 
                        </RobotoTypo>
                    </Box>

                    <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' },  width:{xs:'100%',sm:'49%'} }}>
                        <Box sx={{ flex: '1', }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).fivethfield1} 
                            </RobotoTypo>
                            <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                        </Box>
                    
                    </Box>

                    <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' }}}>
                        <Box sx={{ flex: '1', }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).fivethfield2} 
                            </RobotoTypo>
                            <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                        </Box>
                        <Box sx={{ flex: '1', }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                            <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> {t('RentingApplication.form', { returnObjects: true }).fivethfield3} 
                            </RobotoTypo>
                            <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                        </Box>
                    
                    </Box>
                </Box>
            </Box>


                <Box sx={{ textAlign: 'center' }}>
                    <Button disableElevation variant='contained' sx={{
                        mt: 5, borderRadius: '50px', textAlign: 'center', backgroundColor: '#FFC424',
                        '&:hover': {
                            backgroundColor: '#FFC424',
                        },
                    }} >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px', padding: '6px 5px' }}>
                            <RobotoTypo sx={{ color: '#FFFFFF', letterSpacing: '0.02em', textTransform: 'none', lineHeight: '32px', fontWeight: '700', fontSize: { xs: 18, sm: 18, md: 21, lg: 21 } }}>{t('RentingApplication.form', { returnObjects: true }).submitBtn}</RobotoTypo>
                        </Box>
                    </Button>
                </Box>

            </Box>
        </Box >
    )
}

import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import DrAppHeader from '../components/Service/DrApp/DrAppHeader';
import DrAppInfo from '../components/Service/DrApp/DrAppInfo';
import DrAppServiceIntro from '../components/Service/DrApp/DrAppServiceIntro';
import Share from '../components/Share';
import SideOverlap from './sideOverlap'

export default function DrApp() {
    return (
        <>
            <Header/>
            <DrAppHeader />
            <DrAppInfo />
            <DrAppServiceIntro />
            <Share />
            <Footer />
            <SideOverlap/>
        </>
    )
}
import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import AcademicCounselingHeader from '../components/StudyingAboard/AcademicCounseling/AcademicCounselingHeader';
import AcademicCounselingInfo from '../components/StudyingAboard/AcademicCounseling/AcademicCounselingInfo';
import AcademicCounselingServiceIntro from '../components/StudyingAboard/AcademicCounseling/AcademicCounselingServiceIntro';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';

export default function AcademicCounseling() {
    return (
        <>
            <Header />
            <AcademicCounselingHeader />
            <AcademicCounselingInfo />
            <AcademicCounselingServiceIntro />
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}
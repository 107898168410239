import React from 'react'
import { Box } from '@mui/material';
import { RobotoTypo, TimesNewTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import { getId } from '../../../utils/common';
import { ReactComponent as YellowDot } from '../../../assets/Home/ApplicationProcess/YellowDot.svg';

export default function SchoolAdmissionInfo({data}) {
    const { t } = useTranslation();
console.log('admissison ',data)
    
    const myId = getId('https://www.youtube.com/watch?v=mVfxlWoshg0&pp=ygUjc3RhbWZvcmQgYW1lcmljYW4gc2Nob29sIHNpbmdhcG9yZSA%3D');
    
    return (
        (data?.admission_info?.length == 1 && (data?.admission_info[0].title =="" || data?.admission_info[0].description =="")) ?(
        ""):(
            <Box sx={{padding: '4% 5%',backgroundColor:'#F7F7F7',}}>
        <Box sx={{  display:'flex', gap:'60px', width:'100%', flexDirection:{xs:'column',lg:'row'} }}>
        <Box sx={{ width:{xs:'100%',lg:'50%'}}}>
            <Box sx={{mb:4,  display:'flex', justifyContent:'center', alignItems:'center', backgroundColor: '#003B5C', padding: '8px 12px', borderRadius: '50px', width:'fit-content' }
            } >
                <RobotoTypo sx={{ letterSpacing: '0.02em', fontWeight: '700', color: 'white', fontSize: { xs: '20px', sm: '22px', md: '22px', lg: '22px' }, textAlign: 'left' }}>
                {t('School.schoolAdmissionInfo', { returnObjects: true }).title}
                </RobotoTypo><br />
            </ Box>
            {data?.admission_info?.map((admission,index) => (
                <Box key={index} sx={{mb:4}}>
                    <Box sx={{display:'flex', alignItems:'center', gap:'20px', mb:2}}>
                        <YellowDot/> <RobotoTypo sx={{ fontWeight: '500', letterSpacing: '8%', lineHeight: '25px', color: '#003B5C', fontSize: { xs: '20px', sm: '22px', md: '22px', lg: '22px' }, textAlign: 'left' }}>
                                {admission?.title}
                        </RobotoTypo>
                    </Box>
                    <RobotoTypo sx={{ml:4, fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: '16px', textAlign: 'left' }}>{<p dangerouslySetInnerHTML={{ __html: admission.description }} />}</RobotoTypo>
                </Box>
            )) }
           
            </Box>
            {console.log('data?.entry_requirement?.length ',data?.entry_requirement)}
           {(data?.entry_requirement?.length >=1 && (data?.entry_requirement[0].description !="" || data?.entry_requirement[0].title !="")) && <Box sx={{ width:{xs:'100%',lg:'45%'}}}>
            <Box sx={{mb:4,  display:'flex', justifyContent:'center', alignItems:'center', backgroundColor: '#003B5C', padding: '8px 12px', borderRadius: '50px', width:'fit-content' }
            } >
                
                <RobotoTypo sx={{ letterSpacing: '0.02em', fontWeight: '700', color: 'white', fontSize: { xs: '20px', sm: '22px', md: '22px', lg: '22px' }, textAlign: 'left' }}>
                {/* {t('School.schoolAdmissionInfo', { returnObjects: true }).title} */}
              {t("EntryRequirements")}
                </RobotoTypo><br />
            </ Box>
            {data?.entry_requirement?.map((requirement,index) => (
                <Box key={index} sx={{mb:4}}>
                    <Box sx={{display:'flex', alignItems:'center', gap:'20px', mb:2}}>
                        <YellowDot/> <RobotoTypo sx={{ fontWeight: '500', letterSpacing: '8%', lineHeight: '25px', color: '#003B5C', fontSize: { xs: '20px', sm: '22px', md: '22px', lg: '22px' }, textAlign: 'left' }}>
                                {requirement?.title}
                        </RobotoTypo>
                    </Box>
                    <RobotoTypo sx={{ml:4, fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: '16px', textAlign: 'left' }}>{<p dangerouslySetInnerHTML={{ __html: requirement.description }} />}</RobotoTypo>
                </Box>
            )) }
            </Box>}
            
        </Box>
        </Box>
        )
    )
}

import React from "react";
import { RobotoTypo } from "../../../utils/Typographies";
import { Box,  Checkbox, Stack, Typography } from "@mui/material";

const PaymentDetails = ({
  paymentData,
  setPaymentData,
  payLater,
  payNow,
  total,
}) => {
  const handleCheckboxChange = (index) => {
    const newPaymentData = paymentData.map((row, i) => {
      if (i === index) {
        return { ...row, isChecked: !row.isChecked };
      }
      return row;
    });
    setPaymentData(newPaymentData);
  };

  // Calculate total based on checked items

  return total === 0 ? (
    <Box>
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "bold",
          color: "#ff5722", // A nice accent color
          padding: "20px",
          border: "1px solid #ff5722", // Match the color
          borderRadius: "8px",
          backgroundColor: "#fff3e0", // Light background for emphasis
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow
          my: "24px",
          mx: "24px", // Space around the text
        }}
      >
        We're sorry, but we are unable to process the payment for this
        application. Please try again later.
      </Typography>
    </Box>
  ) : (
    <>
      <Stack
        sx={{
          padding: "18px 30px",
          backgroundColor: "#e1e1e1",
          mb: "30px",
        }}
      >
        <RobotoTypo
          sx={{
            fontWeight: "500",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
          }}
        >
          Application Fee
        </RobotoTypo>
      </Stack>
      <Stack px={"40px"} pb={"40px"} gap="30px">
        <Box className="table">
          <table>
            <tbody>
              {paymentData.map((row, index) => (
                <tr key={index}>
                  <td>
                    <Checkbox
                      checked={row.isChecked}
                      disabled={row.isReadable}
                      onChange={() => handleCheckboxChange(index)}
                    />
                  </td>
                  <td>
                    {row?.url ? (
                      <a
                        href={row?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          fontFamily: "Roboto, sans-serif ",
                          // Blue color for the link
                          // Remove underline
                          // Make the text bold
                        }}
                      >
                        {row.label}
                      </a>
                    ) : (
                      <RobotoTypo sx={{ fontSize: "16px" }}>
                        {row.label}
                      </RobotoTypo>
                    )}
                  </td>
                  <td>
                    <RobotoTypo sx={{ fontSize: "16px" }}>
                      $ {isNaN(row.charge) ? 0 : row.charge}
                    </RobotoTypo>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
        <div
          style={{
            backgroundColor: "#f0f8ff", // Light background color
            border: "1px solid #007bff", // Border color
            borderRadius: "8px", // Rounded corners
            padding: "16px", // Space inside the box
            marginTop: "20px", // Space above the box
            textAlign: "center", // Centered text
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow
          }}
        >
          <RobotoTypo
            sx={{ fontSize: "18px", fontWeight: "400", color: "#202020" }}
          >
            Thank you for your selections! Your total amount to be paid is
            <span style={{ color: "#177D84", fontWeight: "bold" }}>
              {" "}
              $ {total}
            </span>
            . You're ready for payment!
          </RobotoTypo>
        </div>
      </Stack>
    </>
  );
};

export default PaymentDetails;

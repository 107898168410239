import React, { useState,useRef, useEffect  } from 'react';
import { Box, TextField, Button, IconButton } from '@mui/material';
import { emailRegex } from '../utils/common';
import { RobotoTypo, ErrTypo } from '../utils/Typographies';
import { makeStyles } from '@mui/styles'
import axios from 'axios';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ToastAlert from '../utils/Alert';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    TextField: {
        "& .MuiInputBase-input": { padding: '10px 10px', borderRadius: '5px', backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset' },
        "& fieldset": { border: "none", padding: '0' }
    },

    selectIcon: {
        "& .MuiInputBase-input": { padding: '10px 10px', borderRadius: '5px', backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset' },
        "& fieldset": { border: "none", },
        '& .MuiSvgIcon-root.MuiSelect-icon': {
            color: '#177D84', // Set your desired color here
        },
    },
    adornment: {
        paddingRight: 0, // Adjust the padding around the InputAdornment
    },



}));


export default function Otp({userDetails}) {
    const classes = useStyles();
    const [otp, setOtp] = useState(['', '', '', '']);
    const [otpError, setOtpError] = useState('')
    const [resendTimer, setResendTimer] = useState(0);
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
    const [toastStatus,setToastStatus] = useState({color:'',status:false,message:'',icon:''})
    const navigate = useNavigate()
    console.log('userDetails ',userDetails)

    useEffect(() => {
        window.scrollTo(0,0)
    })
    // const handleChange = (index, e) => {
    //     const { value } = e.target;
    //     if (/^[0-9]?$/.test(value)) {
    //         const newOtp = [...otp];
    //         newOtp[index] = value;
    //         setOtp(newOtp);
    //     }
    // };

    
    const handleChange = (index, e) => {
        const { value } = e.target;
        if (/^[0-9]?$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            setOtpError('')
            // Focus on next input if current input is not empty and it's not the last input
            if (value !== '' && index < 3) {
                inputRefs[index + 1].current.focus();
            }
        }
    };

    const handleSubmit = () => {
        const otpValue = otp.join('');
        console.log('OTP Submitted:', otpValue);
        if(otpValue.length != 4) {
            setOtpError('Please enter OTP')
        } else {
            setOtpError('')
            verifyOTP(otpValue)
        }
        // Add further submission logic here
    };

    const handleResendOTP = async() => {
        console.log('Resend OTP clicked');
        // Add logic to resend OTP here
        setResendTimer(30); // Reset the timer to 30 seconds
        // Start countdown timer
        const timer = setInterval(() => {
            setResendTimer(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/resendOtp`, {userId: userDetails.userId != undefined ? userDetails.userId : userDetails._id, email: userDetails.email, name: userDetails.name})
            console.log(response);
            if(response?.data?.status == 'PENDING') {
                setToastStatus({color:'green',status:true,message:response.data.message,icon:'tick'})
                setTimeout(()=>{
                    setToastStatus({color:'',status:false})
                    // navigate(-1)
                },5000)
            } else if(response?.data?.status == 'FAILED') {
                setOtpError(response.data.message)
            }
        } catch (error) {
            console.log('errror ',error);
        }

    };

    const verifyOTP = async (otpValue) => {
        const data = {otp: otpValue, userId: userDetails.userId != undefined ? userDetails.userId : userDetails._id, email: userDetails.email}
        console.log('data ',data)
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/verifyOTP`, data)
            console.log(response);
            if(response?.data?.status == 'VERIFIED') {
                setToastStatus({color:'green',status:true,message:response.data.message,icon:'tick'})
                setTimeout(()=>{
                    setToastStatus({color:'',status:false})
                    navigate('/login')
                },5000)
            } else if(response?.data?.status == 'FAILED') {
                setOtpError(response.data.message)
            }
        } catch (error) {
            console.log('errror ',error);
        }
    }


    return (
        <Header>
            <ToastAlert  message={toastStatus?.message} status={toastStatus?.status}  color={toastStatus?.color} icon={toastStatus?.icon} closeFunc={() => setToastStatus({color:'',status:false})} />  
        <Box sx={{ padding: '4% 10%', backgroundColor: '#F5DFBA', display: 'flex', justifyContent: 'center' }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '30px',
                width: { xs: '100%', md: '50%' },
                maxWidth:'400px',
                backgroundColor: '#F7F7F7',
                padding: '30px',
                borderRadius: '12px'
            }}>
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '25px', textAlign: 'center' }}>
                    Enter OTP
                </RobotoTypo>
                <Box>
                    <RobotoTypo sx={{ fontWeight: '500', color: 'grey', fontSize: '14px', textAlign: 'center' }}>
                        OTP has been sent to your email ID {userDetails?.email}
                    </RobotoTypo>
                </Box>
               

                <Box sx={{ display: 'flex', flexDirection:'column' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        {otp.map((digit, index) => (
                            <TextField
                                key={index}
                                value={digit}
                                onChange={(e) => handleChange(index, e)}
                                className={classes.TextField}
                                variant="outlined"
                                inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                                sx={{ width: '50px', margin: '0 5px' }}
                                inputRef={inputRefs[index]}
                            />
                        ))}
                    </Box>
                         <ErrTypo sx={{mt:1, alignSelf:'center'}}>{otpError}</ErrTypo>
                </Box>
           


                <Box sx={{ textAlign: 'center' }}>
                    <Button variant='contained' onClick={handleSubmit} sx={{
                        borderRadius: '50px', textAlign: 'center', backgroundColor: '#177d84',
                        '&:hover': {
                            backgroundColor: '#177d84',
                        },
                    }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px', padding: '6px 5px' }}>
                            <RobotoTypo sx={{ color: '#FFFFFF', letterSpacing: '0.02em', textTransform: 'none', lineHeight: '32px', fontWeight: '500', fontSize: { xs: 14, sm: 16 } }}>Submit OTP</RobotoTypo>
                        </Box>
                    </Button>

                    <Box sx={{ textAlign: 'center', mt:1 }}>
                    <Button
                        variant="text"
                        onClick={handleResendOTP}
                        disabled={resendTimer > 0}
                    >
                        Resend OTP {resendTimer > 0 && `(${resendTimer}s)`}
                    </Button>
                </Box>
                </Box>

              
            </Box>
        </Box>
        <Footer/>
        </Header>
    );
}

import { Box, Typography } from '@mui/material';
import React from 'react';
import Logo from '../utils/Logo';
import { BaskerTypo, RobotoTypo } from '../utils/Typographies';
import VingaLogo from '../assets/png/VingaLogo.png';
import { FooterInfo, Qrs } from './FooterData';
import FooterBrandings from './Footer/FooterBrandings';
import FooterFeatures from './Footer/FooterFeatures';

const Footer = () => {
  return (
    <Box
      sx={{
        background: '#003B5C',
        p: { xs: 3, md: 6, lg: 9 },
        width: 'auto',
        overflowX: 'hidden',
      }}
    >
      <FooterBrandings />
      <FooterFeatures />
    </Box>
  );
};

export default Footer;

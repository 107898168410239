import React, { useEffect, useState } from "react";
import Admin from "../../Dashboard";
import { Box, Stack, TextField, Button } from "@mui/material";
import { RobotoTypo } from "../../../../utils/Typographies";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const AddEnrollmentApplication = () => {
  const { id } = useParams();
  const [data, setData] = useState({
    name_en: "",
    name_ch: "",
    url: "",
  });
  const navigate = useNavigate();
  const [dataError, setDataError] = useState({
    name_en: "",
    name_ch: "",
    url: "",
  });
  const updateEnrollment = async (reqData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/UpdateEnrollment/${id}`,
        reqData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 201) {
        navigate('/admin/enrollmentApplication')
      }
    } catch (error) {
      if (error.response.status == 403) {
        localStorage.clear();
      }
    }
  };

  const addEnrollment = async (reqData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/addEnrollment`,
        reqData,
        {
          headers: {
            Authorization: localStorage.getItem("admin_token"),
            content: "application/json",
          },
        }
      );
      console.log("response", response);
      if (response.status == 201) {
        navigate("/admin/enrollmentApplication");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
    setDataError((prev) => ({ ...prev, [name]: "" }));
  };
  const validate = () => {
    let error = false;
    if (data.name_en == "") {
      setDataError((prev) => ({ ...prev, name_en: "Name is required" }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, name_en: "" }));
      error = false;
    }
    if (data.name_ch == "") {
      setDataError((prev) => ({ ...prev, name_ch: "Name is required" }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, name_ch: "" }));
      error = false;
    }
    if (data.url == "") {
      setDataError((prev) => ({ ...prev, url: "Url is required" }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, url: "" }));
      error = false;
    }

    return error;
  };
  const handleSubmit = () => {
    const validation = validate();
    if (!validation) {
      const formData = {
        name_en: data.name_en,
        name_ch: data.name_ch,
        url: data.url,
      };
      if (!id) {
        addEnrollment(formData);
      }
      else{
        updateEnrollment(formData)
      }
    }
  };
  const getEnrollmentById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getEnrollmentById/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 200) {
        const enrollmentData = response.data;

        setData({
          name_en: enrollmentData.en.name,
          name_ch: enrollmentData.ch.name,
          url:enrollmentData.en.url
        });
        
      }
    } catch (error) {
      if (error.response.status == 403) {
        localStorage.clear();
        navigate("/admin/login");
      }
    }
  };
  useEffect(()=>{
    if(id){
      getEnrollmentById()
    }
  },[])
  return (
    <Admin>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Box sx={{ display: "flex", gap: "50px" }}>
          <Stack sx={{ width: "50%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 20, sm: 24 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              English
            </RobotoTypo>

            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Button Name
            </RobotoTypo>
            <TextField
              name="name_en"
              value={data.name_en}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.name_en != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                {dataError.name_en}
              </RobotoTypo>
            )}
          </Stack>
          <Box sx={{ width: "50%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 24 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Chinese
            </RobotoTypo>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Button Name
            </RobotoTypo>
            <TextField
              name="name_ch"
              value={data.name_ch}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.name_ch != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                {dataError.name_ch}
              </RobotoTypo>
            )}
          </Box>
        </Box>

        <Stack sx={{ width: "50%" }}>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 20 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 1,
            }}
          >
            Url
          </RobotoTypo>
          <TextField
            name="url"
            value={data.url}
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={handleInputChange}
          />
          {dataError.url != "" && (
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 14, sm: 18 },
                color: "red",
                fontWeight: 500,
                my: 1,
              }}
            >
              {dataError.url}
            </RobotoTypo>
          )}
        </Stack>
        <Button
          component="span"
          onClick={handleSubmit}
          variant="contained"
          sx={{
            alignSelf: "center",
            mt: 1,
            width: "fit-content",
            backgroundColor: "#177D84",
            "&:hover": { backgroundColor: "#177D84" },
          }}
        >
          {id ? "Edit" : "Add"}
        </Button>
      </Box>
    </Admin>
  );
};

export default AddEnrollmentApplication;

import React from "react";
import { Box, Button } from "@mui/material";
import { RobotoTypo, TimesNewTypo } from "../../utils/Typographies";
import { useTranslation } from "react-i18next";
import vingaApp from "../../assets/Home/vingaApp.png";
import DownloadOptions from "./DownloadOption";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  // Media query for screens narrower than 1024px
  mobileImg: {
    width: "75%",
  },
  "@media (max-width: 580px)": {
    mobileImg: {
      width: "60%",
    },
  },
}));

export default function VingaInfo() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box sx={{ p: { xs: 4, lg: 6 }, background: "#fff" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "center",
          gap: 7,
        }}
      >
        <Box
          sx={{
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            gap: 4,
            alignItems: "flex-start",
            justifyContent: "flex-start",
            width: { xs: "100%", md: "50%" },
            justifyItems: "center",
          }}
        >
          <RobotoTypo
            sx={{
              color: "#003B5C",
              letterSpacing: "0.02em",
              textTransform: "none",
              lineHeight: "32px",
              fontWeight: "700",
              fontSize: { xs: 21, sm: 20, md: 28, lg: 30 },
            }}
          >
            {t("Home.vingaApp.title")}
          </RobotoTypo>
          <RobotoTypo
            sx={{
              color: "#242424",
              letterSpacing: "0.02em",
              textTransform: "none",
              lineHeight: "32px",
              fontWeight: "400",
              fontSize: { xs: 18, sm: 18, md: 20, lg: 20 },
            }}
          >
            {t("Home.vingaApp.subTitle1")}
          </RobotoTypo>
          <RobotoTypo
            sx={{
              color: "#242424",
              letterSpacing: "0.02em",
              textTransform: "none",
              lineHeight: "32px",
              fontWeight: "400",
              fontSize: { xs: 18, sm: 18, md: 20, lg: 20 },
            }}
          >
            {t("Home.vingaApp.subTitle2")}
          </RobotoTypo>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "start" },
              width: "100%",
            }}
          >
            <DownloadOptions />
          </Box>
        </Box>

        <Box
          sx={{
            width: { xs: "100%", md: "50%" },
            maxWidth: { xs: "250px", md: "550px" },
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          <Box
            component="img"
            src={vingaApp}
            alt="Vinga App"
            style={{
              height: "inherit",
              width: "inherit",
              objectFit: "contain",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

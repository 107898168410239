import React from "react";
import { Box, Button } from "@mui/material";
import { RobotoTypo, TimesNewTypo } from "../../../utils/Typographies";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
import { dormitoryImages } from "../../LandingPage/newsData";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  customCarouselContainer: {
    padding: "0", // Set padding to 0px for the entire Carousel container
  },
}));

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1200, min: 600 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
export default function SchoolPictures({ data }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const customArrowStyle = {
    position: "absolute",
    top: "50%",
    border: "none",
    width: "30px",
    transform: "translateY(-50%)",
    backgroundColor: "#FFC424", // Change the background color to yellow
    color: "white",
    padding: "8px",
    borderRadius: "50%",
    cursor: "pointer",
    zIndex: 1, // Ensure arrows are above the images
    "@media (max-width: 600px)": {
      right: "5px",
    },
  };
  return (
    data?.school_pitures &&
    data?.school_pitures?.length != 0 && (
      <Box sx={{ position: "relative", background: "#F7F7F7" }}>
        <Box
          sx={{
            height: "95px",
            display: {
              xs: "none",
              md: "block",
              lg: "block",
              xl: "block",
              xxl: "block",
            },
            width: { xs: "15%", sm: "15%", md: "35%", lg: "40%", xl: "40%" },
            borderTopRightRadius: "50px",
            borderRight: "8px solid white",
            borderTop: "8px solid white",
            borderColor: "#F5DFBA",
            position: "absolute",
            top: "0",
            right: "0",
            zIndex: "99",
            transform: "rotate(180deg)",
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            gap: "50px",
            flexDirection: "column",
            justifyContent: "center",
            margin: "0 3%",
          }}
        >
          <Box
            sx={{
              marginTop: "50px",
              alignSelf: "center",
              backgroundColor: "#003B5C",
              padding: "8px 24px",
              borderRadius: "50px",
              width: "fit-content",
            }}
          >
            <RobotoTypo
              sx={{
                fontWeight: "700",
                fontSize: { xs: "18px", sm: "21px", md: "24px" },
                minWidth: "150px",
                color: "#FFFFFF",
                letterSpacing: "0.02em",
                textAlign: "center",
              }}
            >
              {t("School.SchoolView", { returnObjects: true }).title}
            </RobotoTypo>
          </Box>
          <Box
            sx={{
              width: { xs: "80%", lg: "94%" },
              display: "flex",
              flexDirection: "column",
              margin: "0 auto",
              gap: "10px",
              padding: "20px",
            }}
          >
            <Box sx={{ zIndex: "10" }}>
              <Carousel
                className={classes.customCarouselContainer}
                swipeable={true}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={true}
                infinite={true}
                autoPlay={false}
                keyBoardControl={true}
                customTransition="transform 500ms ease-in-out"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={[""]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                customLeftArrow={
                  <button
                    style={{
                      ...customArrowStyle,
                      left: "0px",
                      zIndex: "10000",
                    }}
                  >
                    &lt;
                  </button>
                }
                customRightArrow={
                  <button
                    style={{
                      ...customArrowStyle,
                      right: "5px",
                    }}
                  >
                    &gt;
                  </button>
                }
              >
                {data?.school_pitures?.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      width: { sm: "70%", md: "70%", lg: "75%" },
                      minWidth: { sm: "70%", md: "70%", lg: "90%" },
                      borderRadius: "20px",
                      gap: "15px",
                      flexDirection: "column",
                      "@media (min-width: 600px)": {
                        gap: "15px", // Adjust the gap for smaller screens
                      },
                    }}
                  >
                    <Box
                      component="img"
                      sx={{ width: "300px", height: "240px",borderRadius: "20px" }}
                      src={`${process.env.REACT_APP_FILE_URL}${item}`}
                     
                      alt=""
                    />
                  </Box>
                ))}
              </Carousel>
            </Box>
          </Box>
        </Box>{" "}
        <br /> <br /> <br />
      </Box>
    )
  );
}

import { Box } from "@mui/material";
import React from "react";
import { RobotoTypo } from "../../utils/Typographies";
import { FooterFeaturesData, Qrs } from "../FooterData";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const FooterFeatures = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { xs: "100%", lg: "50% 50%" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: { xs: "10px 0 0 0", sm: 4 },
          width: "100%",
        }}
      >
        <RobotoTypo
          sx={{
            color: "#FFC424",
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            fontSize: { xs: 16, sm: 20 },
          }}
        >
          {t("footer.social-links")}
        </RobotoTypo>{" "}
        <br />
        <Box
          sx={{
            display: "flex",
            // justifyContent: 'flex-start',
            alignItems: { xs: "", sm: "flex-start" },
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
          }}
        >
          {Qrs.map((item, index) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Box
                sx={{
                  padding: "6px",
                  background: "white",
                  display: "grid",
                  placeItems: "center",
                  width: 150,
                  height: 150,
                }}
              >
                <img
                  src={item.image}
                  alt={`${item.title}`}
                  style={{
                    objectFit: "contain",
                    height: "inherit",
                    width: "inherit",
                  }}
                />
              </Box>
              <RobotoTypo
                sx={{
                  fontSize: { xs: 12, sm: 14 },
                  color: "white",
                  textAlign: "center",
                }}
              >
                {t("footer.qrs", { returnObjects: true })[index]}
              </RobotoTypo>
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "100%", sm: "30% 30% 30%" },
          p: { xs: 2, md: 4 },
          gap: { xs: 4, md: 3 },
        }}
      >
        {t("footer.features", { returnObjects: true }).map((item, index) =>
          item.title === "Studying Abroad" ||
          item.title === "留學申請" ||
          item.title === "Services" ||
          item.title === "服務概覽" ||
          item.title === "服务概览" ||
          item.title === "留学申请" ? (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "inherit",
                gap: 1,
              }}
            >
              <RobotoTypo
                sx={{ color: "#FFC424", fontSize: { xs: 16, md: 18 } }}
              >
                {item.title}
              </RobotoTypo>
              {item.data.map((item, index) => (
                <RobotoTypo
                  onClick={() => navigate(item.route)}
                  sx={{
                    color: "white",
                    fontSize: { xs: 14, md: 16 },

                    cursor: "pointer",
                    "&:hover": {
                      color: "#FFC424",
                    },
                  }}
                >
                  {item.name}
                </RobotoTypo>
              ))}
            </Box>
          ) : item.title === "Related Website" ||
            item.title === "相關網站" ||
            item.title === "相关网站" ? (
            <>
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "inherit",
                  gap: 1,
                }}
              >
                <RobotoTypo
                  sx={{ color: "#FFC424", fontSize: { xs: 16, md: 18 } }}
                >
                  {item.title}
                </RobotoTypo>
                {item.data.map((item, index) =>
                  item?.name ? (
                    <>
                      <RobotoTypo
                        onClick={() => window.open(item?.link, "_blank")}
                        sx={{
                          color: "white",
                          fontSize: { xs: 14, md: 16 },

                          cursor: "pointer",
                          "&:hover": {
                            color: "#FFC424",
                          },
                        }}
                      >
                        {item.name}
                      </RobotoTypo>
                    </>
                  ) : (
                    <>
                      <RobotoTypo
                        sx={{ color: "white", fontSize: { xs: 14, md: 16 } }}
                      >
                        {item}
                      </RobotoTypo>
                    </>
                  )
                )}
              </Box>
            </>
          ) : (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "inherit",
                gap: 1,
              }}
            >
              <RobotoTypo
                sx={{ color: "#FFC424", fontSize: { xs: 16, md: 18 } }}
              >
                {item.title}
              </RobotoTypo>
              {item.data.map((item, index) => (
                <RobotoTypo
                  sx={{ color: "white", fontSize: { xs: 14, md: 16 } }}
                >
                  {item}
                </RobotoTypo>
              ))}
            </Box>
          )
        )}
      </Box>
    </Box>
  );
};

export default FooterFeatures;

import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import SuccessIcon from "../assets/payment/Vector.svg";
import pendingIcon from "../assets/payment/pending.png";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";

const PaymentSuccess = ({ data, status }) => {
  const ApplicationFees = (data?.details?.payment?.fee / 100).toFixed(2);
  const amount = data?.details?.payment?.amount; // replace with actual value if needed
  const dividedAmount = (amount / 100).toFixed(2);
  const navigate = useNavigate();

  const paymentDetails = {
    Bank_Name: data?.details?.payment?.card?.bank,
    Payment_Time: moment(data?.details?.payment?.card?.paid_at).format(
      "DD MMM YYYY, HH:mm"
    ),
    Payment_Method: "Bank Transfer",
    Applicant_Name: data?.details?.payment?.card?.name,
  };
  const AmountDetails = {
    Amount: `SGD ${dividedAmount}`,
  };
  const location = useLocation();
  console.log("data", data);
  const paymentTitle = (status) => {
    switch (status) {
      case "successful":
        return "Payment was successful ";

      case "pending":
        return "Your payment is currently pending. ";

      default:
        return "Payment failed.";
    }
  };
  const paymentIcon = (status) => {
    switch (status) {
      case "successful":
        return (
          <img
            style={{ width: "40px", height: "40px" }}
            src={SuccessIcon}
            alt="payment-indicator"
          />
        );

      case "pending":
        return <PendingActionsIcon sx={{ color: "#FF7900 " }} />;

      default:
        return <SmsFailedIcon sx={{ color: "red" }} />;
    }
  };

  return (
    <>
      {" "}
      <style>
        {`
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
        
        .poppins-text {
            font-family: 'Poppins', sans-serif;
        }
    `}
      </style>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        width="100%"
        backgroundColor="#CFD9E8"
        height="100vh"
      >
        <Box
          sx={{
            background: "#ffffff",
            borderRadius: "20px",
            width: "450px",

            py: "20px",
            px: "20px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Box width="100%" display={"flex"} justifyContent={"center"}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: "70px",
                height: "70px",
                borderRadius: "50%",
                backgroundColor:
                  status === "successful"
                    ? "rgba(35, 162, 109, 0.12)"
                    : status === "pending"
                    ? "rgba(255, 121, 0, 0.12)"
                    : "rgba(255, 0, 0, 0.12)",
              }}
            >
              <Box
                sx={{ width: "60px", height: "60px" }}
                display="flex"
                justifyContent={"center"}
                alignItems={"center"}
              >
                {paymentIcon(status)}
              </Box>
            </Box>
          </Box>

          <Stack
            gap={"12px"}
            sx={{
              py: "20px",
            }}
            alignItems={"center"}
          >
            <Typography
              sx={{
                color: "#474747",
                fontFamily: "poppins",
                fontWeight: "400",
                fontSize: "20px",
              }}
            >
              {paymentTitle(status)}
            </Typography>
            <Typography
              sx={{
                color: "121212",
                fontFamily: "poppins",
                fontWeight: "700",
                fontSize: "24px",
              }}
            >
              SGD {dividedAmount}
            </Typography>
          </Stack>
          {status !== "failed" && (
            <>
              <Stack gap="20px" py="20px" px="20px">
                {Object.entries(paymentDetails).map(([key, value], index) => (
                  <Stack
                    key={index}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                  >
                    <Typography
                      sx={{
                        color: "#707070",
                        fontFamily: "poppins",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    >
                      {key.replace(/_/g, " ")}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#121212",
                        fontFamily: "poppins",
                        fontWeight: "600",
                        fontSize: "16px",
                        textTransform: "capitalize",
                      }}
                    >
                      {value}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
              <Stack gap="20px" py="20px" px="20px">
                {Object.entries(AmountDetails).map(([key, value], index) => (
                  <Stack
                    key={index}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                  >
                    <Typography
                      sx={{
                        color: "#707070",
                        fontFamily: "poppins",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    >
                      {key.replace(/_/g, " ")}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#121212",
                        fontFamily: "poppins",
                        fontWeight: "600",
                        fontSize: "16px",
                        textTransform: "capitalize",
                      }}
                    >
                      {value}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </>
          )}
          <Button
            variant="contained"
            sx={{
              borderRadius: "9px",
              textTransform: "capitalize",
              backgroundColor: "#23A26D",
              fontSize: "16px",
              fontFamily: "poppins",
              width: "100%",
              "&:hover": {
                backgroundColor: "#1a7d52", // Change this to your desired hover color
              },
              my: "20px ",
            }}
            onClick={() => {
              navigate("/");
             
            }}
          >
            Go to Homepage
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default PaymentSuccess;

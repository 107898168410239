import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SelfGuidedTourHeader from '../components/Service/SelfGuidedTour/SelfGuidedTourHeader';
import SelfGuidedTourInfo from '../components/Service/SelfGuidedTour/SelfGuidedTourInfo';
import SelfGuidedPlaces from '../components/Service/SelfGuidedTour/SelfGuidedPlaces';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';

export default function SelfGuidedTour() {
    return (
        <>
            <Header />
            <SelfGuidedTourHeader />
            <SelfGuidedTourInfo />
            <SelfGuidedPlaces />
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}
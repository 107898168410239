import React from 'react'
import { Box } from '@mui/material'
import EnquireButton from '../components/sideOverlap/EnquireButton'

export default function SideOverlap() {
  
    return (
        <Box sx={{}}>
            <EnquireButton />
        </Box>
    )
}

import React from "react";
import { Box } from "@mui/material";
import { RobotoTypo } from "../../../utils/Typographies";
import { useTranslation } from "react-i18next";
import { ReactComponent as Circle } from "../../../assets/ServicesImages/StudentPassApplicationImages/circle.svg";

export default function ImmigrationInfo() {
  const { t } = useTranslation();
  return (
    <Box sx={{ padding: "4% 7%" }}>
      <RobotoTypo
        sx={{
          fontWeight: "700",
          letterSpacing: "0.05em",
          color: "#003B5C",
          fontSize: { xs: "20px", sm: "25px", md: "28px", lg: "30px" },
          textAlign: "left",
        }}
      >
        {
          t("service.immigrationConsultation.info", { returnObjects: true })
            .title
        }
      </RobotoTypo>
      <br />

      <Box sx={{ display: "flex", alignItems: "flex-start", gap: "20px" }}>
        <Box>
          <Circle />
        </Box>
        <RobotoTypo
          sx={{
            fontWeight: "400",
            letterSpacing: "0.03em",
            lineHeight: "25px",
            color: "#242424",
            fontSize: { xs: "16px", md: "16px", lg: "16px" },
            textAlign: "left",
          }}
        >
          {
            t("service.immigrationConsultation.info", { returnObjects: true })
              .description1
          }
        </RobotoTypo>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start", gap: "20px" }}>
        <Box>
          <Circle />
        </Box>
        <RobotoTypo
          sx={{
            fontWeight: "400",
            letterSpacing: "0.03em",
            lineHeight: "25px",
            color: "#242424",
            fontSize: { xs: "16px", md: "16px", lg: "16px" },
            textAlign: "left",
          }}
        >
          {
            t("service.immigrationConsultation.info", { returnObjects: true })
              .description2
          }
        </RobotoTypo>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start", gap: "20px" }}>
        <Box>
          <Circle />
        </Box>
        <RobotoTypo
          sx={{
            fontWeight: "400",
            letterSpacing: "0.03em",
            lineHeight: "25px",
            color: "#242424",
            fontSize: { xs: "16px", md: "16px", lg: "16px" },
            textAlign: "left",
          }}
        >
          {
            t("service.immigrationConsultation.info", { returnObjects: true })
              .description3
          }
        </RobotoTypo>
      </Box>
    </Box>
  );
}

import React from "react";
import { Box } from "@mui/material";
import { RobotoTypo, TimesNewTypo } from "../../../utils/Typographies";
import { useTranslation } from "react-i18next";
import { ReactComponent as Circle } from "../../../assets/ServicesImages/StudentPassApplicationImages/circle.svg";
import { useNavigate } from "react-router-dom";

export default function SchoolTourServiceIntro() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box sx={{ position: "relative", background: "#f5dfba" }}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: { xs: "50px 0", md: "100px 0", lg: "80px 0" },
          }}
        >
          <Box
            sx={{
              zIndex: "10000",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              flexDirection: "column",
              gap: "20px",
              width: { xs: "80%", lg: "50%" },
              padding: "50px 30px",
              backgroundColor: "white",
              borderRadius: "50px",
            }}
          >
            <RobotoTypo
              sx={{
                fontWeight: "700",
                color: "#003B5C",
                fontSize: { xs: "22px", sm: "25px", md: "28px", lg: "30px" },
                textAlign: "center",
              }}
            >
              {t("AeisExamPre.serviceIntro", { returnObjects: true }).title}
            </RobotoTypo>
            {t("SchoolBasedTour.serviceIntro.listItems", {
              returnObjects: true,
            }).map((item, index) => (
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "start", gap: "20px" }}
              >
                <Box>
                  <Circle />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <RobotoTypo
                    sx={{
                      fontWeight: "400",
                      letterSpacing: "0.03em",
                      lineHeight: "25px",
                      color: "#242424",
                      fontSize: { xs: "16px", md: "16px", lg: "16px" },
                      textAlign: "left",
                    }}
                  >
                    {item.key}
                  </RobotoTypo>
                  <RobotoTypo
                    sx={{
                      fontWeight: "400",
                      letterSpacing: "0.03em",
                      lineHeight: "25px",
                      color: "#242424",
                      fontSize: { xs: "16px", md: "16px", lg: "16px" },
                      textAlign: "left",
                    }}
                  >
                    {item.value}
                  </RobotoTypo>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

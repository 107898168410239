import "react-multi-carousel/lib/styles.css";
import React, { useState, useRef } from "react";

import Sim from "../../assets/png/brands/SIM.jpg";
import { Carousel } from "react-responsive-carousel";

import Kaplan from "../../assets/png/brands/KAPLAN.jpg";
import JCU from "../../assets/png/brands/JCU.jpg";
import Curtin from "../../assets/png/brands/CURTIN.jpg";
import Eic from "../../assets/png/brands/EIC.png";
import Psb from "../../assets/png/brands/PSB.jpg";
import Ais from "../../assets/png/brands/AIS.png";
import Beason from "../../assets/png/brands/Beason.png";
import Cis from "../../assets/png/brands/CIS.jpg";
import Dimensions from "../../assets/png/brands/Dimensions.png";
import Eaim from "../../assets/png/brands/EAIM.jpg";
import Erc from "../../assets/png/brands/ERC.jpg";
import Etonhouse from "../../assets/png/brands/Etonhouse.webp";
import Fis from "../../assets/png/brands/FIS.jpg";
import Giis from "../../assets/png/brands/GIIS.jpg";
import Hcis from "../../assets/png/brands/HCIS.jpg";
import ISS from "../../assets/png/brands/ISS.png";
import KLC from "../../assets/png/brands/KLC.jpg";
import Lassalle from "../../assets/png/brands/LASALLE.jpg";
import Mdis from "../../assets/png/brands/MDIS.jpg";
import Middleton from "../../assets/png/brands/Middleton.jpg";
import Nafa from "../../assets/png/brands/NAFA-UAS-LOCKUP.png";
import Nexus from "../../assets/png/brands/NEXUS.jpg";
import Nus from "../../assets/png/brands/NUS.jpg";
import NTU from "../../assets/png/brands/NTU.jpg";
import Owis from "../../assets/png/brands/OWIS.jpg";
import Raffles from "../../assets/png/brands/Raffles.jpg";
import Sais from "../../assets/png/brands/SAIS.jpg";
import SanYu from "../../assets/png/brands/San Yu.jpg";
import Shelton from "../../assets/png/brands/SHELTON.jpg";
import Shatec from "../../assets/png/brands/SHATEC.png";
import SHRM from "../../assets/png/brands/SHRM.jpg";
import Smu from "../../assets/png/brands/SMU.jpg";
import Sstc from "../../assets/png/brands/SSTC.jpg";
import Francis from "../../assets/png/brands/St Francis.jpg";
import TMC from "../../assets/png/brands/TMC.jpg";
import UWC from "../../assets/png/brands/UWC.png";
import XCL from "../../assets/png/brands/XCL.jpg";
import Amity from "../../assets/png/brands/Amity.jpg";
import { Box, Typography, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RobotoTypo } from "../../utils/Typographies";
import { makeStyles } from "@mui/styles";
import { styled, keyframes } from "@mui/system";
import { ReactComponent as Circle } from "../../assets/ServicesImages/StudentPassApplicationImages/circle.svg";

const BrandImages = [
  Sim,
  Kaplan,
  JCU,
  Curtin,
  Eic,
  Psb,
  Ais,
  Beason,
  Cis,
  Dimensions,
  Eaim,
  Erc,
  Etonhouse,
  Fis,
  Giis,
  Hcis,
  ISS,
  KLC,
  Lassalle,
  Mdis,
  Middleton,
  Nafa,
  Nexus,
  Nus,
  NTU,
  Owis,
  Raffles,
  Sais,
  SanYu,
  Shelton,
  Shatec,
  SHRM,
  Smu,
  Sstc,
  Francis,
  TMC,
  UWC,
  XCL,
  Amity,
];
const slide = keyframes`
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-1000%);
    }
`;

// Styled component for the carousel wrapper
const CarouselWrapper = styled("div")({
  whiteSpace: "nowrap",
  width: "100%",
  position: "relative",
  marginTop: "30px",
});

// Styled component for the image container
const ImageContainer = styled("div")({
  display: "inline-block",
  whiteSpace: "nowrap",
  animation: `${slide} 260s linear infinite`,
  width: "200%", // Double the width to ensure smooth looping

  // Media query to increase speed on mobile
  "@media (max-width: 768px)": {
    animation: `${slide} 100s linear infinite`, // Reduce the duration to increase speed on mobile
  },
});

// Styled component for the image
const Image = styled("img")({
  objectFit: "cover",
  display: "inline-block",
  maxWidth: "100%",
  height: "60px",
  marginLeft: "150px", // Each image should take up equal space
});

const Brands = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        py: { xs: 4, lg: 6 },
        background: "white",
        boxShadow: "0 6px 20px rgba(0, 0, 0, 0.2)",
        mx: 4,
        my: 4,
        borderRadius: "20px",
        overflow: "hidden",
      }}
    >
      <RobotoTypo
        sx={{
          textAlign: "center",
          fontSize: { xs: 20, sm: 24, lg: 32 },
          color: "#003B5C",
          fontWeight: 700,
        }}
      >
        {t("BrandsTitle")}
      </RobotoTypo>
      <Container sx={{ width: "100%", marginTop: "30px", pt: 2, pb: 4 }}>
        <CarouselWrapper>
          <ImageContainer>
            {BrandImages.concat(BrandImages).map((src, index) => (
              <Image src={src} alt={`Image ${index + 1}`} key={index} />
            ))}
          </ImageContainer>
        </CarouselWrapper>
      </Container>
    </Box>
  );
};

export default Brands;

import { ReactComponent as CultureIcon } from '../../assets/AboutUsImages/WhyChooseDR/culture.svg';
import { ReactComponent as Experienced } from '../../assets/AboutUsImages/WhyChooseDR/experienced.svg';
import { ReactComponent as OneStopService } from '../../assets/AboutUsImages/WhyChooseDR/oneStopService.svg';
import { ReactComponent as Passionate } from '../../assets/AboutUsImages/WhyChooseDR/passionate.svg';
import { ReactComponent as Progression } from '../../assets/AboutUsImages/WhyChooseDR/progression.svg';
import { ReactComponent as Rationality } from '../../assets/AboutUsImages/WhyChooseDR/rationality.svg';

export const data = [
    {
        id:1,
        icon: <CultureIcon />,
        title: 'Service Culture',
        description: 'Committed to providing comprehensive service for student that are studying'
    },
    {
        id:2,
        icon: <Passionate />,
        title: 'Passionate',
        description: 'Trained, Abled, Confident, and Customized suggestions'
    },
    {
        id:3,
        icon: <Experienced />,
        title: 'Experienced',
        description: 'Accurate information for precise data analysis and comparison'
    },
    {
        id:4,
        icon: <OneStopService />,
        title: 'One-Stop Service',
        description: 'Accustomed to student of different language ability'
    },
    {
        id:5,
        icon: <Rationality />,
        title: 'Solving Problems with Rationality',
        description: 'Our team focuses on solving complicated issue using the most efficient method'
    },
    {
        id:6,
        icon: <Progression />,
        title: 'Non-stop Progression',
        description: 'Continuous effort to understand and upgrade service method for better and broader range of service'
    }
]
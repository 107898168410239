// import React, { useState, useEffect } from "react";
// import {
//   Typography,
//   Box,
//   Divider,
//   Button,
//   TableContainer,
//   Table,
//   TableHead,
//   TableRow,
//   TableCell,
//   TableBody,
//   Paper,
//   Stack,
//   IconButton,
//   TablePagination
// } from "@mui/material";
// import { useNavigate, useParams } from "react-router-dom";
// import axios from "axios";
// import moment from "moment/moment";

// export default function Applications() {
// const { id } = useParams()
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   const [totalItems, setTotalItems] = useState(0);
//   const [loading, setLoading] = useState(false);

//   const navigate = useNavigate();

//   const [studentData, setStudentData] = useState([]);

//   const getStudentApplications = async () => {
//     setLoading(true)
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BASE_URL}/getStudentDetails/${id}?page=${
//           page + 1
//         }&limit=${rowsPerPage}`
//       );
//       console.log(response?.data);
//       setTotalItems(response.data.total || 0)
//       setStudentData(response?.data?.data || []);
//       setLoading(false)
//     } catch (error) {
//       console.log(error);
//       setLoading(false)
//     }
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   useEffect(() => {
//     getStudentApplications();
//   }, [id, page, rowsPerPage]);

//   return (
//     <>

//     </>
//   );
// }

import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { RobotoTypo } from "../../utils/Typographies";
import { Box } from "@mui/system";
import { Button, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/slice";

const Applications = () => {
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
  const [totalItems, setTotalItems] = useState(0); // Total count of items
  const [loading, setLoading] = useState(false);
  const [studentData, setStudentData] = useState([]);
  const navigate = useNavigate();
  const [prevStudentDataLength, setPrevStudentDataLength] = useState(0);

  const dispatch = useDispatch();
  const userData = localStorage.getItem("student_data")
    ? JSON.parse(localStorage.getItem("student_data"))
    : "";

  const getStudentApplications = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getStudentData/${
          userData._id
        }?page=${page + 1}&limit=${rowsPerPage}`
      );
      console.log(response.data);
      setTotalItems(response.data.total || 0);
      setStudentData(response.data.data || []);

      dispatch(actions.triggerOmise(response.data.data));
    } catch (error) {
      console.error("Error fetching student applications:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStudentApplications();
  }, [userData._id, page, rowsPerPage]);
  useEffect(() => {
    // Check if the length of studentData has changed
    if (studentData.length !== prevStudentDataLength) {
      // If the data length has changed, reload the page
      // window.location.reload();
    }

    // Update the previous data length state
    setPrevStudentDataLength(studentData.length);
  }, [studentData]);

  // Pagination logic
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const  handleNavigate = (data) => {
    if (data.student.StudentDetails.type_id == "school") {
      navigate(`/student/applications/school/${data.student._id}`);
    } else {
      navigate(`/student/applications/university/${data.student._id}`);
    }
  };

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : studentData.length === 0 ? (
        <p>No data found</p>
      ) : (
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            boxShadow: "0px 0px 30px 5px #f4f4f4",
          }}
        >
          <thead>
            <tr>
              <th style={{ border: "1px solid #ccc", padding: "18px" }}>
                <RobotoTypo sx={{ fontWeight: 700 }}>Institute</RobotoTypo>
              </th>
              <th style={{ border: "1px solid #ccc", padding: "18px" }}>
                <RobotoTypo sx={{ fontWeight: 700 }}>Description</RobotoTypo>
              </th>
              <th style={{ border: "1px solid #ccc", padding: "18px" }}>
                <RobotoTypo sx={{ fontWeight: 700 }}>Date</RobotoTypo>
              </th>
              <th style={{ border: "1px solid #ccc", padding: "18px" }}>
                <RobotoTypo sx={{ fontWeight: 700 }}>Actions</RobotoTypo>
              </th>
            </tr>
          </thead>
          <tbody>
            {studentData.map((item, index) => (
              <tr
                key={item._id}
                style={{
                  backgroundColor: index % 2 === 0 ? "#f8f8f8" : "white",
                }}
              >
                <td style={{ border: "1px solid #ccc", padding: "15px" }}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <RobotoTypo>
                      {item?.school?.en?.school_name || "-"}
                    </RobotoTypo>
                  </Box>
                </td>
                <td style={{ border: "1px solid #ccc", padding: "15px" }}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <RobotoTypo>
                      {item?.school?.en?.description?.slice(0, 20) || "-"}...
                    </RobotoTypo>
                  </Box>
                </td>
                <td style={{ border: "1px solid #ccc", padding: "15px" }}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <RobotoTypo>
                      {moment(item?.student?.StudentDetails?.createdAt).format(
                        "MMMM DD, YYYY h:mm:ss A"
                      ) || "-"}
                    </RobotoTypo>
                  </Box>
                </td>
                <td style={{ border: "1px solid #ccc", padding: "15px" }}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      style={{ p: 2, textTransform: "none" }}
                      onClick={() => {
                        handleNavigate(item);
                      }}
                    >
                      View
                    </Button>
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Pagination Controls */}
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box style={{ marginTop: "20px", display: "flex" }}>
          <Button
            sx={{
              textTransform: "none",
              backgroundColor: "#167d84",
              color: "white",
              mx: 2,
              ":hover": { backgroundColor: "#167d84", color: "white" },
            }}
            disabled={page === 0}
            onClick={() => handleChangePage(page - 1)}
          >
            Previous
          </Button>
          
          <Button
            sx={{
              textTransform: "none",
              backgroundColor: "#167d84",
              color: "white",
              ":hover": { backgroundColor: "#167d84", color: "white" },
            }}
            disabled={page >= totalItems - 1}
            onClick={() => handleChangePage(page + 1)}
          >
            Next
          </Button>
         
        </Box>
      </Box> */}
    </div>
  );
};

export default Applications;

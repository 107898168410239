import React from 'react'
import { Box, Button } from '@mui/material';
import { RobotoTypo, TimesNewTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Circle } from '../../../assets/ServicesImages/StudentPassApplicationImages/circle.svg';
import MobileImage from "../../../assets/ServicesImages/DrAppImages/mobile.png";
import DownloadOptions from './DownloadOptions';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    // Media query for screens narrower than 1024px
    mobileImg: {
        width: '75%'
    },
    '@media (max-width: 580px)': {
        mobileImg: {
            width: '60%'
        }
    },
}));

export default function DrAppServiceIntro() {
    const { t } = useTranslation();
    const classes = useStyles();
    const introData = [
        "Applying for student pass that includes filling up of formalities, preparing necessary document, and submission of application.",
        "Provide information and guidance for pass application.",
        "Assist in preparing for interview material and related preparation.",
        "Follow up and tracking of application process, provide latest application status.",
        "Deal with transference and postponing of pass related issues.",
    ];
    return (
        <Box sx={{ position: 'relative', background: '#F7F7F7' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Box
                    sx={{
                        borderBottomRightRadius: { xs: '0', md: '0', lg: '50px', xl: '50px', xxl: '50px' },
                        // padding: { xs: '0', sm: "0", md: '0', lg: "40px", xl: '40px' },
                        padding: '40px 0',
                        zIndex: 10000,
                        height: { xs: '11%', sm: "11%", md: '11%', lg: "15%", xl: '15%' },
                        width: { xs: '100%', sm: "100%", md: '100%', lg: "30%", xl: '30%' },
                        position: { xs: 'relative', sm: "relative", md: 'relative', lg: "absolute", xl: 'absolute' },
                        left: '0',
                        top: '0',
                        background: '#F5DFBA',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >

                    {/* {t('aboutUs.why_dr', { returnObjects: true }).map((item, index) => ( */}
                    <Box
                        // key={index}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <RobotoTypo sx={{ fontWeight: '700', color: '#003B5C', fontSize: { xs: '22px', sm: '25px', md: '28px', lg: '30px' }, textAlign: 'center' }}>
                            {t('service.DrApp.DrAppServiceIntro', { returnObjects: true }).title1}                          </RobotoTypo>
                        <TimesNewTypo sx={{ fontWeight: '700', color: '#177D84', fontSize: { xs: '18px', md: '22px', lg: '24px' }, textAlign: 'center' }}>
                            {t('service.DrApp.DrAppServiceIntro', { returnObjects: true }).title2}
                        </TimesNewTypo>
                    </Box>
                    {/* ))} */}

                </Box>
                <Box sx={{ flexDirection: { xs: 'column', lg: 'row' }, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: { xs: '50px 0', md: '100px 0' } }}>
                    <Box sx={{ gap: '10px', display: 'flex', flexDirection: 'column', zIndex: '10000', width: { lg: "100%" } }}>
                        <img
                            src={MobileImage}
                            alt='MobileImage'
                            className={classes.mobileImg}
                            style={{
                                objectFit: 'cover',
                                height: '100%',
                                // width: '100%',
                                borderRadius: '24px',
                                minWidth: '150px',
                                alignSelf: 'center'
                                // maxWidth: '600px'
                            }}
                        />
                        <DownloadOptions />
                    </Box >
                    <Box sx={{
                        zIndex: '10000',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'start',
                        alignSelf: { xs: 'center', lg: 'stretch' },
                        flexDirection: 'column',
                        gap: '20px',
                        padding: '50px 10px 50px 10px',
                        borderRadius: '50px',
                        width: { xs: '80%', sm: '50%', md: '50%', lg: "33.33%%" }
                    }}>

                        {t('service.DrApp.DrAppServiceIntro.row1', { returnObjects: true }).map((item, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <Box

                                ><Circle /></Box>
                                <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '16px', md: '16px', lg: '16px' }, textAlign: 'left' }}>
                                    {item}
                                </RobotoTypo>

                            </Box>
                        ))}

                    </Box>

                    <Box sx={{
                        zIndex: '10000',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'start',
                        alignSelf: { xs: 'center', lg: 'stretch' },
                        justifyContent: 'flex-start',
                        flexDirection: 'column',
                        gap: '20px',
                        padding: '50px 10px 50px 10px',
                        borderRadius: '50px',
                        width: { xs: '80%', sm: "50%", md: '50%', lg: "33.33%%" }
                    }}>
                        {t('service.DrApp.DrAppServiceIntro.row2', { returnObjects: true }).map((item, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <Box

                                ><Circle /></Box>
                                <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '16px', md: '16px', lg: '16px' }, textAlign: 'left' }}>
                                    {item}
                                </RobotoTypo>

                            </Box>
                        ))}
                    </Box>



                </Box>
            </Box>
        </Box >
    )
}



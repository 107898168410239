import { createSlice } from "@reduxjs/toolkit";

const languageSlice = createSlice({
  name: "languageSlice",
  initialState: {
    language: "ch",
    applicationFee: "",
    paymentDetails: null,
    triggerOmise: null,
  },
  reducers: {
    toggleLanguage: (state, action) => {
      console.log("state.language ", action);

      switch (action.payload) {
        case "en":
          state.language = "en";
          break;
        case "ch":
          state.language = "ch";
          break;
        case "sch":
          state.language = "sch";
          break;
      }
    },
    applicationFee: (state, action) => {
      state.applicationFee = action.payload;
    },
    paymentDetails: (state, action) => {
      state.paymentDetails = action.payload;
    },
    triggerOmise: (state, action) => {
      state.triggerOmise = action.payload;
    },
  },
});

export const { actions, reducer } = languageSlice;

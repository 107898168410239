import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const DeleteSchoolModal = ({
  school,
  open,
  onClose,
  handleDelete,
  deleteSchoolId,
}) => {
  return (
    <div>
      <Dialog fullWidth open={open} onClose={onClose}>
        <DialogTitle sx={{ borderBottom: "2px solid #eceef3" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography style={{ fontSize: "20px", fontWeight: 600 }}>
              {" "}
              Confirmation
            </Typography>
            <Close
              onClick={() => {
                onClose();
              }}
              style={{ cursor: "pointer" }}
            />
          </Box>
        </DialogTitle>
        <DialogContent style={{ padding: "30px 24px" }}>
          <Typography>Are You Sure You want to delete {school}?</Typography>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "15px 24px",
            borderTop: "2px solid #eceef3",
          }}
        >
          <Button
            onClick={() => {
              handleDelete(deleteSchoolId);
              onClose();
            }}
            style={{
              borderRadius: "8px",
              backgroundColor: "#0E5E84",
              padding: "8px 14px",
              color: "#FFFFFF",
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteSchoolModal;

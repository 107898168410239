import { Box } from '@mui/system'
import React from 'react'
import { RobotoTypo } from '../../utils/Typographies'

function Payments() {
  return (
    <Box>
        <RobotoTypo>Payments</RobotoTypo>
    </Box>
  )
}

export default Payments
import {
  Select,
  MenuItem,
  ListItemText,
  InputAdornment,
  TextField,
  IconButton,
  Stack,
  Grid,
  List,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SearchBody = () => {
  const schoolOptions = [
    { value: "primarySchool", label: "Primary School" },
    { value: "secondarySchool", label: "Secondary School" },
    { value: "internationalSchool", label: "International School" },
    { value: "university", label: "University" },
    { value: "languageCourse", label: "Language Course" },
    { value: "kindergarten", label: "ChildCare & Kindergarten" },
  ];
  const [schoolList, setSchoolList] = React.useState([]);
  const navigate = useNavigate();
  const [selected, setSelected] = useState(null);
  const handleSelect = (e) => {
    setSelected(e.target.value);
  };
  useEffect(() => {
    if (selected) {
      const navigateObj = schoolList.filter(
        (value) => value.label === selected
      );
      navigate(`/school/${navigateObj[0].id}`);
    }
  }, [selected]);
  const getSchools = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getSchools`
      );
      console.log("response get ", response);
      if (response.status == 200) {
        if (Array.isArray(response?.data)) {
          setSchoolList(
            response?.data
              ?.map((school) => {
                if (school?.en) {
                  return {
                    label: school?.en?.school_name,
                    id: school?._id,
                  };
                }
                return null;
              })
              .filter(Boolean)
          );
        } else if (
          typeof response?.data === "object" &&
          response?.data !== null
        ) {
          setSchoolList(response?.data != "" ? [response?.data] : []);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getSchools();
  }, []);
  console.log("school", schoolList);

  return (
    <>
      <Stack width="100%" gap="20px" py="20px">
        <Box mx="5%">
          <Typography
            variant="h5" // Adjust the variant for size
            sx={{
              fontWeight: "bold", // Make the text bold
              color: "#333", // Dark gray color for better readability
              textAlign: "center", // Center align the text
              padding: "10px", // Add padding around the text
              backgroundColor: "#f5f5f5", // Light background for contrast
              borderRadius: "8px", // Rounded corners
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)", // Subtle shadow
            }}
          >
            Search Schools
          </Typography>
        </Box>
        <TextField
          select
          name="school_types"
          onChange={handleSelect}
          sx={{ width: "90%", mx: "5%", textTransform: "capitalize" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon sx={{ color: "#FFC424", fontSize: "32px" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          placeholder="Search" // Placeholder text
        >
          <MenuItem value="" disabled>
            <em>Select a school type...</em> {/* Placeholder text */}
          </MenuItem>
          {schoolList.map((option) => (
            <MenuItem key={option.label} value={option.label}>
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </>
  );
};

export default SearchBody;

import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ImmigrationHeader from '../components/Service/ImmigrationConsultation/ImmigrationHeader';
import ImmigrationInfo from '../components/Service/ImmigrationConsultation/ImmigrationInfo';
import Share from '../components/Share';
import SideOverlap from './sideOverlap'

export default function InvestmentConsultation() {
    return (
        <>
            <Header/>
            <ImmigrationHeader />
            <ImmigrationInfo />
            <Share />
            <Footer />
            <SideOverlap/>
        </>
    )
}
import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RobotoTypo, TimesNewTypo } from "../../utils/Typographies";

export default function ContactUsBody() {
  const { t } = useTranslation();
  return (
    <Box sx={{ padding: "4% 7%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          fontSize: "16px",
        }}
      >
        <RobotoTypo
          sx={{
            fontWeight: "500",
            color: "#003B5C",
            fontSize: { xs: "22px", sm: "25px", md: "28px", lg: "30px" },
            textAlign: "left",
          }}
        >
          {t("contactUs.info", { returnObjects: true }).title1}
        </RobotoTypo>
        <TimesNewTypo
          sx={{
            fontWeight: "700",
            color: "#177D84",
            fontSize: { xs: "20px", md: "23px", lg: "24px" },
            textAlign: "left",
          }}
        >
          {t("contactUs.info", { returnObjects: true }).title2}
        </TimesNewTypo>
      </Box>
      <br />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", sm: "row", md: "row" },
          gap: "50px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: { xs: "100%", md: "50%" },
          }}
        >
          <Box sx={{ position: "relative" }}>
            <span
              style={{
                position: "absolute",
                // left: '-20px',
                top: "15px",
                display: "inline-block",
                width: "8px",
                height: "8px",
                borderRadius: "20px",
                backgroundColor: "#FFC424",
              }}
            ></span>

            <Box
              sx={{
                display: "flex",
                gap: "15px",
                marginLeft: "25px",
                alignItems: "center",
              }}
            >
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  letterSpacing: "0.02em",
                  lineHeight: "35px",
                  color: "#003B5C",
                  fontSize: { xs: "18px", md: "18px", lg: "18px" },
                  textAlign: "left",
                }}
              >
                {t("contactUs.info.address", { returnObjects: true }).name}
              </RobotoTypo>
              <RobotoTypo
                sx={{
                  fontWeight: "400",
                  letterSpacing: "0.03em",
                  lineHeight: "25px",
                  color: "#242424",
                  fontSize: { xs: "16px", md: "16px", lg: "16px" },
                  textAlign: "left",
                }}
              >
                {
                  t("contactUs.info.address", { returnObjects: true })
                    .description
                }
              </RobotoTypo>
            </Box>
          </Box>

          <Box sx={{ position: "relative" }}>
            <span
              style={{
                position: "absolute",
                // left: '-20px',
                top: "15px",
                display: "inline-block",
                width: "8px",
                height: "8px",
                borderRadius: "20px",
                backgroundColor: "#FFC424",
              }}
            ></span>

            <Box
              sx={{
                display: "flex",
                gap: "15px",
                marginLeft: "25px",
                alignItems: "center",
              }}
            >
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  letterSpacing: "0.02em",
                  lineHeight: "35px",
                  color: "#003B5C",
                  fontSize: { xs: "18px", md: "18px", lg: "18px" },
                  textAlign: "left",
                }}
              >
                {t("contactUs.info.tel", { returnObjects: true }).name}
              </RobotoTypo>
              <RobotoTypo
                sx={{
                  fontWeight: "400",
                  letterSpacing: "0.03em",
                  lineHeight: "25px",
                  color: "#242424",
                  fontSize: { xs: "16px", md: "16px", lg: "16px" },
                  textAlign: "left",
                }}
              >
                {t("contactUs.info.tel", { returnObjects: true }).description}
              </RobotoTypo>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: { xs: "100%", md: "50%" },
          }}
        >
          <Box sx={{ position: "relative" }}>
            <span
              style={{
                position: "absolute",
                // left: '-20px',
                top: "15px",
                display: "inline-block",
                width: "8px",
                height: "8px",
                borderRadius: "20px",
                backgroundColor: "#FFC424",
              }}
            ></span>

            <Box
              sx={{
                display: "flex",
                gap: "15px",
                marginLeft: "25px",
                alignItems: "center",
              }}
            >
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  letterSpacing: "0.02em",
                  lineHeight: "35px",
                  color: "#003B5C",
                  fontSize: { xs: "18px", md: "18px", lg: "18px" },
                  textAlign: "left",
                }}
              >
                {t("contactUs.info.email", { returnObjects: true }).name}
              </RobotoTypo>
              <RobotoTypo
                sx={{
                  fontWeight: "400",
                  letterSpacing: "0.03em",
                  lineHeight: "25px",
                  color: "#242424",
                  fontSize: { xs: "16px", md: "16px", lg: "16px" },
                  textAlign: "left",
                }}
              >
                {t("contactUs.info.email", { returnObjects: true }).description}
              </RobotoTypo>
            </Box>
          </Box>

          <Box sx={{ position: "relative" }}>
            <span
              style={{
                position: "absolute",
                // left: '-20px',
                top: "15px",
                display: "inline-block",
                width: "8px",
                height: "8px",
                borderRadius: "20px",
                backgroundColor: "#FFC424",
              }}
            ></span>

            <Box sx={{ display: "flex", gap: "15px", marginLeft: "25px" }}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  letterSpacing: "0.02em",
                  lineHeight: "35px",
                  color: "#003B5C",
                  fontSize: { xs: "18px", md: "18px", lg: "18px" },
                  textAlign: "left",
                }}
              >
                {t("contactUs.info.website", { returnObjects: true }).name}
              </RobotoTypo>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  justifyContent: "flex-start",
                }}
              >
                <a href="https://www.dredugroup.com" target="_blank">
                  <RobotoTypo
                    sx={{
                      fontWeight: "400",
                      letterSpacing: "0.03em",
                      lineHeight: "25px",
                      color: "#242424",
                      fontSize: { xs: "16px", md: "16px", lg: "16px" },
                    }}
                  >
                    {
                      t("contactUs.info.website", { returnObjects: true })
                        .description1
                    }
                  </RobotoTypo>
                </a>

                <a href="https://www.vinga.com.sg" target="_blank">
                  <RobotoTypo
                    sx={{
                      fontWeight: "400",
                      letterSpacing: "0.03em",
                      lineHeight: "25px",
                      color: "#242424",
                      fontSize: { xs: "16px", md: "16px", lg: "16px" },
                    }}
                  >
                    {
                      t("contactUs.info.website", { returnObjects: true })
                        .description2
                    }
                  </RobotoTypo>
                </a>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

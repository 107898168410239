import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import EndrollmentTrainingHeader from '../components/StudyingAboard/EndrollmentTraining/EndrollmentTrainingHeader';
import EndrollmentTrainingInfo from '../components/StudyingAboard/EndrollmentTraining/EndrollmentTrainingInfo';
import EndrollmentTrainingServiceIntro from '../components/StudyingAboard/EndrollmentTraining/EndrollmentTrainingServiceIntro';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';

export default function EndrollmentTraining() {
    return (
        <>
            <Header />
            <EndrollmentTrainingHeader />
            <EndrollmentTrainingInfo />
            <EndrollmentTrainingServiceIntro/>
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}
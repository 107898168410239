import { Card, CardHeader, IconButton, Snackbar, Typography } from '@mui/material'
import React, { useState } from 'react'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { RobotoTypo } from './Typographies';
export default function ToastAlert({ message, status, icon, color,closeFunc }) {
    const [state, setState] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;
    const renderIcon = (icon) => {
        if (icon === 'done') {
            return <DoneIcon />
        } else  if (icon === 'close'){
            return <CloseIcon />
        }


    }
    const renderBorderColor = (colors) => {
        return { color: colors }
    }
    return (
        <>
            {status ?

                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={status}

                    key={vertical + horizontal}
                >
                    <Card>
                        <CardHeader
                            sx={{ borderLeft: `10px solid ${color}` }}
                            action={
                                <IconButton aria-label="settings" onClick={closeFunc?()=>closeFunc():''}>
                                    {renderIcon(icon)}
                                </IconButton>
                            }
                            title={<RobotoTypo>{message}</RobotoTypo>}
                        />
                    </Card>
                </Snackbar>
                : ''}
        </>
    )
}

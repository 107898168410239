import React, { useState } from "react";
import {
  Box,
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Button,
} from "@mui/material";
import { RobotoTypo } from "../../utils/Typographies";
import { makeStyles } from "@mui/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { ReactComponent as Hover1 } from "../../assets/ContactUs/DateIcon.svg";
import { useTranslation } from "react-i18next";

export const contactFormStyles = makeStyles((theme) => ({
  TextField: {
    "& .MuiInputBase-input": {
      padding: "10px 10px",
      borderRadius: "5px",
      backgroundColor: "white",
      boxShadow: "2px 2px 4px 0px #00000040 inset",
    },
    "& fieldset": { border: "none" },
  },

  selectIcon: {
    "& .MuiInputBase-input": {
      padding: "10px 10px",
      borderRadius: "5px",
      backgroundColor: "white",
      boxShadow: "2px 2px 4px 0px #00000040 inset",
    },
    "& fieldset": { border: "none" },
    "& .MuiSvgIcon-root.MuiSelect-icon": {
      color: "#177D84", // Set your desired color here
    },
  },
}));

export default function ContactForm() {
  const [qualification, setQualification] = React.useState("");
  const classes = contactFormStyles();
  const { t } = useTranslation();

  const handleAgeChange = (event) => {
    setQualification(event.target.value);
  };

  const ageOptions = ["SSLC", "HSC", "BACHELOR DEGREE", "MASTER DEGREE"];

  return (
    <Box sx={{ padding: "4% 10%", backgroundColor: "#F7F7F7" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "90px",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{ flex: "1" }}>
            <RobotoTypo
              sx={{
                fontWeight: "500",
                color: "#003B5C",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              {t("contactUs.form", { returnObjects: true }).name}
            </RobotoTypo>
            <TextField
              className={classes.TextField}
              variant="outlined"
              InputProps={{ border: "none", outline: "none" }}
              sx={{ flex: "1", width: "100%", marginTop: "10px" }}
            />
          </Box>
          <Box sx={{ flex: "1" }}>
            <RobotoTypo
              sx={{
                fontWeight: "500",
                color: "#003B5C",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              {t("contactUs.form", { returnObjects: true }).dob}
            </RobotoTypo>
            <Box className={classes.TextField}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    renderInput={(params) => <TextField {...params} />}
                    format="YYYY/MM/DD" // Set the desired date format here
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
            {/* <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} /> */}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "90px",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{ flex: "1" }}>
            <RobotoTypo
              sx={{
                fontWeight: "500",
                color: "#003B5C",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              {t("contactUs.form", { returnObjects: true }).contactNo}{" "}
            </RobotoTypo>
            <TextField
              className={classes.TextField}
              variant="outlined"
              InputProps={{ border: "none" }}
              sx={{ flex: "1", width: "100%", marginTop: "10px" }}
            />
          </Box>
          <Box sx={{ flex: "1" }}>
            <RobotoTypo
              sx={{
                fontWeight: "500",
                color: "#003B5C",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              {t("contactUs.form", { returnObjects: true }).email}{" "}
            </RobotoTypo>
            <TextField
              className={classes.TextField}
              variant="outlined"
              InputProps={{ border: "none" }}
              sx={{ flex: "1", width: "100%", marginTop: "10px" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "90px",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{ flex: "1" }}>
            <RobotoTypo
              sx={{
                fontWeight: "500",
                color: "#003B5C",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              Line
            </RobotoTypo>
            <TextField
              className={classes.TextField}
              variant="outlined"
              InputProps={{ border: "none" }}
              sx={{ flex: "1", width: "100%", marginTop: "10px" }}
            />
          </Box>
          <Box sx={{ flex: "1" }}>
            <RobotoTypo
              sx={{
                fontWeight: "500",
                color: "#003B5C",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              {t("contactUs.form", { returnObjects: true }).wechat}{" "}
            </RobotoTypo>
            <TextField
              className={classes.TextField}
              variant="outlined"
              InputProps={{ border: "none" }}
              sx={{ flex: "1", width: "100%", marginTop: "10px" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "90px",
            flexDirection: { xs: "column", sm: "row" },
          }}
          justifyContent={"space-between"}
        >
          <Box sx={{ width: { xs: "100%", sm: "100%", md: "50%" } }}>
            <RobotoTypo
              sx={{
                fontWeight: "500",
                color: "#003B5C",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              WhatsApp
            </RobotoTypo>
            <FormControl fullWidth>
              <TextField
                className={classes.TextField}
                variant="outlined"
                InputProps={{ border: "none" }}
                sx={{ flex: "1", width: "100%", marginTop: "10px" }}
              />
            </FormControl>
          </Box>

          <Box sx={{ width: { xs: "100%", sm: "100%", md: "50%" } }}>
            <RobotoTypo
              sx={{
                fontWeight: "500",
                color: "#003B5C",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              {t("contactUs.form", { returnObjects: true }).qualification}{" "}
            </RobotoTypo>
            <FormControl fullWidth>
              <Select
                sx={{ marginTop: "10px" }}
                className={classes.selectIcon}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={qualification}
                onChange={handleAgeChange}
              >
                {ageOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ flex: "1" }}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {" "}
            {t("contactUs.form", { returnObjects: true }).remarks}{" "}
          </RobotoTypo>
          <TextField
            className={classes.TextField}
            // variant="outlined"
            multiline
            rows={4}
            InputProps={{
              style: { border: "none", height: "100px", padding: "10px 0" },
            }}
            sx={{
              flex: "1",
              width: "100%",
              marginTop: "10px",
              padding: "10px 0",
            }}
          />
        </Box>

        <Box sx={{ textAlign: "center" }}>
          <Button
            disableElevation
            variant="contained"
            sx={{
              mt: 5,
              borderRadius: "50px",
              textAlign: "center",
              backgroundColor: "#FFC424",
              "&:hover": {
                backgroundColor: "#FFC424",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
                padding: "6px 5px",
              }}
            >
              <RobotoTypo
                sx={{
                  color: "#FFFFFF",
                  letterSpacing: "0.02em",
                  textTransform: "none",
                  lineHeight: "32px",
                  fontWeight: "700",
                  fontSize: { xs: 18, sm: 18, md: 21, lg: 21 },
                }}
              >
                {t("contactUs.form", { returnObjects: true }).submit}
              </RobotoTypo>
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

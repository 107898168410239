import React from 'react'
import { Box } from '@mui/material';
import { RobotoTypo, TimesNewTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import { getId } from '../../../utils/common';

export default function Schoolintro({data}) {
    const { t } = useTranslation();

    
    // const myId = getId();
    
    return (
        <Box sx={{padding: '4%'}}>
        <Box sx={{  display:'flex', gap:'60px', width:'100%', alignItems:'center', flexDirection:{xs:'column',lg:'row'} }}>
            <Box sx={{ position:'relative', width:{xs:'100%',lg:'45%'}, height:'350px' }}>
                <iframe
                style={{left:0,top:0,height:'100%',width:'100%', position:'absolute'}}
                width="600px"
                src={ data?.url ? `https://www.youtube.com/embed/${getId(data?.url)}` : ''}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
                />
            </Box>
         
            <Box sx={{ width:{xs:'100%',lg:'45%'}}}>
            <Box sx={{mb:4,  display:'flex', justifyContent:'center', alignItems:'center', backgroundColor: '#003B5C', padding: '8px 12px', borderRadius: '50px', width:'fit-content' }
            } >
                <RobotoTypo sx={{ letterSpacing: '0.02em', fontWeight: '700', color: 'white', fontSize: { xs: '20px', sm: '22px', md: '22px', lg: '22px' }, textAlign: 'left' }}>
                {t('School.schoolIntro', { returnObjects: true }).title}
                </RobotoTypo><br />
            </ Box>
                <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: '16px', textAlign: 'left' }}>
                    {data?.description}
                </RobotoTypo>
            </Box>
            
        </Box>
        </Box>
    )
}

import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ALevelHeader from '../components/StudyingAboard/ALevelPreCourse/ALevelHeader';
import ALevelInfo from '../components/StudyingAboard/ALevelPreCourse/ALevelInfo';
import ALevelServiceIntro from '../components/StudyingAboard/ALevelPreCourse/ALevelServiceIntro';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';

export default function ALevelPreCourse() {
    return (
        <>
            <Header />
            <ALevelHeader />
            <ALevelInfo />
            <ALevelServiceIntro/>
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}
import React from 'react'
import { Box } from '@mui/material';
import { RobotoTypo, TimesNewTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Circle } from '../../../assets/ServicesImages/StudentPassApplicationImages/circle.svg';
import Arrow from '../../../assets/Home/ApplicationProcess/ApplicationProcessArrow.svg';
import Arrowline from '../StudentPassApplication/Arrowline';
import Arrowline2 from '../StudentPassApplication/Arrowline2';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    // Media query for screens narrower than 1024px
    '@media (max-width: 1200px)': {
        firstRow: {
            flexDirection: 'column',
            gap: '40px'
        },
        arrowImg: {
            marginBottom: '25px',
            transform: 'rotate(90deg)',
        }
    },
}));
export default function StudentApplicationProcess() {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Box sx={{ position: 'relative', background: '#F7F7F7' }}>
            {/* <Arrowline /> */}
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: "column", md: 'column', }, width: '100%' }}>
                <Box
                    sx={{
                        borderBottomRightRadius: { xs: '0', md: '0', lg: '50px', xl: '50px', xxl: '50px' },
                        // padding: { xs: '0', sm: "0", md: '0', lg: "40px", xl: '40px' },
                        padding: '40px 0',
                        zIndex: 10000,
                        height: { xs: '11%', sm: "11%", md: '11%', lg: "15%", xl: '15%' },
                        width: { xs: '100%', sm: "100%", md: '100%', lg: "30%", xl: '30%' },
                        position: { xs: 'relative', sm: "relative", md: 'relative', lg: "absolute", xl: 'absolute' },
                        left: '0',
                        top: '0',
                        background: '#F5DFBA',
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <RobotoTypo sx={{ fontWeight: '700', color: '#003B5C', fontSize: { xs: '22px', sm: '25px', md: '28px', lg: '30px' }, textAlign: 'center' }}>
                            {t('service.CompanyRegistration.RegistrationProcess', { returnObjects: true }).name1}
                        </RobotoTypo>
                        <TimesNewTypo sx={{ fontWeight: '700', color: '#177D84', fontSize: { xs: '16px', md: '20px', lg: '20px' }, textAlign: 'center' }}>
                            {t('service.CompanyRegistration.RegistrationProcess', { returnObjects: true }).name2}
                        </TimesNewTypo>
                    </Box>

                </Box>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', position: 'relative' }}>
                    <Arrowline />
                    <Box sx={{
                        zIndex: '10000',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: { xs: '12% 6% 4% 6%', md: '11% 6% 4% 6%', lg: '12% 6% 5% 6%', xl: '8% 6% 5% 6%' },
                        gap: '50px',
                        width: '100%',
                    }}>
                        <Box className={classes.firstRow} sx={{ display: 'flex', margin: '3% 6% 0' }}>
                            <Box sx={{ display: 'flex', flex: '1', maxWidth: '500px', minHeight: '150px', alignItems: 'center', justifyContent: 'center', border: '3px solid #177D84', borderRadius: '25px', position: 'relative', backgroundColor: 'white' }}>
                                <Box sx={{ position: 'absolute', left: { xs: 'unset', md: 'unset', lg: '-40px' }, top: { xs: '-40px', md: '-40px', lg: 'unset' } }}>
                                    <Box
                                        sx={{ display: 'flex', position: 'relative', alignItems: 'center', background: 'white', justifyContent: 'center', border: '2px solid #177D84', width: '80px', height: '80px', borderRadius: '50px' }}
                                    >
                                        <Circle style={{ position: 'absolute', left: -4 }} />
                                        <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '20px', sm: '24px', md: '32px' } }}>01</RobotoTypo>
                                    </Box>
                                </Box>

                                <Box sx={{ padding: { xs: '48px 60px', lg: '20px 60px' } }}>
                                    <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '18px', sm: '18px', md: '20px' } }}>
                                        {t('service.CompanyRegistration.RegistrationProcess', { returnObjects: true }).title1}
                                    </RobotoTypo>
                                    <RobotoTypo sx={{ color: '#000000', fontWeight: '400', letterSpacing: '0.02em', lineHeight: '27px', fontSize: '14px' }}>
                                        {t('service.CompanyRegistration.RegistrationProcess', { returnObjects: true }).description1}
                                    </RobotoTypo>
                                </Box>

                            </Box>
                            <Box sx={{ alignSelf: 'center', marginRight: { md: '0', lg: '25px' }, marginLeft: '-12px' }}>
                                <img className={classes.arrowImg} src={Arrow} style={{ marginTop: '3px', width: '100px', marginBottom: { xs: '25px', md: '25px', lg: '0' }, }} alt="arrow" />
                            </Box>
                            <Box sx={{ maxWidth: '500px', minHeight: '150px', display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'center', border: '3px solid #177D84', background: 'white', borderRadius: '25px', position: 'relative' }}>
                                <Box sx={{ position: 'absolute', left: { xs: 'unset', md: 'unset', lg: '-40px' }, top: { xs: '-40px', md: '-40px', lg: 'unset' } }}>
                                    <Box
                                        sx={{ display: 'flex', position: 'relative', alignItems: 'center', background: 'white', justifyContent: 'center', border: '2px solid #177D84', width: '80px', height: '80px', borderRadius: '50px' }}
                                    >
                                        <Circle style={{ position: 'absolute', left: -4 }} />
                                        <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '20px', sm: '24px', md: '32px' } }}>02</RobotoTypo>
                                    </Box>
                                </Box>
                                <Box sx={{ padding: { xs: '80px 60px', lg: '20px 20px 20px 60px' } }}>
                                    <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '18px', sm: '18px', md: '20px' } }}>
                                        {t('service.CompanyRegistration.RegistrationProcess', { returnObjects: true }).title2}
                                    </RobotoTypo>
                                    <RobotoTypo sx={{ color: '#000000', fontWeight: '400', letterSpacing: '0.02em', lineHeight: '27px', fontSize: '14px' }}>
                                        {t('service.CompanyRegistration.RegistrationProcess', { returnObjects: true }).description2}
                                    </RobotoTypo>
                                </Box>

                            </Box>
                            <Box sx={{ display: { xs: 'block', lg: 'none' }, alignSelf: 'center', marginRight: { md: '0', lg: '25px' }, marginLeft: '-12px' }}>
                                <img className={classes.arrowImg} src={Arrow} style={{ marginTop: '3px', width: '100px', marginBottom: { xs: '25px', md: '25px', lg: '0' }, }} alt="arrow" />
                            </Box>
                        </Box>

                    </Box>
                </Box>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', position: 'relative' }}>
                    <Arrowline2 />
                    <Box sx={{
                        zIndex: '10000',
                        display: 'flex',
                        justifyContent: 'center',
                        // padding: { xs: '0% 2% 5% 7%', md: '0% 2% 5% 7%', lg: '0% 6% 5% 6%' },
                        padding: { xs: '0% 6% 4% 6%', md: '0% 6% 4% 6%', lg: '0% 6% 5% 6%' },
                        gap: { md: '0px', lg: '50px' },
                        width: '100%',
                    }}>
                        <Box className={classes.firstRow} sx={{
                            display: 'flex',
                            margin: '0 6%',
                            gap: { md: '40px', lg: '100px' },

                        }}>
                            <Box sx={{ minWidth: '240px', maxWidth: '500px', minHeight: '150px', display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'center', border: '3px solid #177D84', borderRadius: '25px', position: 'relative', backgroundColor: 'white' }}>
                                <Box sx={{ position: 'absolute', left: { xs: 'unset', md: 'unset', lg: '-40px' }, top: { xs: '-40px', md: '-40px', lg: 'unset' } }}>
                                    <Box
                                        sx={{ display: 'flex', position: 'relative', alignItems: 'center', background: 'white', justifyContent: 'center', border: '2px solid #177D84', width: '80px', height: '80px', borderRadius: '50px' }}
                                    >
                                        <Circle style={{ position: 'absolute', left: -4 }} />
                                        <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '20px', sm: '24px', md: '32px' } }}>03</RobotoTypo>
                                    </Box>
                                </Box>

                                <Box sx={{ padding: { xs: '80px 20px 48px 60px', lg: '20px 20px 20px 60px' } }}>
                                    <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '18px', sm: '18px', md: '20px' } }}>
                                        {t('service.CompanyRegistration.RegistrationProcess', { returnObjects: true }).title3}
                                    </RobotoTypo>
                                    <RobotoTypo sx={{ color: '#000000', fontWeight: '400', letterSpacing: '0.02em', lineHeight: '27px', fontSize: '14px' }}>
                                        {t('service.CompanyRegistration.RegistrationProcess', { returnObjects: true }).description3}
                                    </RobotoTypo>
                                </Box>

                            </Box>
                            <Box sx={{ display: { xs: 'block', lg: 'none' }, alignSelf: 'center', marginRight: { md: '0', lg: '25px' }, marginLeft: '-12px' }}>
                                <img className={classes.arrowImg} src={Arrow} style={{ marginTop: '3px', width: '100px', marginBottom: { xs: '25px', md: '25px', lg: '0' }, }} alt="arrow" />
                            </Box>
                            <Box sx={{ minWidth: '240px', maxWidth: '500px', minHeight: '150px', display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'center', border: '3px solid #177D84', background: 'white', borderRadius: '25px', position: 'relative' }}>
                                <Box sx={{ position: 'absolute', left: { xs: 'unset', md: 'unset', lg: '-40px' }, top: { xs: '-40px', md: '-40px', lg: 'unset' } }}>
                                    <Box
                                        sx={{ display: 'flex', position: 'relative', alignItems: 'center', background: 'white', justifyContent: 'center', border: '2px solid #177D84', width: '80px', height: '80px', borderRadius: '50px' }}
                                    >
                                        <Circle style={{ position: 'absolute', left: -4 }} />
                                        <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '20px', sm: '24px', md: '32px' } }}>04</RobotoTypo>
                                    </Box>
                                </Box>
                                <Box sx={{ padding: { xs: '48px 20px 48px 60px', lg: '20px 20px 20px 60px' } }}>
                                    <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '18px', sm: '18px', md: '20px' } }}>
                                        {t('service.CompanyRegistration.RegistrationProcess', { returnObjects: true }).title4}
                                    </RobotoTypo>
                                    <RobotoTypo sx={{ color: '#000000', fontWeight: '400', letterSpacing: '0.02em', lineHeight: '27px', fontSize: '14px' }}>
                                        {t('service.CompanyRegistration.RegistrationProcess', { returnObjects: true }).description4}
                                    </RobotoTypo>
                                </Box>

                            </Box>
                            <Box sx={{ display: { xs: 'block', lg: 'none' }, alignSelf: 'center', marginRight: { md: '0', lg: '25px' }, marginLeft: '-12px' }}>
                                <img className={classes.arrowImg} src={Arrow} style={{ marginTop: '3px', width: '100px', marginBottom: { xs: '25px', md: '25px', lg: '0' }, }} alt="arrow" />
                            </Box>
                            <Box sx={{ minWidth: '240px', maxWidth: '500px', minHeight: '150px', display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'center', border: '3px solid #177D84', background: 'white', borderRadius: '25px', position: 'relative' }}>
                                <Box sx={{ position: 'absolute', left: { xs: 'unset', md: 'unset', lg: '-40px' }, top: { xs: '-40px', md: '-40px', lg: 'unset' } }}>
                                    <Box
                                        sx={{ display: 'flex', position: 'relative', alignItems: 'center', background: 'white', justifyContent: 'center', border: '2px solid #177D84', width: '80px', height: '80px', borderRadius: '50px' }}
                                    >
                                        <Circle style={{ position: 'absolute', left: -4 }} />
                                        <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '20px', sm: '24px', md: '32px' } }}>05</RobotoTypo>
                                    </Box>
                                </Box>
                                <Box sx={{ padding: { xs: '48px 20px 48px 60px', lg: '20px 20px 20px 60px' } }}>
                                    <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '18px', sm: '18px', md: '20px' } }}>
                                        {t('service.CompanyRegistration.RegistrationProcess', { returnObjects: true }).title5}
                                    </RobotoTypo>
                                    <RobotoTypo sx={{ color: '#000000', fontWeight: '400', letterSpacing: '0.02em', lineHeight: '27px', fontSize: '14px' }}>
                                        {t('service.CompanyRegistration.RegistrationProcess', { returnObjects: true }).description5}
                                    </RobotoTypo>
                                </Box>

                            </Box>
                        </Box><br />

                    </Box>
                </Box>


            </Box><br />
           
            <br />
            <Box sx={{ display: 'flex', gap: '60px', flexDirection: 'column', justifyContent: 'center', margin: '0 3%' }}>
                <Box sx={{ alignSelf: 'center', backgroundColor: '#003B5C', padding: '8px 24px', borderRadius: '50px', width: 'fit-content' }}>
                    <RobotoTypo sx={{ fontWeight: '700', fontSize: { xs: '18px', sm: '21px', md: '24px' }, minWidth: '150px', color: '#FFFFFF', letterSpacing: '0.02em', textAlign: 'center' }}>
                        {t('service.CompanyRegistration.instruction', { returnObjects: true }).title}
                    </RobotoTypo>
                </Box>
                <Box sx={{ width: { xs: '70%', lg: '50%' }, backgroundColor: "#FFFFFF", borderRadius: '50px', display: 'flex', flexDirection: 'column', margin: '0 auto', gap: '10px', padding: '20px' }}>
                    {t('service.CompanyRegistration.instruction.bullets', { returnObjects: true }).map((item, index) => (
                        <Box key={index} sx={{ margin: '10px', display: 'flex', alignItems: 'center', gap: '20px' }}>
                            <Box

                            ></Box>
                            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '28px', color: '#242424', fontSize: { xs: '16px', md: '16px', lg: '16px' }, textAlign: 'left' }}>
                            {`${index+1}.) ${item}`}
                            </RobotoTypo>

                        </Box>
                    ))}
                </Box>
            </Box> <br /><br />
            <Box sx={{ display: 'flex', gap: '60px', flexDirection: 'column', justifyContent: 'center', margin: '0 3%' }}>
                <Box sx={{ alignSelf: 'center', backgroundColor: '#003B5C', padding: '8px 24px', borderRadius: '50px', width: 'fit-content' }}>
                    <RobotoTypo sx={{ fontWeight: '700', fontSize: { xs: '18px', sm: '21px', md: '24px' }, minWidth: '150px', color: '#FFFFFF', letterSpacing: '0.02em', textAlign: 'center' }}>
                        {t('service.CompanyRegistration.preparation', { returnObjects: true }).title}
                    </RobotoTypo>
                </Box>
                <Box sx={{ width: { xs: '70%', lg: '50%' }, backgroundColor: "#FFFFFF", borderRadius: '50px', display: 'flex', flexDirection: 'column', margin: '0 auto', gap: '10px', padding: '20px' }}>
                    {t('service.CompanyRegistration.preparation.bullets', { returnObjects: true }).map((item, index) => (
                        <Box key={index} sx={{ margin: '10px', display: 'flex', alignItems: 'center', gap: '20px' }}>
                            <Box

                            ><Circle /></Box>
                            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '28px', color: '#242424', fontSize: { xs: '16px', md: '16px', lg: '16px' }, textAlign: 'left' }}>
                                {item}
                            </RobotoTypo>

                        </Box>
                    ))}
                </Box>
            </Box><br />



        </Box>
    )
}

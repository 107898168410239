import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import StudentPassApplicationHeader from '../components/Service/StudentPassApplication/StudentPassApplicationHeader'
import StudentPassInfo from '../components/Service/StudentPassApplication/StudentPassInfo'
import StudentApplicationProcess from '../components/Service/StudentPassApplication/StudentApplicationProcess'
import StudentPassServiceIntro from '../components/Service/StudentPassApplication/StudentPassServiceIntro'
import Share from '../components/Share';
import SideOverlap from './sideOverlap';

export default function StudentPassApplication() {
    return (
        <>
            <Header/>
            <StudentPassApplicationHeader />
            <StudentPassInfo />
            <StudentApplicationProcess />
            <StudentPassServiceIntro />
            <Share />
            <Footer />
            <SideOverlap/>
        </>
    )
}

import React from "react";
import Header from "../../components/Header";
import Share from "../../components/Share";
import SideOverlap from "../sideOverlap";
import Footer from "../../components/Footer";
import SearchBody from "./SearchBody";

const SearchComponent = () => {
  return (
    <div>
      <Header />
      <SearchBody />
      <Share />
      <SideOverlap />
      <Footer />
    </div>
  );
};

export default SearchComponent;

import Ceo from '../../assets/AboutUsImages/Teams/Ceo.png';
import Coo from '../../assets/AboutUsImages/Teams/Coo.png';
import Cfo from '../../assets/AboutUsImages/Teams/Cfo.png';
import MarketingManager1 from '../../assets/AboutUsImages/Teams/MarketingManager1.png';
import MarketingManager2 from '../../assets/AboutUsImages/Teams/MarketingManager2.png';
import SeniorConsultant1 from '../../assets/AboutUsImages/Teams/SeniorConsultant1.png';
import Consultant from '../../assets/AboutUsImages/Teams/Consultant.png';
import Admission from '../../assets/AboutUsImages/Teams/Admission.png';
import CountryManagerTaiwan from '../../assets/AboutUsImages/Teams/CountryManagerTaiwan.png';
import SeniorConsultantTaiwan from '../../assets/AboutUsImages/Teams/SeniorConsultantTaiwan.png';
import ConsultantTaiwan from '../../assets/AboutUsImages/Teams/ConsultantTaiwan.png';

export const teamCeo =  {role:'CEO'}


export const teamCoo = {role:'COO'}


export const singaporeTeam = [
    {
        id:1,
        image: Ceo,
        name: 'Raymond Ng',
        role:'CEO'
    },
    {
        id:2,
        image: Coo,
        name: 'Fennie Lee',
        role:'COO'
    },
    {
        id:3,
        image: Cfo,
        name: 'Danielle Wen',
        role:'CFO'
    },
    {
        id:4,
        image: MarketingManager1,
        name: 'Huang Rulong',
        role:'MARKETING MANAGER'
    },
    {
        id:5,
        image: MarketingManager2,
        name: 'Leong Xiao Xi',
        role:'MARKETING MANAGER'
    },
    {
        id:6,
        image: Cfo,
        name: 'Shady Liu',
        role:'COUNTRY MANAGER (CHINA)'
    },
    {
        id:7,
        image: Cfo,
        name: 'Serena Li',
        role:'COUNTRY MANAGER (CHINA)'
    },
    {
        id:8,
        image: SeniorConsultant1,
        name: 'Kacey Chen',
        role:'SENIOR CONSULTANT'
    },
    {
        id:9,
        image: Cfo,
        name: 'Thea Li',
        role:'SENIOR CONSULTANT'
    },
    {
        id:10,
        image: Consultant,
        name: 'Yao Jingyi',
        role:'CONSULTANT'
    },
    {
        id:11,
        image: Cfo,
        name: 'Hu Yucheng',
        role:'STUDENT SERVICE'
    },
    {
        id:12,
        image: Cfo,
        name: 'Eillen Ai',
        role:'ADMISSION'
    },
    {
        id:13,
        image: Admission,
        name: 'Lucia Liu',
        role:'ADMISSION'
    },
    {
        id:14,
        image: Cfo,
        name: 'Jeya',
        role:'IT Development Manager'
    },
    
]

export const taiwanTeam = [
    {
        id:1,
        image: CountryManagerTaiwan,
        name: 'Yang Xiaomin',
        role:'COUNTRY MANAGER  (TAIWAN)'
    },
    {
        id:2,
        image: Coo,
        name: 'Rita Chen ',
        role:'MARKETING MANAGER'
    },
    {
        id:3,
        image: SeniorConsultantTaiwan,
        name: 'Travis Chang',
        role:'SENIOR CONSULTANT'
    },
    {
        id:4,
        image: ConsultantTaiwan,
        name: 'Huang Rulong',
        role:'CONSULTANT'
    },
]

export const hongkongTeam = [
    {
        id:1,
        image: Cfo,
        name: 'William Wen',
        role:'ADMINISTRATIVE CONSULTANT'
    },
    {
        id:2,
        image: Cfo,
        name: 'Sumy Chen',
        role:'COUNTRY MANAGER'
    },
    {
        id:3,
        image: Cfo,
        name: 'Steve Cheng',
        role:'MARKETING MANAGER'
    },
]

export const ChinaTeam = [
    {
        id:1,
        image: Cfo,
        name: 'Ding Yayun',
        role:'SENIOR CONSULTANT'
    },
    {
        id:2,
        image: Cfo,
        name: 'Chen Xiaohua',
        role:'SENIOR CONSULTANT'
    },
]




// import Ceo from '../../assets/AboutUsImages/Teams/Ceo.png';
// import Coo from '../../assets/AboutUsImages/Teams/Coo.png';
// import Cfo from '../../assets/AboutUsImages/Teams/Cfo.png';
// import MarketingManager1 from '../../assets/AboutUsImages/Teams/MarketingManager1.png';
// import MarketingManager2 from '../../assets/AboutUsImages/Teams/MarketingManager2.png';
// import SeniorConsultant1 from '../../assets/AboutUsImages/Teams/SeniorConsultant1.png';
// import Consultant from '../../assets/AboutUsImages/Teams/Consultant.png';
// import Admission from '../../assets/AboutUsImages/Teams/Admission.png';

// export const singaporeTeamFirstRow = [
//     {
//         id:1,
//         image: Ceo,
//         name: 'Raymond Ng',
//         role:'CEO'
//     },
//     {
//         id:2,
//         image: Coo,
//         name: 'Fennie Lee',
//         role:'COO'
//     },
//     {
//         id:3,
//         image: Cfo,
//         name: 'Danielle Wen',
//         role:'CFO'
//     },
//     {
//         id:4,
//         image: MarketingManager1,
//         name: 'Huang Rulong',
//         role:'MARKETING MANAGER'
//     },
//     {
//         id:5,
//         image: MarketingManager2,
//         name: 'Leong Xiao Xi',
//         role:'MARKETING MANAGER'
//     },
    
// ]

// export const singaporeTeamSecondRow = [
//     {
//         id:1,
//         image: Cfo,
//         name: 'Shady Liu',
//         role:'COUNTRY MANAGER (CHINA)'
//     },
//     {
//         id:2,
//         image: Cfo,
//         name: 'Serena Li',
//         role:'COUNTRY MANAGER (CHINA)'
//     },
//     {
//         id:3,
//         image: SeniorConsultant1,
//         name: 'Kacey Chen',
//         role:'SENIOR CONSULTANT'
//     },
//     {
//         id:4,
//         image: Cfo,
//         name: 'Thea Li',
//         role:'SENIOR CONSULTANT'
//     },
//     {
//         id:5,
//         image: Consultant,
//         name: 'Yao Jingyi',
//         role:'CONSULTANT'
//     },
// ]

// export const singaporeTeamThirdRow = [
//     {
//         id:1,
//         image: Cfo,
//         name: 'Hu Yucheng',
//         role:'STUDENT SERVICE'
//     },
//     {
//         id:2,
//         image: Cfo,
//         name: 'Eillen Ai',
//         role:'ADMISSION'
//     },
//     {
//         id:3,
//         image: Admission,
//         name: 'Lucia Liu',
//         role:'ADMISSION'
//     },
//     {
//         id:4,
//         image: Cfo,
//         name: 'Jeya',
//         role:'IT Development Manager'
//     },
// ]
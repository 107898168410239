import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import TutionHeader from '../components/StudyingAboard/Tution/TutionHeader';
import TutionInfo from '../components/StudyingAboard/Tution/TutionInfo';
import TutionServiceIntro from '../components/StudyingAboard/Tution/TutionServiceIntro';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';

export default function Tution() {
    return (
        <>
            <Header />
            <TutionHeader />
            <TutionInfo />
            <TutionServiceIntro/>
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}
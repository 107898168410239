import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import LandingServiceHeader from '../components/Service/LandingService/LandingServiceHeader';
import LandingServiceInfo from '../components/Service/LandingService/LandingServiceInfo';
import LandingServiceCards from '../components/Service/LandingService/LandingServiceCards';
import Share from '../components/Share';
import SideOverlap from './sideOverlap'

export default function LandingService() {
    return (
        <>
            <Header />
            <LandingServiceHeader />
            <LandingServiceInfo />
            <LandingServiceCards />
            <Share />
            <Footer />
            <SideOverlap/>
        </>
    )
}


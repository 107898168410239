import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import Admin from "../Dashboard";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DeleteSchoolModal from "../School/DeleteSchoolModal";
const AdminExtraCurricularActivities = () => {
  const [activitiesList, setActivitiesList] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [deleteSchool, setDeleteSchool] = useState("");
  const [deleteSchoolId, setDeleteSchoolId] = useState("");
  const getActivity = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/activities-all`
      );
      console.log("response get ", response);
      if (response.status == 200) {
        if (Array.isArray(response?.data)) {
          setActivitiesList(response?.data);
        } else {
          setActivitiesList([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/activities/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 200) {
        getActivity();
      }
    } catch (error) {
      if (error.response.status == 403) {
        localStorage.clear();
        navigate("/admin/login");
      }
    }
  };
  useEffect(() => {
    getActivity();
  }, []);
  const handleEdit = (id) => {
    navigate(`/admin/AddExtraCurricularActivities/${id}`);
  };
  const handleDeleteSchool = (school, schoolId) => {
    setDeleteSchool(school);
    setDeleteSchoolId(schoolId);
    setOpen(true);
  };
  return (
    <Admin>
      <DeleteSchoolModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        school={deleteSchool}
        handleDelete={handleDelete}
        deleteSchoolId={deleteSchoolId}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          mb: 1,
        }}
      >
        <Button
          component="span"
          onClick={() => navigate(`/admin/AddExtraCurricularActivities/add`)}
          variant="contained"
          sx={{
            alignSelf: "center",
            mt: 1,
            width: "fit-content",
            backgroundColor: "#177D84",
            "&:hover": { backgroundColor: "#177D84" },
          }}
        >
          Add
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SNo</TableCell>
              <TableCell>Event Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activitiesList?.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row.en.title}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDeleteSchool(row.en.title, row._id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton aria-label="edit">
                    <EditIcon onClick={() => handleEdit(row._id)} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Admin>
  );
};

export default AdminExtraCurricularActivities;

import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RobotoTypo, TimesNewTypo } from "../../utils/Typographies";
import ApplicationProcess_1 from "../../assets/Home/ApplicationProcess/ApplicationProcess_1.svg";
import ApplicationProcess_2 from "../../assets/Home/ApplicationProcess/ApplicationProcess_2.svg";
import ApplicationProcess_3 from "../../assets/Home/ApplicationProcess/ApplicationProcess_3.svg";
import ApplicationProcess_4 from "../../assets/Home/ApplicationProcess/ApplicationProcess_4.svg";
import ApplicationProcess_5 from "../../assets/Home/ApplicationProcess/ApplicationProcess_5.svg";
import ApplicationProcess_6 from "../../assets/Home/ApplicationProcess/ApplicationProcess_6.svg";
import YellowDot from "../../assets/Home/ApplicationProcess/YellowDot.svg";
import BorderBg from "../../assets/Home/ApplicationProcess/BorderBg.svg";
import Arrow from "../../assets/Home/ApplicationProcess/ApplicationProcessArrow.svg";
import { useLocation, useNavigate } from "react-router-dom";
import ToastAlert from "../../utils/Alert";

const ApplicationProcessIcons = {
  1: ApplicationProcess_1,
  2: ApplicationProcess_2,
  3: ApplicationProcess_3,
  4: ApplicationProcess_4,
  5: ApplicationProcess_5,
  6: ApplicationProcess_6,
  7: ApplicationProcess_6,
};

const ApplicationProcess = () => {
  const [toastStatus, setToastStatus] = useState({
    color: "",
    status: false,
    message: "",
    icon: "",
  });
  const navigate = useNavigate();
  const query = useQuery();
  const status = query.get("status");
  const userData = localStorage.getItem("student_data")
    ? JSON.parse(localStorage.getItem("student_data"))
    : "";
  console.log("status ", status);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useEffect(() => {
    if (status) {
      if (status == "completed") {
        setToastStatus({
          color: "green",
          status: true,
          message: `Payment Successful, payment reciept sent to your email ${userData?.email}`,
          icon: "tick",
        });
        setTimeout(() => {
          setToastStatus({ color: "", status: false });
          navigate("/");
        }, 3000);
      } else if (status == "pending") {
        setToastStatus({
          color: "orange",
          status: true,
          message: "Payment Pending",
          icon: "clock",
        });
        setTimeout(() => {
          setToastStatus({ color: "", status: false });
          navigate("/");
        }, 3000);
      } else {
        setToastStatus({
          color: "red",
          status: true,
          message: "Payment Failed",
          icon: "close",
        });
        setTimeout(() => {
          setToastStatus({ color: "", status: false });
          navigate("/");
        }, 3000);
      }
    }
  }, [status]);

  const { t } = useTranslation();
  return (
    <>
      <ToastAlert
        message={toastStatus?.message}
        status={toastStatus?.status}
        color={toastStatus?.color}
        icon={toastStatus?.icon}
        closeFunc={() => setToastStatus({ color: "", status: false })}
      />
      <Box sx={{ background: "#F7F7F7" }}>
        <Box
          sx={{
            width: {
              xs: "auto",
              md: "35%",
            },
            background: "#F5DFBA",
            display: "grid",
            placeItems: "center",
            borderBottomRightRadius: { xs: 0, md: "50px" },
            p: { xs: 4, md: 6 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              gap: { xs: 1, md: 0 },
            }}
          >
            <RobotoTypo
              sx={{
                fontSize: { xs: 18, md: 36 },
                fontWeight: 600,
                color: "#003B5C",
                textAlign: "center",
              }}
            >
              {t("Home.ApplicationProcess.title")}
            </RobotoTypo>
          </Box>
        </Box>
        <Box
          sx={{ px: { xs: 2, lg: 6 }, py: { xs: 4, md: 8 }, pb: { md: 18 } }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              width: "100%",
              justifyContent: { xs: "space-evenly", md: "center" },
              height: "auto",
            }}
          >
            {t("Home.ApplicationProcess.data", { returnObjects: true }).map(
              (item) => (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      alignItems: { xs: "center", md: "flex-start" },
                      gap: { xs: 1, md: 0 },
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateRows: {
                          xs: "auto auto",
                          md: "auto 100px ",
                        },
                      }}
                    >
                      <Box
                        key={item.id}
                        sx={{
                          position: "relative",
                          display: "grid",
                          placeItems: "center",
                          height: { xs: 100, md: 75, lg: 80 },
                          width: { xs: 100, md: 75, lg: 80 },
                          flexGrow: 1,
                        }}
                      >
                        {/* Absolute Grid */}
                        <Box
                          sx={{
                            position: "absolute",
                            width: "inherit",
                            height: "inherit",
                            display: "grid",
                            placeItems: "center",
                            // marginLeft: {
                            //   xs: 0,
                            //   md: item.id === 7 ? "30px" : "0px",
                            //   lg: item.id === 7 ? "40px" : "0px",
                            //   xl: item.id === 7 ? "60px" : "0px",
                            // },
                            transform: {
                              xs: "rotate(90deg)",
                              md: "rotate(0deg)",
                            },
                          }}
                        >
                          <img
                            src={BorderBg}
                            alt="border"
                            style={{
                              position: "absolute",
                              objectFit: "contain",
                              width: "inherit",
                            }}
                          />

                          <img
                            src={YellowDot}
                            alt="yellowDot"
                            style={{
                              objectFit: "contain",
                              padding: "4px 0",
                              position: "absolute",
                              left: "-6px",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            background: "white",
                            height: "inherit",
                            width: "inherit",
                            display: "grid",
                            placeItems: "center",
                            borderRadius: "50%",
                            // marginLeft: {
                            //   xs: 0,
                            //   md: item.id === 7 ? "15px" : "0px",
                            //   xl: item.id === 7 ? "30px" : "0px",
                            // },
                          }}
                        >
                          <img
                            src={ApplicationProcessIcons[item.id]}
                            alt={item.title}
                            style={{
                              objectFit: "contain",
                              width: "60%",
                              height: "60%",
                            }}
                          />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          flexGrow: 0,
                          height: "auto",
                          maxWidth: {
                            xs: 100,
                            md: 75,
                            lg: 90,
                            xl: 90,
                          },
                        }}
                      >
                        <RobotoTypo
                          sx={{
                            textAlign: "center",
                            color: "#003B5C",
                            fontSize: 20,
                            fontWeight: 700,
                            textDecoration: "underline",
                            textDecorationColor: "#FFC424",
                            textDecorationThickness: "3px",
                          }}
                        >
                          {item.step}
                        </RobotoTypo>

                        <RobotoTypo
                          sx={{
                            textAlign: "center",
                            fontSize: 13,
                            color: "#003B5C",
                            mt: "12px",
                            fontWeight: 600,
                          }}
                        >
                          {item.title}
                        </RobotoTypo>
                      </Box>
                    </Box>

                    {item.id !== 0 && item.id !== 7 && (
                      <Box
                        sx={{
                          height: 85,
                          // height: { xs: 100, md: 75, lg: 80 },
                          display: "grid",
                          placeItems: "center",
                          transform: {
                            xs: "rotate(90deg)",
                            md: "rotate(0deg)",
                          },
                        }}
                      >
                        <img
                          src={Arrow}
                          alt="arrow"
                          style={{
                            width: "60%",
                            height: "auto",
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              )
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ApplicationProcess;

import React,{useState, useEffect, useRef} from 'react'
import { Box  } from '@mui/material';
import { RobotoTypo, TimesNewTypo } from '../../../utils/Typographies';
import BingMapsReact from 'bingmaps-react';
import { BingMap, BingPushpin } from 'bingmaps-react';
import Location from '../../../assets/png/locations.png';
import axios from 'axios';

export default function SchoolMap({data}) {
    const bingMapsKey = 'AtQyJOYuWEKKkmjbpW1yCcKYwVmI2Z9LIJMhQnSb2OKB3LntnI558Dmu8GQaQGGn';

      const initialLocation = {
        latitude:  data.lat ? parseFloat(data.lat) : 1.3364331, // Example latitude
        longitude: data.lon ? parseFloat(data.lon) : 103.8664566, // Example longitude
        locationName: '<div style="display:flex; align-items:flex-start; flex-direction:column; gap:5px"><h3 style="text-align:left;fontFamily: Roboto, sans-serif;font-weight:550;">Stamford American Internatio...</h3><p>1 woodleigh Ln, 3578</p><div>', // Example location name
      };

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = `https://www.bing.com/api/maps/mapcontrol?key=YOUR_BING_MAPS_API_KEY&callback=initializeMap`;
    //     script.async = true;
    //     document.body.appendChild(script);
      
    //     window.initializeMap = () => {
    //       const mapOptions = {
    //         credentials: 'AtQyJOYuWEKKkmjbpW1yCcKYwVmI2Z9LIJMhQnSb2OKB3LntnI558Dmu8GQaQGGn',
    //         center: new window.Microsoft.Maps.Location(initialLocation.latitude, initialLocation.longitude),
    //         zoom: 12,
    //       };
      
    //       const map = new window.Microsoft.Maps.Map(document.getElementById('map-container'), mapOptions);
      
    //       // Add pushpin to the initial location
    //       const pushpin = new window.Microsoft.Maps.Pushpin(new window.Microsoft.Maps.Location(initialLocation.latitude, initialLocation.longitude));
    //       map.entities.push(pushpin);
      
    //       // Show location name on the top left corner
    //       const locationNameDiv = document.createElement('div');
    //       locationNameDiv.innerHTML = initialLocation.locationName;
    //       locationNameDiv.style.position = 'absolute';
    //       locationNameDiv.style.top = '10px';
    //       locationNameDiv.style.left = '10px';
    //       locationNameDiv.style.background = 'white';
    //       locationNameDiv.style.padding = '5px';
    //       locationNameDiv.style.borderRadius = '5px';
    //       locationNameDiv.style.zIndex = '1000'; // Ensure it's above the map
    //       document.getElementById('map-container').appendChild(locationNameDiv);
    //     };
      
    //     return () => {
    //       document.body.removeChild(script);
    //     };
    //   }, []);

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = `https://www.bing.com/api/maps/mapcontrol?key=YOUR_BING_MAPS_API_KEY&callback=initializeMap`;
    //     script.async = true;
    //     document.body.appendChild(script);
      
    //     window.initializeMap = () => {
    //       const mapOptions = {
    //         credentials: 'AtQyJOYuWEKKkmjbpW1yCcKYwVmI2Z9LIJMhQnSb2OKB3LntnI558Dmu8GQaQGGn',
    //         center: new window.Microsoft.Maps.Location(initialLocation.latitude, initialLocation.longitude),
    //         zoom: 12,
    //       };
      
    //       const map = new window.Microsoft.Maps.Map(document.getElementById('map-container'), mapOptions);
      
    //       // Add pushpin to the initial location
    //       const pushpinOptions = {
    //         color: 'red', // Set marker color to red
    //       };
    //       const pushpin = new window.Microsoft.Maps.Pushpin(map.getCenter(), pushpinOptions);
    //       map.entities.push(pushpin);
      
    //       // Show location name on the top left corner
    //       const locationNameDiv = document.createElement('div');
    //       locationNameDiv.innerHTML = initialLocation.locationName;
    //       locationNameDiv.style.position = 'absolute';
    //       locationNameDiv.style.top = '10px';
    //       locationNameDiv.style.left = '10px';
    //       locationNameDiv.style.background = 'white';
    //       locationNameDiv.style.padding = '5px';
    //       locationNameDiv.style.borderRadius = '5px';
    //       locationNameDiv.style.zIndex = '1000'; // Ensure it's above the map
    //       document.getElementById('map-container').appendChild(locationNameDiv);
    //     };
      
    //     return () => {
    //       document.body.removeChild(script);
    //     };
    //   }, []);

    // const getCoordinatesFromAddress = async (address) => {
    //     try {
    //       const response = await axios.get(`https://dev.virtualearth.net/REST/v1/Locations?query=${encodeURIComponent(address)}&key=AtQyJOYuWEKKkmjbpW1yCcKYwVmI2Z9LIJMhQnSb2OKB3LntnI558Dmu8GQaQGGn`);
    //       const data = response.data;
      
    //       if (data && data.resourceSets && data.resourceSets.length > 0 && data.resourceSets[0].resources && data.resourceSets[0].resources.length > 0) {
    //         const result = data.resourceSets[0].resources[0];
    //         const coordinates = result.point.coordinates;
    //         return { latitude: coordinates[0], longitude: coordinates[1] };
    //       } else {
    //         throw new Error('No results found for the address');
    //       }
    //     } catch (error) {
    //       console.error('Error retrieving coordinates:', error);
    //       return null;
    //     }
    //   };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.bing.com/api/maps/mapcontrol?key=AtQyJOYuWEKKkmjbpW1yCcKYwVmI2Z9LIJMhQnSb2OKB3LntnI558Dmu8GQaQGGn&callback=initializeMap`;
        script.async = true;
        document.body.appendChild(script);
    
        window.initializeMap = () => {
          const mapOptions = {
            credentials: 'AtQyJOYuWEKKkmjbpW1yCcKYwVmI2Z9LIJMhQnSb2OKB3LntnI558Dmu8GQaQGGn',
            center: new window.Microsoft.Maps.Location(initialLocation.latitude, initialLocation.longitude),
            zoom: 12,
          };
    
          const map = new window.Microsoft.Maps.Map(document.getElementById('map-container'), mapOptions);
         
          const pushpinLocation = new window.Microsoft.Maps.Location(initialLocation.latitude, initialLocation.longitude);
          const pushpinOptions = {
            icon: Location, 
            anchor: new window.Microsoft.Maps.Point(12, 40) 
          };
          const pushpin = new window.Microsoft.Maps.Pushpin(pushpinLocation, pushpinOptions);
          map.entities.push(pushpin);
    
          axios.get(`https://dev.virtualearth.net/REST/v1/Locations/${initialLocation.latitude},${initialLocation.longitude}?key=AtQyJOYuWEKKkmjbpW1yCcKYwVmI2Z9LIJMhQnSb2OKB3LntnI558Dmu8GQaQGGn`)
            .then(response => {
              const address = response.data.resourceSets[0].resources[0].address;
              console.log('ad ',address)
              initialLocation.locationName = `<div style="display:flex; align-items:flex-start; flex-direction:column; gap:5px"><h3 style="text-align:left;font-family: Roboto, sans-serif;font-weight:550;">${address.formattedAddress}</h3><p>${address.addressLine}</p></div>`;
              
              const locationNameDiv = document.createElement('div');
              locationNameDiv.innerHTML = initialLocation.locationName;
              locationNameDiv.style.position = 'absolute';
              locationNameDiv.style.top = '10px';
              locationNameDiv.style.left = '10px';
              locationNameDiv.style.background = 'white';
              locationNameDiv.style.padding = '5px';
              locationNameDiv.style.borderRadius = '5px';
              locationNameDiv.style.zIndex = '1000'; // Ensure it's above the map
              document.getElementById('map-container').appendChild(locationNameDiv);
            })

            
            .catch(error => {
              console.error('Error retrieving address:', error);
            });
        };
    
        return () => {
          document.body.removeChild(script);
        };
      }, []);

  return (
    <Box sx={{width:{xs:'100%', sm:'95%', display:'flex', margin:'10px auto', borderRadius:'10px'}}}>
        <Box id="map-container" sx={{ width: '100%', height: '500px' }}></Box>
    </Box>
  )
}

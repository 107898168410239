import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SchoolTourHeader from '../components/Service/SchoolBasedTour/SchoolTourHeader';
import SchoolTourInfo from '../components/Service/SchoolBasedTour/SchoolTourInfo';
import SchoolTourServiceIntro from '../components/Service/SchoolBasedTour/SchoolTourServiceIntro';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';

export default function SchoolBasedTour() {
    return (
        <>
            <Header />
            <SchoolTourHeader />
            <SchoolTourInfo />
            <SchoolTourServiceIntro/>
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}
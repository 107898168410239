import React from 'react'
import { Box } from '@mui/material';
import { RobotoTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Circle } from '../../../assets/ServicesImages/StudentPassApplicationImages/circle.svg';

export default function AirportPickupServiceInfo() {
    const { t } = useTranslation();
    console.log('mmm ',t('AirportPickup.serviceInfo.listItems', { returnObjects: true }))
    return (
        <Box sx={{ padding: '4%',  backgroundColor:'#F7F7F7' }}>
            <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', gap:'40px',}}>
            <Box sx={{mb:4,  display:'flex', justifyContent:'center', alignItems:'center', backgroundColor: '#003B5C', padding: '8px 12px', borderRadius: '50px', width:'fit-content' }
            } >
                <RobotoTypo sx={{ letterSpacing: '0.02em', fontWeight: {xs:'500', sm:'700'}, color: 'white', fontSize: { xs: '18px', sm: '22px', md: '22px', lg: '22px' }, textAlign: 'left' }}>
                {t('AirportPickup.serviceInfo', { returnObjects: true }).title}
                </RobotoTypo><br />
            </ Box>
            <Box sx={{display:'flex', gap:'20px', backgroundColor:'white', padding:'40px', borderRadius: '30px',}}>
                <Box>
                    {t('AirportPickup.serviceInfo.listItems', { returnObjects: true })[0].map((item, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                            <Box

                            ><Circle /></Box>
                            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '16px', md: '16px', lg: '16px' }, my:1, textAlign: 'left' }}>
                                {item}
                            </RobotoTypo>

                        </Box>
                    ))}
                </Box>

                <Box>
                    {t('AirportPickup.serviceInfo.listItems', { returnObjects: true })[1].map((item, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                            <Box

                            ><Circle /></Box>
                            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '16px', md: '16px', lg: '16px' }, textAlign: 'left',my:1 }}>
                                {item}
                            </RobotoTypo>

                        </Box>
                    ))}
                </Box>

            </Box>
            </Box>

        </Box>
    )
}

import React from 'react'
import { Box } from '@mui/material';
import { RobotoTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';

export default function VisaInfo() {
    const { t } = useTranslation();
    return (
        <Box sx={{ padding: '4% 7%' }}>
            <RobotoTypo sx={{ fontWeight: '700', letterSpacing: '0.05em', color: '#003B5C', fontSize: { xs: '20px', sm: '25px', md: '28px', lg: '30px' }, textAlign: 'left' }}>
                {t('service.travelVisa.info', { returnObjects: true }).title}
            </RobotoTypo><br />
            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: '16px', textAlign: 'left' }}>
                {t('service.travelVisa.info', { returnObjects: true }).description1}
            </RobotoTypo>
        </Box>
    )
}

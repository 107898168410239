import React from "react";
import { Box } from "@mui/material";
import { RobotoTypo, TimesNewTypo } from "../../../utils/Typographies";
import { useTranslation } from "react-i18next";
import { ReactComponent as Circle } from "../../../assets/ServicesImages/StudentPassApplicationImages/circle.svg";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  // Media query for screens narrower than 1024px
  card: {
    width: "25%",
    alignSelf: "stretch",
  },
  "@media (max-width: 700px)": {
    cards: {
      flexDirection: "column",
    },
    card: {
      width: "50%",
      alignSelf: "unset",
    },
  },
  "@media (min-width: 1600px)": {
    card: {
      minWidth: "300px",
      maxWidth: "300px",
    },
  },
}));

export default function DormitoryService() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box sx={{ position: "relative", background: "#B1D0E0" }}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box
          sx={{
            background: "#F5DFBA",
            borderBottomRightRadius: {
              xs: "0",
              md: "0",
              lg: "0",
              xl: "50px",
              xxl: "50px",
            },
            // padding: { xs: '0', sm: "0", md: '0', lg: "40px", xl: '40px' },
            padding: "40px 0",
            zIndex: 99,
            height: { xs: "11%", sm: "11%", md: "11%", lg: "15%", xl: "15%" },
            width: { xs: "100%", sm: "100%", md: "100%", lg: "30%", xl: "30%" },
            position: {
              xs: "relative",
              sm: "relative",
              md: "relative",
              lg: "absolute",
              xl: "absolute",
            },
            left: "0",
            top: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* {t('aboutUs.why_dr', { returnObjects: true }).map((item, index) => ( */}
          <Box
            // key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <RobotoTypo
              sx={{
                fontWeight: "700",
                color: "#003B5C",
                fontSize: { xs: "22px", sm: "25px", md: "28px", lg: "30px" },
                textAlign: "center",
              }}
            >
              {
                t("service.dormitory.dormitoryService", { returnObjects: true })
                  .title1
              }{" "}
            </RobotoTypo>
            <TimesNewTypo
              sx={{
                fontWeight: "700",
                color: "#177D84",
                fontSize: { xs: "18px", md: "22px", lg: "24px" },
                textAlign: "center",
              }}
            >
              {
                t("service.dormitory.dormitoryService", { returnObjects: true })
                  .title2
              }
            </TimesNewTypo>
          </Box>
          {/* ))} */}
        </Box>
        <Box
          className={classes.cards}
          sx={{
            width: "100%",
            flexWrap: "wrap",
            display: "flex",
            gap: "50px",
            alignItems: "center",
            justifyContent: "center",
            padding: { xs: "50px 0", md: "12% 0" },
          }}
        >
          {t("service.dormitory.dormitoryService.cards", {
            returnObjects: true,
          })?.map((item, index) => (
            <Box
              className={classes.card}
              key={index}
              sx={{
                zIndex: "10000",
                display: "flex",
                // justifyContent: 'center',
                alignItems: "start",
                flexDirection: "column",
                gap: "20px",
                padding: { xs: "20px 20px", sm: "30px 30px" },
                backgroundColor: "white",
                borderRadius: "40px",
                width: {
                  xs: "200px",
                  sm: "290px",
                  md: "281px",
                  lg: "300px",
                },
              }}
            >
              <Box
                sx={{ display: "flex", gap: "20px", flexDirection: "column" }}
              >
                <RobotoTypo
                  sx={{
                    fontWeight: "700",
                    letterSpacing: "0.03em",
                    lineHeight: "25px",
                    color: "#003B5C",
                    fontSize: { xs: "18px", md: "22px", lg: "22px" },
                    textAlign: { xs: "center", sm: "left" },
                  }}
                >
                  {item.heading}
                </RobotoTypo>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    margin: "0 1%",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: { xs: "20px", sm: "30px" },
                      width: "100%",
                    }}
                  >
                    <Circle style={{ minWidth: "8px" }} />
                    <RobotoTypo
                      sx={{
                        fontWeight: "400",
                        letterSpacing: "0.03em",
                        lineHeight: "25px",
                        color: "#000000",
                        fontSize: {
                          xs: "15px",
                          md: "16px",
                          lg: "16px",
                          xl: "18px",
                        },
                        textAlign: "left",
                      }}
                    >
                      {item.description1}
                    </RobotoTypo>
                  </Box>
                  {item.description2 && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: { xs: "20px", sm: "30px" },
                      }}
                    >
                      <Circle />
                      <RobotoTypo
                        sx={{
                          fontWeight: "400",
                          letterSpacing: "0.03em",
                          lineHeight: "25px",
                          color: "#000000",
                          fontSize: {
                            xs: "15px",
                            md: "16px",
                            lg: "16px",
                            xl: "18px",
                          },
                          textAlign: "left",
                        }}
                      >
                        {item.description2}
                      </RobotoTypo>
                    </Box>
                  )}
                  {item.description3 && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: { xs: "20px", sm: "30px" },
                      }}
                    >
                      <Circle />
                      <RobotoTypo
                        sx={{
                          fontWeight: "400",
                          letterSpacing: "0.03em",
                          lineHeight: "25px",
                          color: "#000000",
                          fontSize: {
                            xs: "15px",
                            md: "16px",
                            lg: "16px",
                            xl: "18px",
                          },
                          textAlign: "left",
                        }}
                      >
                        {item.description3}
                      </RobotoTypo>
                    </Box>
                  )}
                  {item.description4 && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: { xs: "20px", sm: "30px" },
                      }}
                    >
                      <Circle />
                      <RobotoTypo
                        sx={{
                          fontWeight: "400",
                          letterSpacing: "0.03em",
                          lineHeight: "25px",
                          color: "#000000",
                          fontSize: {
                            xs: "15px",
                            md: "16px",
                            lg: "16px",
                            xl: "18px",
                          },
                          textAlign: "left",
                        }}
                      >
                        {item.description4}
                      </RobotoTypo>
                    </Box>
                  )}
                  {item.description5 && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: { xs: "20px", sm: "30px" },
                      }}
                    >
                      <Circle />
                      <RobotoTypo
                        sx={{
                          fontWeight: "400",
                          letterSpacing: "0.03em",
                          lineHeight: "25px",
                          color: "#000000",
                          fontSize: {
                            xs: "15px",
                            md: "16px",
                            lg: "16px",
                            xl: "18px",
                          },
                          textAlign: "left",
                        }}
                      >
                        {item.description5}
                      </RobotoTypo>
                    </Box>
                  )}
                  {item.description6 && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: { xs: "20px", sm: "30px" },
                      }}
                    >
                      <Circle />
                      <RobotoTypo
                        sx={{
                          fontWeight: "400",
                          letterSpacing: "0.03em",
                          lineHeight: "25px",
                          color: "#000000",
                          fontSize: {
                            xs: "15px",
                            md: "16px",
                            lg: "16px",
                            xl: "18px",
                          },
                          textAlign: "left",
                        }}
                      >
                        {item.description6}
                      </RobotoTypo>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

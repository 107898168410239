import React from 'react'
import { Box,Button,Table, TableBody, TableCell, TableContainer, TableRow, Paper  } from '@mui/material';
import { RobotoTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import {  useNavigate } from 'react-router-dom';

export default function AeisExamCourseFeesInfo() {
    const { t } = useTranslation();
    const navigate = useNavigate()

    return (
        <Box sx={{ padding: '4%', position:'relative' }}>
            <RobotoTypo sx={{ fontWeight: '700', letterSpacing: '0.05em', color: '#003B5C', fontSize: { xs: '20px', sm: '25px', md: '28px', lg: '30px' }, textAlign: 'left' }}>
                AEIS Exam Pre-Course Information
            </RobotoTypo><br />
            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: {xs:'16px', md:'18px'}, textAlign: 'left' }}>
                Registration Fee: S$80, one time payment and non-refundable.
            </RobotoTypo><br />
            <TableContainer component={Paper}>
                <Table >
                    <TableBody>
                    {/* First row */}
                    <TableRow >
                        <TableCell style={{ backgroundColor: '#8db3e2', height: 80,border:'4px solid white', textAlign:'center' }}><RobotoTypo sx={{fontWeight:'700', color:'black'}}>入学年级</RobotoTypo><RobotoTypo sx={{fontWeight:'700', color:'black'}}>Level</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#8db3e2', height: 80, border:'4px solid white', textAlign:'center' }}><RobotoTypo sx={{fontWeight:'700', color:'black'}}>课堂模式</RobotoTypo><RobotoTypo sx={{fontWeight:'700', color:'black'}}>Class Mode</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#8db3e2', height: 80, border:'4px solid white', textAlign:'center' }}><RobotoTypo sx={{fontWeight:'700', color:'black'}}>收费</RobotoTypo><RobotoTypo sx={{fontWeight:'700', color:'black'}}>Charges</RobotoTypo></TableCell>
                    </TableRow>
                    {/* Second and Third row */}
                    <TableRow>
                        <TableCell rowSpan={2} style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>小学</RobotoTypo><RobotoTypo sx={{fontWeight:'500', color:'black'}}>Primary school Level</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>1 to 1</RobotoTypo><RobotoTypo sx={{fontWeight:'500', color:'black'}}>一对一</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$3,200</RobotoTypo></TableCell>
                    </TableRow>
                    {/* Fourth and Fifth row */}
                    <TableRow>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>1 to more</RobotoTypo><RobotoTypo sx={{fontWeight:'500', color:'black'}}>一对多</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$2,800</RobotoTypo></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell rowSpan={2} style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>中学</RobotoTypo><RobotoTypo sx={{fontWeight:'500', color:'black'}}>Secondary School Level</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>1 to 1</RobotoTypo><RobotoTypo sx={{fontWeight:'500', color:'black'}}>一对一</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$3,500</RobotoTypo></TableCell>
                    </TableRow>
                    {/* Fourth and Fifth row */}
                    <TableRow>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>1 to more</RobotoTypo><RobotoTypo sx={{fontWeight:'500', color:'black'}}>一对多</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$3,200</RobotoTypo></TableCell>
                    </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{mt:4}}>
            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: '16px', textAlign: 'left' }}>
             Duration: 6 weeks
            </RobotoTypo><br />
            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: '16px', textAlign: 'left' }}>
             Lesson Mode: Online / Offline
            </RobotoTypo><br />
            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: '16px', textAlign: 'left' }}>
             Lesson Time: 3 Lessons a week
            </RobotoTypo><br />
            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: '16px', textAlign: 'left' }}>
             CEQ: Twice / week, 3 hours
            </RobotoTypo><br />
            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: '16px', textAlign: 'left' }}>
             Mathematicts Lesson: Once / week, 2 hours
            </RobotoTypo><br />
            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: '16px', textAlign: 'left' }}>
             Intake: 1st working day of each month 
            </RobotoTypo><br />
            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: '16px', textAlign: 'left' }}>
             Pre-requisties: Primary 2-5, Secondary 1-3
            </RobotoTypo><br />
            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: '16px', textAlign: 'left' }}>
             Academic Level: One level of education below the entrollment level
            </RobotoTypo><br />
            </Box>
            {/* <Box sx={{display:'flex',justifyContent:'center',mt:6}}>
                    <Button
                    onClick={() => navigate('/aeispreexam')}
                    variant='contained'
                    sx={{alignSelf:'flex-end',minWidth:'200px',
                    borderRadius: '50px', textAlign: 'center', backgroundColor: '#003B5C',
                    '&:hover': {
                        backgroundColor: '#003B5C',
                        },
                    }}><RobotoTypo sx={{ fontSize: "20px", letterSpacing: "0.08em", textTransform: "none" }}>Course Info</RobotoTypo></Button>
                </Box> */}
        </Box>
    )
}

import { makeStyles } from '@mui/styles';


export const useStyles = makeStyles((theme) => ({
    // Media query for screens narrower than 1024px
    headerImage: {
        borderBottomLeftRadius: '50px',
    },
    '@media (max-width: 1200px)': {
        headerImage: {
            borderBottomLeftRadius: '0px',
        },

    },
}));
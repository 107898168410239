import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import EducationalJourneyHeader from '../components/StudyingAboard/EducationalJourney/EducationalJourneyHeader';
import EducationalJourneyInfo from '../components/StudyingAboard/EducationalJourney/EducationalJourneyInfo';
import EducationalJourneyServiceIntro from '../components/StudyingAboard/EducationalJourney/EducationalJourneyServiceIntro';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';

export default function EducationalJourney() {
    return (
        <>
            <Header />
            <EducationalJourneyHeader />
            <EducationalJourneyInfo />
            <EducationalJourneyServiceIntro/>
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UniversityDetails from "./UniversityDetails";
import SchoolDetails from "./SchoolDetails";
import UniversityViewDetail from "./UniversityViewDetail";

const StudentDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [studentDetails, setStudentDetails] = useState(null);
  const getStudentDetail = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getStudentDetails/${id}`
      );
      console.log("response get ", response);
      if (response.status == 200) {
        setStudentDetails(response.data);
      } else if (response.status == 403) {
        localStorage.clear();
        navigate("/admin/login");
      }
    } catch (error) {
      console.log("er ", error);
    }
  };
  useEffect(() => {
    getStudentDetail();
  }, []);
  return (
    <div>
      {studentDetails &&
        (studentDetails?.StudentDetails?.type_id === "university" ? (
          <UniversityViewDetail data={studentDetails} />
        ) : (
          <SchoolDetails data={studentDetails} />
        ))}
    </div>
  );
};

export default StudentDetail;

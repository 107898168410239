import React from 'react'
import { Box } from '@mui/material';
import { RobotoTypo, TimesNewTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Circle } from '../../../assets/ServicesImages/StudentPassApplicationImages/circle.svg';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
  // Media query for screens narrower than 1024px
  card: {
    width: '25%',
    alignSelf: 'stretch',
  },
  '@media (max-width: 1026px)': {
    cards: {
      flexDirection: 'column',
    },
    card: {
      width: '50%',
      alignSelf: 'unset',
    }

  },
}));

export default function LongTermApplicationServiceIntro() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box sx={{ position: 'relative', background: '#B1D0E0' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box
          sx={{
            background: '#F5DFBA',
            borderBottomRightRadius: { xs: '0', md: '0', lg: '0', xl: '50px', xxl: '50px' },
            // padding: { xs: '0', sm: "0", md: '0', lg: "40px", xl: '40px' },
            padding: '40px 0',
            zIndex: 10000,
            height: { xs: '11%', sm: "11%", md: '11%', lg: "15%", xl: '15%' },
            width: { xs: '100%', sm: "100%", md: '100%', lg: "30%", xl: '30%' },
            position: { xs: 'relative', sm: "relative", md: 'relative', lg: "absolute", xl: 'absolute' },
            left: '0',
            top: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >

          {/* {t('aboutUs.why_dr', { returnObjects: true }).map((item, index) => ( */}
          <Box
            // key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <RobotoTypo sx={{ fontWeight: '700', color: '#003B5C', fontSize: { xs: '22px', sm: '25px', md: '28px', lg: '30px' }, textAlign: 'center' }}>
              {t('service.longTermPassApplication.longTermApplicationIntro', { returnObjects: true }).name1}                            </RobotoTypo>
            <TimesNewTypo sx={{ fontWeight: '700', color: '#177D84', fontSize: { xs: '18px', md: '22px', lg: '24px' }, textAlign: 'center' }}>
              {t('service.longTermPassApplication.longTermApplicationIntro', { returnObjects: true }).name2}
            </TimesNewTypo>
          </Box>
          {/* ))} */}

        </Box>
        <Box className={classes.cards} sx={{ width: '100%', display: 'flex', gap: '50px', justifyContent: 'center', alignItems: 'center', padding: { xs: '50px 0', md: '8% 0' } }}>
          <Box
            className={classes.card}
            sx={{
              zIndex: '10000',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'start',
              flexDirection: 'column',
              gap: '20px',
              padding: { xs: '20px 20px', sm: '30px 30px' },
              backgroundColor: 'white',
              borderRadius: '50px',
              minWidth: { xs: '213px', sm: '540px' }
            }}>
            {/* {introData?.map((item, index) => ( */}
            <Box sx={{ display: 'flex', gap: '20px', flexDirection: 'column', }}>

              <RobotoTypo sx={{ fontWeight: '700', letterSpacing: '0.03em', lineHeight: '25px', color: '#003B5C', fontSize: { xs: '17px', md: '20px', lg: '20px' }, textAlign: 'left' }}>
                {t('service.longTermPassApplication.longTermApplicationIntro', { returnObjects: true }).title1}
              </RobotoTypo>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <Box

                ><Circle /></Box>
                <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '15px', md: '16px', lg: '16px' }, textAlign: 'left' }}>
                  {t('service.longTermPassApplication.longTermApplicationIntro', { returnObjects: true }).description1}
                </RobotoTypo>

              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <Box

                ><Circle /></Box>
                <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '15px', md: '16px', lg: '16px' }, textAlign: 'left' }}>
                  {t('service.longTermPassApplication.longTermApplicationIntro', { returnObjects: true }).description2}
                </RobotoTypo>

              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <Box

                ><Circle /></Box>
                <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '15px', md: '16px', lg: '16px' }, textAlign: 'left' }}>
                  {t('service.longTermPassApplication.longTermApplicationIntro', { returnObjects: true }).description3}
                </RobotoTypo>

              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <Box

                ><Circle /></Box>
                <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '15px', md: '16px', lg: '16px' }, textAlign: 'left' }}>
                  {t('service.longTermPassApplication.longTermApplicationIntro', { returnObjects: true }).description4}
                </RobotoTypo>

              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <Box

                ><Circle /></Box>
                <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '15px', md: '16px', lg: '16px' }, textAlign: 'left' }}>
                  {t('service.longTermPassApplication.longTermApplicationIntro', { returnObjects: true }).description5}
                </RobotoTypo>

              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <Box

                ><Circle /></Box>
                <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '15px', md: '16px', lg: '16px' }, textAlign: 'left' }}>
                  {t('service.longTermPassApplication.longTermApplicationIntro', { returnObjects: true }).description6}
                </RobotoTypo>

              </Box>
            </Box>



          </Box>


          {/* <Box
            className={classes.card}
            sx={{
              zIndex: '10000',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'start',
              flexDirection: 'column',
              gap: '20px',
              padding: '30px 30px',
              backgroundColor: 'white',
              borderRadius: '50px',
              minWidth: { xs: '200px', sm: '340px' }
            }}>
            <Box sx={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>

              <RobotoTypo sx={{ fontWeight: '700', letterSpacing: '0.03em', lineHeight: '25px', color: '#003B5C', fontSize: { xs: '18px', md: '20px', lg: '20px' }, textAlign: 'left' }}>
                {t('service.longTermPassApplication.longTermApplicationIntro', { returnObjects: true }).title2}
              </RobotoTypo>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', }}>
                <Box

                ><Circle /></Box>
                <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '16px', md: '16px', lg: '16px' }, textAlign: 'left' }}>
                  {t('service.longTermPassApplication.longTermApplicationIntro', { returnObjects: true }).description4}
                </RobotoTypo>

              </Box>
            </Box>



          </Box> */}


        </Box>
      </Box>
    </Box>
  )
}

  import React, { useState } from "react";
  import {
    Box,
    TextField,
    Select,
    FormControl,
    MenuItem,
    InputLabel,
    Button,
    Grid,
    Stack,
    Divider,
    TextareaAutosize,
    Radio,
    RadioGroup,
    FormControlLabel,
  } from "@mui/material";
  import { ErrTypo, RobotoTypo } from "../../../utils/Typographies";
  import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
  import { makeStyles } from "@mui/styles";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
  import { ReactComponent as Hover1 } from "../../../assets/ContactUs/DateIcon.svg";
  import { useTranslation } from "react-i18next";
  import { Grade } from "@mui/icons-material";
  import moment from "moment";
  import DatePicker from "react-datepicker";
  import "react-datepicker/dist/react-datepicker.css";
  import "../../../pages/Admin/News/News.css";

  export default function UniversityApplicationForm3({
    setIsFormValid,
    validationForm3,
    form3Errors1,
    setForm3Errors1,
    form3Errors2,
    setForm3Errors2,
    setFormPage,
    educationalDetails,
    setEducationalDetails,
    academicDetails,
    setAcademicDetails,
    workExperince,
    setWorkExperience,
    otherDetails,
    setOtherDertails,
  }) {
    // const [educationalDetails, setEducationalDetails] = useState([{ school: '', nation: '', highest_education: '', admission_date: '', completion_date:'', finished_condition:'', course_nature: '', language:'' }]);
    // const [academicDetails, setAcademicDetails] = useState([{ graduation_school: '', major: '', admission_time: '', graduation_time: '', academic_certificateNo:'', highest_education:''}]);
    // const [workExperince, setWorkExperience] = useState([{ company_name: '', nation: '', position: '', entry_time: '', resignation_time:''}]);
    // const [otherDetails, setOtherDertails] = useState({gpa_score:"", provide_grade:"", ielts_test:"",score:"",singapore_address:"", residential_address:"", checkin_time:"", departure_time:"", refused_country:"",convicted_crime:"",banned_entry:"",other_passport:"", reason_other_passport:"" })
    const { t } = useTranslation();

    const handleEducationalChange = (index, event, reqField) => {
      console.log("reqfield ", reqField);
      console.log("formerrrrr ", form3Errors1[index]);
      if (reqField == "admission_date" || reqField == "completion_date") {
        const formattedDate = moment(event).format("DD/MM/YYYY");
        const updatedDetails = [...educationalDetails];
        updatedDetails[index][reqField] = formattedDate;
        setEducationalDetails(updatedDetails);
        if (form3Errors1?.length != 0)
          setForm3Errors1((prev) => [...prev, delete prev[index][reqField]]);
      } else {
        const { name, value } = event.target;
        console.log("names ", name);
        const updatedDetails = [...educationalDetails];
        updatedDetails[index][name] = value;
        setEducationalDetails(updatedDetails);
        if (form3Errors1?.length != 0)
          setForm3Errors1((prev) => [
            ...prev,
            prev[index][name] && delete prev[index][name],
          ]);
      }
    };

    const handleAddEducational = () => {
      setEducationalDetails([
        ...educationalDetails,
        {
          school: "",
          nation: "",
          highest_education: "",
          admission_date: "",
          completion_date: "",
          finished_condition: "",
          course_nature: "",
          language: "",
        },
      ]);
    };

    const handleDeleteAdmissionInfo = (index) => {
      const newInfo = [...educationalDetails];
      newInfo.splice(index, 1);
      setEducationalDetails(newInfo);
    };

    const handleAcademicChanges = (index, event) => {
      const { name, value } = event.target;
      const updatedDetails = [...academicDetails];
      updatedDetails[index][name] = value;
      setAcademicDetails(updatedDetails);
    };

    const handleAddAcademicFields = () => {
      setAcademicDetails([
        ...academicDetails,
        {
          graduation_school: "",
          major: "",
          admission_time: "",
          graduation_time: "",
          academic_certificateNo: "",
          highest_education: "",
        },
      ]);
    };

    const handleDeleteAcademicInfo = (index) => {
      const newInfo = [...academicDetails];
      newInfo.splice(index, 1);
      setAcademicDetails(newInfo);
    };

    const handleWorkExperience = (index, event) => {
      const { name, value } = event.target;
      const updatedDetails = [...workExperince];
      updatedDetails[index][name] = value;
      setWorkExperience(updatedDetails);
    };

    const handleAddWorkExperienceFields = () => {
      setWorkExperience([
        ...workExperince,
        {
          company_name: "",
          nation: "",
          position: "",
          entry_time: "",
          resignation_time: "",
        },
      ]);
    };

    const handleDeleteWorkExperienceInfo = (index) => {
      const newInfo = [...workExperince];
      newInfo.splice(index, 1);
      setWorkExperience(newInfo);
    };

    const handleOtherDetails = (e) => {
      const { name, value } = e.target;
      setOtherDertails((prev) => ({ ...prev, [name]: value }));
      setForm3Errors2((prev) => ({ ...prev, [name]: "" }));
    };

    const handleContinue = () => {
      // const isValid = validationForm3();
      // console.log("isValid ", isValid);
      // console.log("form3Errors1 ", form3Errors1);
      // if (isValid) {
      setIsFormValid((prev) => ({ ...prev, formThree: true }));
      setFormPage(3);
      // }
    };

    console.log("otherDetails ", otherDetails);
    return (
      <>
        <Box
          sx={{
            backgroundColor: "#F7F7F7",
            margin: { md: "0 8%", xs: "0 2%" },
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
          }}
        >
          {/* <Box xs={12} padding='18px 30px' sx={{ backgroundColor: '#e1e1e1', borderRadius: '20px 20px 0px 0px' }}>
                      <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                          {t('ApplicationForm.father_info_title', { returnObjects: true })}
                      </RobotoTypo>
                  </Box> */}
          <Divider
            sx={{
              borderTop: "1px solid #F3F4F6",
              width: "100%",
            }}
          />
          <Grid
            container
            rowGap={3}
            columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }}
            padding="3%"
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#e1e1e1",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "20px",
                  textAlign: "left",
                }}
              >
                {t(
                  "UniversityApplicationForm.academic_background.academic_background_title",
                  { returnObjects: true }
                )}
              </RobotoTypo>
            </Stack>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  sx={{
                    mt: 2,
                    backgroundColor: "#177D84",
                    "&:hover": { backgroundColor: "#177D84" },
                  }}
                  onClick={handleAddAcademicFields}
                >
                  Add
                </Button>
              </Grid>
              {academicDetails.map((item, index) => (
                <React.Fragment key={index}>
                  {academicDetails?.length >= 2 && index >= 1 && (
                    <Grid item xs={12}>
                      <Button
                        component="span"
                        onClick={() => handleDeleteAcademicInfo(index)}
                        aria-label="delete"
                        variant="contained"
                        sx={{
                          alignSelf: "center",
                          mt: 2,
                          width: "fit-content",
                          backgroundColor: "#f50057",
                          "&:hover": { backgroundColor: "#f50057" },
                        }}
                      >
                        Delete
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12} md={5} marginTop={"10px"}>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#003B5C",
                        fontSize: "18px",
                        textAlign: "left",
                      }}
                    >
                      {t(
                        "UniversityApplicationForm.academic_background.graduation_school"
                      )}{" "}
                      <Box
                        sx={{ color: "#FF202E", display: "inline-block" }}
                      ></Box>
                    </RobotoTypo>
                    <TextField
                      fullWidth
                      name="graduation_school"
                      value={item.graduation_school}
                      onChange={(event) => handleAcademicChanges(index, event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#003B5C",
                        fontSize: "18px",
                        textAlign: "left",
                      }}
                    >
                      {t("UniversityApplicationForm.academic_background.major")}{" "}
                      <Box
                        sx={{ color: "#FF202E", display: "inline-block" }}
                      ></Box>
                    </RobotoTypo>
                    <TextField
                      fullWidth
                      name="major"
                      value={item.major}
                      onChange={(event) => handleAcademicChanges(index, event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#003B5C",
                        fontSize: "18px",
                        textAlign: "left",
                      }}
                    >
                      {t(
                        "UniversityApplicationForm.academic_background.admission_time"
                      )}{" "}
                      <Box
                        sx={{ color: "#FF202E", display: "inline-block" }}
                      ></Box>
                    </RobotoTypo>
                    <TextField
                      fullWidth
                      name="admission_time"
                      value={item.admission_time}
                      onChange={(event) => handleAcademicChanges(index, event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#003B5C",
                        fontSize: "18px",
                        textAlign: "left",
                      }}
                    >
                      {t(
                        "UniversityApplicationForm.academic_background.graduation_time"
                      )}{" "}
                      <Box
                        sx={{ color: "#FF202E", display: "inline-block" }}
                      ></Box>
                    </RobotoTypo>
                    <TextField
                      fullWidth
                      name="graduation_time"
                      value={item.graduation_time}
                      onChange={(event) => handleAcademicChanges(index, event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#003B5C",
                        fontSize: "18px",
                        textAlign: "left",
                      }}
                    >
                      {t(
                        "UniversityApplicationForm.academic_background.academic_certificate_number"
                      )}{" "}
                      <Box
                        sx={{ color: "#FF202E", display: "inline-block" }}
                      ></Box>
                    </RobotoTypo>
                    <TextField
                      fullWidth
                      name="academic_certificateNo"
                      value={item.academic_certificateNo}
                      onChange={(event) => handleAcademicChanges(index, event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#003B5C",
                        fontSize: "18px",
                        textAlign: "left",
                      }}
                    >
                      {t(
                        "UniversityApplicationForm.academic_background.highest_education"
                      )}{" "}
                      <Box
                        sx={{ color: "#FF202E", display: "inline-block" }}
                      ></Box>
                    </RobotoTypo>
                    <TextField
                      fullWidth
                      name="highest_education"
                      value={item.highest_education}
                      onChange={(event) => handleAcademicChanges(index, event)}
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#e1e1e1",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "20px",
                  textAlign: "left",
                }}
              >
                {t(
                  "UniversityApplicationForm.work_experience.work_experience_title",
                  { returnObjects: true }
                )}
              </RobotoTypo>
            </Stack>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  sx={{
                    mt: 2,
                    backgroundColor: "#177D84",
                    "&:hover": { backgroundColor: "#177D84" },
                  }}
                  onClick={handleAddWorkExperienceFields}
                >
                  Add
                </Button>
              </Grid>
              {workExperince?.map((item, index) => (
                <React.Fragment key={index}>
                  {workExperince?.length >= 2 && index >= 1 && (
                    <Grid item xs={12}>
                      <Button
                        component="span"
                        onClick={() => handleDeleteWorkExperienceInfo(index)}
                        aria-label="delete"
                        variant="contained"
                        sx={{
                          alignSelf: "center",
                          mt: 2,
                          width: "fit-content",
                          backgroundColor: "#f50057",
                          "&:hover": { backgroundColor: "#f50057" },
                        }}
                      >
                        Delete
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12} md={5} marginTop={"10px"}>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#003B5C",
                        fontSize: "18px",
                        textAlign: "left",
                      }}
                    >
                      {t(
                        "UniversityApplicationForm.work_experience.company_name"
                      )}
                    </RobotoTypo>
                    <TextField
                      fullWidth
                      name="company_name"
                      value={item.company_name}
                      onChange={(event) => handleWorkExperience(index, event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#003B5C",
                        fontSize: "18px",
                        textAlign: "left",
                      }}
                    >
                      {t("UniversityApplicationForm.work_experience.nation")}
                    </RobotoTypo>
                    <TextField
                      fullWidth
                      name="nation"
                      value={item.nation}
                      onChange={(event) => handleWorkExperience(index, event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#003B5C",
                        fontSize: "18px",
                        textAlign: "left",
                      }}
                    >
                      {t("UniversityApplicationForm.work_experience.position")}
                    </RobotoTypo>
                    <TextField
                      fullWidth
                      name="position"
                      value={item.position}
                      onChange={(event) => handleWorkExperience(index, event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#003B5C",
                        fontSize: "18px",
                        textAlign: "left",
                      }}
                    >
                      {t("UniversityApplicationForm.work_experience.entry_time")}
                    </RobotoTypo>
                    <TextField
                      fullWidth
                      name="entry_time"
                      value={item.entry_time}
                      onChange={(event) => handleWorkExperience(index, event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#003B5C",
                        fontSize: "18px",
                        textAlign: "left",
                      }}
                    >
                      {t(
                        "UniversityApplicationForm.work_experience.resignation_time"
                      )}
                    </RobotoTypo>
                    <TextField
                      fullWidth
                      name="resignation_time"
                      value={item.resignation_time}
                      onChange={(event) => handleWorkExperience(index, event)}
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#e1e1e1",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "20px",
                  textAlign: "left",
                }}
              >
                {t(
                  "UniversityApplicationForm.educational_background.educational_background_title",
                  { returnObjects: true }
                )}
              </RobotoTypo>
            </Stack>

            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  sx={{
                    mt: 2,
                    backgroundColor: "#177D84",
                    "&:hover": { backgroundColor: "#177D84" },
                  }}
                  onClick={handleAddEducational}
                >
                  Add
                </Button>
              </Grid>
              {educationalDetails.map((item, index) => (
                <React.Fragment key={index}>
                  {educationalDetails?.length >= 2 && index >= 1 && (
                    <Grid item xs={12}>
                      <Button
                        component="span"
                        onClick={() => handleDeleteAdmissionInfo(index)}
                        aria-label="delete"
                        variant="contained"
                        sx={{
                          alignSelf: "center",
                          mt: 2,
                          width: "fit-content",
                          backgroundColor: "#f50057",
                          "&:hover": { backgroundColor: "#f50057" },
                        }}
                      >
                        Delete
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12} md={5} marginTop={"10px"}>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#003B5C",
                        fontSize: "18px",
                        textAlign: "left",
                      }}
                    >
                      {t(
                        "UniversityApplicationForm.educational_background.school"
                      )}
                    </RobotoTypo>
                    <TextField
                      fullWidth
                      name="school"
                      value={item.school}
                      onChange={(event) => handleEducationalChange(index, event)}
                    />
                    {form3Errors1[index] && form3Errors1[index].school && (
                      <ErrTypo> {form3Errors1[index].school}</ErrTypo>
                    )}
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#003B5C",
                        fontSize: "18px",
                        textAlign: "left",
                      }}
                    >
                      {t(
                        "UniversityApplicationForm.educational_background.nation"
                      )}
                    </RobotoTypo>
                    <TextField
                      fullWidth
                      name="nation"
                      value={item.nation}
                      onChange={(event) => handleEducationalChange(index, event)}
                    />
                    {form3Errors1[index] && form3Errors1[index].nation && (
                      <ErrTypo> {form3Errors1[index].nation}</ErrTypo>
                    )}
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#003B5C",
                        fontSize: "18px",
                        textAlign: "left",
                      }}
                    >
                      {t(
                        "UniversityApplicationForm.educational_background.highest_education"
                      )}
                    </RobotoTypo>
                    <TextField
                      fullWidth
                      name="highest_education"
                      value={item.highest_education}
                      onChange={(event) => handleEducationalChange(index, event)}
                    />
                    {form3Errors1[index] &&
                      form3Errors1[index].highest_education && (
                        <ErrTypo>
                          {" "}
                          {form3Errors1[index].highest_education}
                        </ErrTypo>
                      )}
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#003B5C",
                        fontSize: "18px",
                        textAlign: "left",
                      }}
                    >
                      {t(
                        "UniversityApplicationForm.educational_background.admission_date"
                      )}
                    </RobotoTypo>
                    <Box sx={{ width: "100%", display: "flex", flex: 1 }}>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        style={{ width: "100%" }}
                        selected={
                          item.admission_date
                            ? moment(item.admission_date, "DD/MM/YYYY").toDate()
                            : null
                        }
                        onChange={(event) =>
                          handleEducationalChange(index, event, "admission_date")
                        }
                        placeholderText="DD/MM/YYYY"
                        customInput={
                          <TextField
                            name="admission_date"
                            sx={{ width: "100%" }}
                            fullWidth
                          />
                        }
                      />
                    </Box>
                    {form3Errors1[index] &&
                      form3Errors1[index].admission_date && (
                        <ErrTypo> {form3Errors1[index].admission_date}</ErrTypo>
                      )}
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#003B5C",
                        fontSize: "18px",
                        textAlign: "left",
                      }}
                    >
                      {t(
                        "UniversityApplicationForm.educational_background.completion_date"
                      )}
                    </RobotoTypo>
                    {/* <TextField
                      fullWidth
                      name="completion_date"
                      value={item.completion_date}
                      onChange={(event) => handleEducationalChange(index, event)}
                      /> */}
                    <Box sx={{ width: "100%", display: "flex", flex: 1 }}>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        style={{ width: "100%" }}
                        selected={
                          item.completion_date
                            ? moment(item.completion_date, "DD/MM/YYYY").toDate()
                            : null
                        }
                        onChange={(event) =>
                          handleEducationalChange(index, event, "completion_date")
                        }
                        placeholderText="DD/MM/YYYY"
                        customInput={
                          <TextField
                            name="completion_date"
                            sx={{ width: "100%" }}
                            fullWidth
                          />
                        }
                      />
                    </Box>
                    {form3Errors1[index] &&
                      form3Errors1[index].completion_date && (
                        <ErrTypo> {form3Errors1[index].completion_date}</ErrTypo>
                      )}
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#003B5C",
                        fontSize: "18px",
                        textAlign: "left",
                      }}
                    >
                      {t(
                        "UniversityApplicationForm.educational_background.finished_condition"
                      )}
                    </RobotoTypo>
                    <TextField
                      fullWidth
                      name="finished_condition"
                      value={item.finished_condition}
                      onChange={(event) => handleEducationalChange(index, event)}
                    />
                    {form3Errors1[index] &&
                      form3Errors1[index].finished_condition && (
                        <ErrTypo>
                          {" "}
                          {form3Errors1[index].finished_condition}
                        </ErrTypo>
                      )}
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#003B5C",
                        fontSize: "18px",
                        textAlign: "left",
                      }}
                    >
                      {t(
                        "UniversityApplicationForm.educational_background.course_nature"
                      )}
                    </RobotoTypo>
                    <TextField
                      fullWidth
                      name="course_nature"
                      value={item.course_nature}
                      onChange={(event) => handleEducationalChange(index, event)}
                    />
                    {form3Errors1[index] && form3Errors1[index].course_nature && (
                      <ErrTypo> {form3Errors1[index].course_nature}</ErrTypo>
                    )}
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#003B5C",
                        fontSize: "18px",
                        textAlign: "left",
                      }}
                    >
                      {t(
                        "UniversityApplicationForm.educational_background.language"
                      )}
                    </RobotoTypo>
                    <TextField
                      fullWidth
                      name="language"
                      value={item.language}
                      onChange={(event) => handleEducationalChange(index, event)}
                    />
                    {form3Errors1[index] && form3Errors1[index].language && (
                      <ErrTypo> {form3Errors1[index].language}</ErrTypo>
                    )}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
            <Grid md={12} marginTop={"20px"}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "20px",
                  textAlign: "left",
                }}
              >
                Relavant Details{" "}
                <Box sx={{ color: "#FF202E", display: "inline-block" }}></Box>
              </RobotoTypo>
            </Grid>
            <Grid xs={12} md={5}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {t("UniversityApplicationForm.educational_background.gpa_score", {
                  returnObjects: true,
                })}
              </RobotoTypo>
              {/* <TextField name='name' value={fatherDetails.name} onChange={handleFatherDetails} /> */}
              <RadioGroup
                row
                name="gpa_score"
                value={otherDetails.gpa_score}
                onChange={handleOtherDetails}
              >
                <FormControlLabel
                  sx={{ color: "#003B5C", fontSize: "14px", ml: 0, mt: 1 }}
                  value="Yes"
                  control={<Radio name="gpa_score" />}
                  label={t(
                    "UniversityApplicationForm.educational_background.yes",
                    { returnObjects: true }
                  )}
                  labelPlacement="top"
                />
                <FormControlLabel
                  sx={{ color: "#003B5C", fontSize: "14px" }}
                  value="No"
                  control={<Radio name="gpa_score" />}
                  label={t(
                    "UniversityApplicationForm.educational_background.no",
                    { returnObjects: true }
                  )}
                  labelPlacement="top"
                />
              </RadioGroup>
              {form3Errors2 && form3Errors2.gpa_score && (
                <ErrTypo> {form3Errors2.gpa_score}</ErrTypo>
              )}
            </Grid>
            <Grid xs={12} md={5}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {t(
                  "UniversityApplicationForm.educational_background.provide_grade",
                  { returnObjects: true }
                )}
              </RobotoTypo>
              <RadioGroup
                row
                name="provide_grade"
                value={otherDetails.provide_grade}
                onChange={handleOtherDetails}
              >
                <FormControlLabel
                  sx={{ color: "#003B5C", fontSize: "14px" }}
                  value="AS?"
                  control={<Radio name="provide_grade" />}
                  label={"AS?"}
                  labelPlacement="top"
                />
                <FormControlLabel
                  sx={{ color: "#003B5C", fontSize: "14px" }}
                  value="Bs?"
                  control={<Radio name="provide_grade" />}
                  label={"Bs?"}
                  labelPlacement="top"
                />
                <FormControlLabel
                  sx={{ color: "#003B5C", fontSize: "14px" }}
                  value="Cs?"
                  control={<Radio name="provide_grade" />}
                  label={"Cs?"}
                  labelPlacement="top"
                />
                <FormControlLabel
                  sx={{ color: "#003B5C", fontSize: "14px" }}
                  value="Ds?"
                  control={<Radio name="provide_grade" />}
                  label={"Ds?"}
                  labelPlacement="top"
                />
                <FormControlLabel
                  sx={{ color: "#003B5C", fontSize: "14px" }}
                  value="Es?"
                  control={<Radio name="provide_grade" />}
                  label={"Es?"}
                  labelPlacement="top"
                />
                <FormControlLabel
                  sx={{ color: "#003B5C", fontSize: "14px" }}
                  value="Passes?"
                  control={<Radio name="provide_grade" />}
                  label={"Passes?"}
                  labelPlacement="top"
                />
                <FormControlLabel
                  sx={{ color: "#003B5C", fontSize: "14px" }}
                  value="Fs?"
                  control={<Radio name="provide_grade" />}
                  label={"Fs?"}
                  labelPlacement="top"
                />
              </RadioGroup>
              {form3Errors2 && form3Errors2.provide_grade && (
                <ErrTypo> {form3Errors2.provide_grade}</ErrTypo>
              )}
            </Grid>

            <Grid xs={12} md={5}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {t(
                  "UniversityApplicationForm.educational_background.ielts_test",
                  { returnObjects: true }
                )}{" "}
                <Box sx={{ color: "#FF202E", display: "inline-block" }}></Box>
              </RobotoTypo>
              {/* <TextField name='name' value={fatherDetails.name} onChange={handleFatherDetails} /> */}
              <RadioGroup
                row
                name="ielts_test"
                value={otherDetails.ielts_test}
                onChange={handleOtherDetails}
              >
                <FormControlLabel
                  sx={{ color: "#003B5C", fontSize: "14px", ml: 0, mt: 1 }}
                  value="Yes"
                  control={<Radio name="ielts_test" />}
                  label={t(
                    "UniversityApplicationForm.educational_background.yes",
                    { returnObjects: true }
                  )}
                  labelPlacement="top"
                />
                <FormControlLabel
                  sx={{ color: "#003B5C", fontSize: "14px" }}
                  value="No"
                  control={<Radio name="ielts_test" />}
                  label={t(
                    "UniversityApplicationForm.educational_background.no",
                    { returnObjects: true }
                  )}
                  labelPlacement="top"
                />
              </RadioGroup>
            </Grid>

            <Grid xs={12} md={5}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {t(
                  "UniversityApplicationForm.educational_background.provide_ielts",
                  { returnObjects: true }
                )}{" "}
                <Box sx={{ color: "#FF202E", display: "inline-block" }}></Box>
              </RobotoTypo>
              <TextField
                name="score"
                value={otherDetails.score}
                onChange={handleOtherDetails}
              />
              {form3Errors2 && form3Errors2.score && (
                <ErrTypo> {form3Errors2.score}</ErrTypo>
              )}
            </Grid>

            <Grid xs={12}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {t(
                  "UniversityApplicationForm.educational_background.singapore_address",
                  { returnObjects: true }
                )}{" "}
                <Box sx={{ color: "#FF202E", display: "inline-block" }}></Box>
              </RobotoTypo>
              <TextField
                name="singapore_address"
                value={otherDetails.singapore_address}
                onChange={handleOtherDetails}
                sx={{
                  "& .MuiInputBase-root": { padding: "0px" },
                  minHeight: "88px",
                }}
                multiline
              />
            </Grid>

            <Grid xs={12}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {t(
                  "UniversityApplicationForm.educational_background.residential_address",
                  { returnObjects: true }
                )}{" "}
                <Box sx={{ color: "#FF202E", display: "inline-block" }}></Box>
              </RobotoTypo>
              <TextField
                name="residential_address"
                value={otherDetails.residential_address}
                onChange={handleOtherDetails}
                sx={{
                  "& .MuiInputBase-root": { padding: "0px" },
                  minHeight: "88px",
                }}
                multiline
              />
            </Grid>

            <Grid xs={12} md={5}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {t(
                  "UniversityApplicationForm.educational_background.checkin_time",
                  { returnObjects: true }
                )}{" "}
                <Box sx={{ color: "#FF202E", display: "inline-block" }}></Box>
              </RobotoTypo>
              <TextField
                name="checkin_time"
                value={otherDetails.checkin_time}
                onChange={handleOtherDetails}
              />
            </Grid>

            <Grid xs={12} md={5}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {t(
                  "UniversityApplicationForm.educational_background.departure_time",
                  { returnObjects: true }
                )}{" "}
                <Box sx={{ color: "#FF202E", display: "inline-block" }}></Box>
              </RobotoTypo>
              <TextField
                name="departure_time"
                value={otherDetails.departure_time}
                onChange={handleOtherDetails}
              />
            </Grid>

            <Grid md={12}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {t(
                  "UniversityApplicationForm.educational_background.refused_country",
                  { returnObjects: true }
                )}{" "}
                <Box sx={{ color: "#FF202E", display: "inline-block" }}></Box>
              </RobotoTypo>
              <RadioGroup
                row
                name="refused_country"
                value={otherDetails.refused_country}
                onChange={handleOtherDetails}
              >
                <FormControlLabel
                  sx={{ color: "#003B5C", fontSize: "14px", ml: 0, mt: 1 }}
                  value="Yes"
                  control={<Radio name="refused_country" />}
                  label={t(
                    "UniversityApplicationForm.educational_background.yes",
                    { returnObjects: true }
                  )}
                  labelPlacement="top"
                />
                <FormControlLabel
                  sx={{ color: "#003B5C", fontSize: "14px" }}
                  value="No"
                  control={<Radio name="refused_country" />}
                  label={t(
                    "UniversityApplicationForm.educational_background.no",
                    { returnObjects: true }
                  )}
                  labelPlacement="top"
                />
              </RadioGroup>
            </Grid>

            <Grid md={12}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {t(
                  "UniversityApplicationForm.educational_background.convicted_crime",
                  { returnObjects: true }
                )}{" "}
                <Box sx={{ color: "#FF202E", display: "inline-block" }}></Box>
              </RobotoTypo>
              <RadioGroup
                row
                name="convicted_crime"
                value={otherDetails.convicted_crime}
                onChange={handleOtherDetails}
              >
                <FormControlLabel
                  sx={{ color: "#003B5C", fontSize: "14px", ml: 0, mt: 1 }}
                  value="Yes"
                  control={<Radio name="convicted_crime" />}
                  label={t(
                    "UniversityApplicationForm.educational_background.yes",
                    { returnObjects: true }
                  )}
                  labelPlacement="top"
                />
                <FormControlLabel
                  sx={{ color: "#003B5C", fontSize: "14px" }}
                  value="No"
                  control={<Radio name="convicted_crime" />}
                  label={t(
                    "UniversityApplicationForm.educational_background.no",
                    { returnObjects: true }
                  )}
                  labelPlacement="top"
                />
              </RadioGroup>
            </Grid>

            <Grid md={12}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {t(
                  "UniversityApplicationForm.educational_background.banned_entry",
                  { returnObjects: true }
                )}{" "}
                <Box sx={{ color: "#FF202E", display: "inline-block" }}></Box>
              </RobotoTypo>
              {/* <TextField name='name' value={fatherDetails.name} onChange={handleFatherDetails} /> */}
              <RadioGroup
                row
                name="banned_entry"
                value={otherDetails.banned_entry}
                onChange={handleOtherDetails}
              >
                <FormControlLabel
                  sx={{ color: "#003B5C", fontSize: "14px", ml: 0, mt: 1 }}
                  value="Yes"
                  control={<Radio name="banned_entry" />}
                  label={t(
                    "UniversityApplicationForm.educational_background.yes",
                    { returnObjects: true }
                  )}
                  labelPlacement="top"
                />
                <FormControlLabel
                  sx={{ color: "#003B5C", fontSize: "14px" }}
                  value="No"
                  control={<Radio name="banned_entry" />}
                  label={t(
                    "UniversityApplicationForm.educational_background.no",
                    { returnObjects: true }
                  )}
                  labelPlacement="top"
                />
              </RadioGroup>
            </Grid>

            <Grid xs={12} md={5}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {t(
                  "UniversityApplicationForm.educational_background.other_passport",
                  { returnObjects: true }
                )}{" "}
                <Box sx={{ color: "#FF202E", display: "inline-block" }}></Box>
              </RobotoTypo>
              {/* <TextField name='name' value={fatherDetails.name} onChange={handleFatherDetails} /> */}
              <RadioGroup
                row
                name="other_passport"
                value={otherDetails.other_passport}
                onChange={handleOtherDetails}
              >
                <FormControlLabel
                  sx={{ color: "#003B5C", fontSize: "14px", ml: 0, mt: 1 }}
                  value="Yes"
                  control={<Radio name="other_passport" />}
                  label={t(
                    "UniversityApplicationForm.educational_background.yes",
                    { returnObjects: true }
                  )}
                  labelPlacement="top"
                />
                <FormControlLabel
                  sx={{ color: "#003B5C", fontSize: "14px" }}
                  value="No"
                  control={<Radio name="other_passport" />}
                  label={t(
                    "UniversityApplicationForm.educational_background.no",
                    { returnObjects: true }
                  )}
                  labelPlacement="top"
                />
              </RadioGroup>
            </Grid>

            <Grid xs={12} md={5}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {t(
                  "UniversityApplicationForm.educational_background.provide_ielts",
                  { returnObjects: true }
                )}{" "}
                <Box sx={{ color: "#FF202E", display: "inline-block" }}></Box>
              </RobotoTypo>
              <TextField
                name="reason_other_passport"
                value={otherDetails.reason_other_passport}
                onChange={handleOtherDetails}
              />
            </Grid>
          </Grid>

          <Box
            display="flex"
            justifyContent="space-between"
            padding="2% 3%"
            sx={{ borderTop: "1px solid #E5E7EB" }}
          >
            <Button
              onClick={() => {
                setFormPage(1);
              }}
              variant="Standard"
            >
              Back
            </Button>
            <Button onClick={handleContinue} variant="Standard">
              Continue
            </Button>
          </Box>
        </Box>
      </>
    );
  }

import React from 'react'
import { Box } from '@mui/material';
import { RobotoTypo, TimesNewTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import Header from '../../../assets/png/academicPathway.png';
import { useStyles } from '../../../utils/headerTheme';

export default function AcademicCounselingHeader() {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Box
            sx={{
                position: 'relative',
                // height: '100%',
                width: '100%',
                overflow: 'hidden',
                background: '#B1D0E0',
            }}

        >
            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: { xs: 'column', md: 'column', lg: 'row', xl: 'row', xxl: 'row' },
                justifyContent: 'end',
            }}
            >
                <Box

                    sx={{
                        background: '#fff',
                        borderBottomRightRadius: { md: '0', lg: '50px' },
                        padding: { xs: '0', md: '0', lg: '60px', },
                        zIndex: { xs: 0, md: 0, lg: 10000 },
                        height: { xs: '100px', md: '100px', lg: '60px', xl: '60' },
                        // width: { xs: '100%', md: '100%', lg: '15%', xl: '15%' },
                        position: { xs: 'relative', md: 'relative', lg: 'absolute', xl: 'absolute' },
                        left: '0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            fontSize: '16px'
                        }}
                    >
                        <RobotoTypo sx={{ textAlign: { xs: 'center', sm: 'left' }, letterSpacing: '0.03em', fontWeight: '500', color: '#003B5C', fontSize: { xs: '20px', sm: '25px', md: '28px', lg: '30px' } }}>
                            {t('AcademicPathway.header', { returnObjects: true }).name1}
                        </RobotoTypo>
                        <TimesNewTypo sx={{ textAlign: { xs: 'center', sm: 'left' }, letterSpacing: '0.025em', fontWeight: '700', color: '#177D84', fontSize: { xs: '18px', md: '24px', lg: '25px' } }}>
                            {t('AcademicPathway.header', { returnObjects: true }).name2}
                        </TimesNewTypo>
                    </Box>
                </Box>

                <Box
                    sx={{ width: { xs: '100%', md: '100%', lg: '85%', xl: '85%', xxl: '85%' }, maxWidth: { xs: '100%', md: '100%', lg: '85%', xl: '85%', xxl: '85%' }, position: 'relative', zIndex: '1', borderBottomLeftRadius: { xs: '0px', md: '0px', lg: '0px', xl: '50px', xxl: '50px' }, height: 'calc(80vh - 128px)', marginBottom: { md: '0px', lg: '30px' } }}
                >
                    <img
                        className={classes.headerImage}
                        src={Header}
                        alt='Academic Counseling'
                        style={{
                            objectFit: 'cover',
                            height: '100%',
                            borderBottomLeftRadius: { xs: '0', md: '0', lg: '0', xl: '50px', xxl: '50px' },
                            width: '100%',
                            position: { xs: 'relative', md: 'relative', lg: 'absolute', xl: 'absolute', xxl: 'absoulte' },
                            right: '0',
                        }}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    height: '200px',
                    display: { xs: 'none', md: 'none', lg: 'block', xl: 'block', xxl: 'block' },
                    width: { xs: '15%', sm: "15%", md: '12%', lg: "10%", xl: '10%' },
                    borderTopRightRadius: '50px',
                    borderRight: '8px solid white',
                    borderTop: '8px solid white',
                    borderColor: '#F5DFBA',
                    position: 'absolute',
                    bottom: '0px',
                    zIndex: '99',
                }}
            ></Box>
        </Box >
    )
}

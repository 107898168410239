import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import InvestmentConsultationHeader from '../components/Service/InvestmentConsultation/InvestmentConsultationHeader';
import InvestmentConsultationInfo from '../components/Service/InvestmentConsultation/InvestmentConsultationInfo';
import Share from '../components/Share';
import SideOverlap from './sideOverlap'

export default function InvestmentConsultation() {
    return (
        <>
            <Header/>
            <InvestmentConsultationHeader />
            <InvestmentConsultationInfo />
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}
import React from 'react'
import { Box } from '@mui/material';
import { ReactComponent as Arrows } from '../../../assets/ServicesImages/StudentPassApplicationImages/arrow.svg';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
    // Media query for screens narrower than 1024px
    ArrowSide: {
        right: '9.7%',
        top: '317px',
    },
    '@media (max-width: 1023px)': {
        borderedElement: {
            display: 'none',
            border: 'none', // Hide the border
        },
    },
    '@media (max-width: 1535px)': {
        ArrowSide: {
            right: '8.2%',
            top: '286px',
        },
    },
}));
export default function Arrowline() {
    const classes = useStyles();
    return (
        <>
            {/* <Box sx={{ position: 'relative', top: '135px' }}> */}
            <Box
                className={classes.borderedElement}
                sx={{
                    display: { xs: 'none', md: 'none', lg: 'block' },
                    height: { md: '34%', lg: '32%' },
                    width: '87.5%',
                    // borderTopRightRadius: '50px',
                    borderRight: '3px solid #B1D0E0',
                    borderTop: 'hidden !important',
                    borderStyle: 'dashed',
                    borderLeft: 'hidden !important',
                    borderColor: '#B1D0E0',
                    position: 'absolute',
                    bottom: { md: '8%', lg: '8%' },
                    right: { lg: '8.5%', xl: '10%' },
                    zIndex: '99',
                }}
            />
            <Box className={classes.ArrowSide}
                sx={{

                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block', xxl: 'block' },


                    position: 'absolute',
                    // top: '317px',
                    // right: '120px',
                    zIndex: '99',
                    transform: 'rotate(270deg)'
                }}
            ><Arrows /></Box>

            <Box
                sx={{

                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block', xxl: 'block' },


                    position: 'absolute',
                    bottom: '5.5%',
                    right: '30%',
                    zIndex: '99',
                }}
            ><Arrows /></Box>
            <Box
                sx={{

                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block', xxl: 'block' },


                    position: 'absolute',
                    bottom: '5.5%',
                    right: '52%',
                    zIndex: '99',
                }}
            ><Arrows /></Box>
            <Box
                sx={{

                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block', xxl: 'block' },


                    position: 'absolute',
                    bottom: '5.5%',
                    right: '78%',
                    zIndex: '99',
                }}
            ><Arrows /></Box>

            {/* </Box> */}
            <Box
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block', xxl: 'block' },
                    height: '30%',
                    width: { lg: '10%', xl: "15%" },
                    borderRight: 'hidden !important',
                    borderBottom: 'hidden !important',
                    borderTop: '3px solid #B1D0E0',
                    borderStyle: 'dashed',
                    // borderBottomWidth: '150%',
                    borderLeft: 'hidden !important',
                    borderColor: '#B1D0E0',
                    position: 'absolute',
                    bottom: { md: '8%', lg: '10%' },
                    right: { lg: '8.5%', xl: '10%' },
                    zIndex: '99',
                }}
            />
            {/* <Box
                sx={{

                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block', xxl: 'block' },


                    position: 'absolute',
                    top: '317px',
                    right: '120px',
                    zIndex: '99',
                    transform: 'rotate(270deg)'
                }}
            ><Arrows /></Box> */}
            {/* <Box
                sx={{

                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block', xxl: 'block' },


                    position: 'absolute',
                    top: '380px',
                    right: '30%',
                    zIndex: '99',
                }}
            ><Arrows /></Box>
            <Box
                sx={{

                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block', xxl: 'block' },


                    position: 'absolute',
                    top: '380px',
                    right: '52%',
                    zIndex: '99',
                }}
            ><Arrows /></Box>
            <Box
                sx={{

                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block', xxl: 'block' },


                    position: 'absolute',
                    top: '380px',
                    right: '78%',
                    zIndex: '99',
                }}
            ><Arrows /></Box> */}
        </>
    )
}

import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import AeisExamPreHeader from '../components/StudyingAboard/AeisPreExam/AeisExamPreHeader';
import AeisExamPreInfo from '../components/StudyingAboard/AeisPreExam/AeisExamPreInfo';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';
import AeisExamServiceIntro from '../components/StudyingAboard/AeisExam/AeisExamServiceIntro';

export default function AeisExamPre() {
    return (
        <>
            <Header />
            <AeisExamPreHeader />
            <AeisExamPreInfo />
            <AeisExamServiceIntro/>
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}
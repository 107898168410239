import React from "react";
import Header from "../../components/Header";
import NewsInformation from "./NewsInformation";
import NewsHeader from "./NewsHeader";
import Share from "../../components/Share";
import SideOverlap from "../sideOverlap";
import Footer from "../../components/Footer";

const NewsComponent = () => {
  return (
    <>
      <Header />
      <NewsHeader />
      <NewsInformation />
      <Share />
      <SideOverlap />
      <Footer />
    </>
  );
};

export default NewsComponent;

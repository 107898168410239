import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import TutionInfoHeader from '../components/StudyingAboard/TutionInformation/TutionInfoHeader';
import TutionCourseInfo from '../components/StudyingAboard/TutionInformation/TutionCourseInfo';
import ApplicationRequirement from '../components/StudyingAboard/TutionInformation/ApplicationRequirement';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';

export default function TutionCourseInformation() {
    return (
        <>
            <Header />
            <TutionInfoHeader />
            <TutionCourseInfo />
            <ApplicationRequirement/>
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}
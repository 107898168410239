import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Box } from "@mui/system";
import { Typography, Card, CardContent, Button, Stack } from "@mui/material";
import { RobotoTypo } from "../../utils/Typographies";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { useTranslation } from "react-i18next";
import correctImg from "../../assets/svg/correct.png";
import failureImg from "../../assets/svg/cancel.png";
import SuccessIcon from "../../assets/payment/Vector.svg";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";

function UniversityView() {
  const { id } = useParams();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [applicationData, setApplicationData] = useState({});

  const fetchApplicationData = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/student-application/${id}`)
      .then((response) => {
        console.log(response.data);
        setApplicationData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (id) {
      fetchApplicationData();
    }
  }, [id]);

  const handleSubmit = async (e) => {
    setLoading(true);
    if (e) {
      e.preventDefault();
    }
    try {
      console.log("Attempting to open OmiseCard");
      if (typeof window.OmiseCard !== "undefined") {
        const amount = applicationData?.StudentDetails?.application_fee * 100;
        if (!amount || amount <= 0) {
          console.error("Invalid amount:", amount);
          return;
        }
        console.log("Opening OmiseCard with amount:", amount);
        window.OmiseCard.open({
          amount: amount,
          currency: "SGD",
          frameLabel: "Dr_tech International",
          submitLabel: "Pay Now",
          otherPaymentMethods: ["alipay_hk", "kakaopay", "touch_n_go"],
          onCreateTokenSuccess: handleOmiseResponse,
          onFormClosed: () => {
            console.log("Payment form closed");
            setLoading(false);
            // Reset any relevant state here if needed
          },
        });
      } else {
        console.error("OmiseCard is not available");
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };

  const handleOmiseResponse = async (tokenID) => {
    console.log("called omise response");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/charge`,
        {
          token: tokenID,
          user_id: applicationData?.StudentDetails?.user_id,
          application_id: applicationData?._id,
          amount: applicationData?.StudentDetails?.application_fee * 100,
          user_email: applicationData?.StudentDetails?.personalDetails?.email,
        }
      );
      console.log(response?.data);
      localStorage.setItem("payment_id", response?.data?.id);
      setLoading(false);
      if (response.data.authorize_uri) {
        window.location.href = response.data.authorize_uri;
      } else {
        console.log("Charge response:", response.data.authorize_uri);
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      setLoading(false);
    }
  };

  const studentBox = { width: "45%" };
  const studentTitle = {
    color: "#003B5C",
    fontSize: "1.1rem",
    fontWeight: 600,
  };
  const studentContent = { color: "#003B5C" };
  const studentOuterBox = {
    display: "flex",
    justifyContent: "space-around",
    mt: 3,
  };
  const paymentIcon = (status) => {
    switch (status) {
      case "successful":
        return (
          <img
            style={{ width: "40px", height: "40px" }}
            alt="payment-indicator"
            src={SuccessIcon}
          />
        );

      case "pending":
        return <PendingActionsIcon sx={{ color: "#FF7900 " }} />;

      default:
        return <SmsFailedIcon sx={{ color: "red" }} />;
    }
  };
  const paymentTitle = (status) => {
    switch (status) {
      case "successful":
        return "Payment was successful ";

      case "pending":
        return "Your payment is currently pending. ";

      default:
        return "Payment failed.";
    }
  };

  const downloadInvoice = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/generateInvoicePdf/${id}`,
        {
          responseType: "blob", // Ensure the response is treated as a binary Blob
        }
      );

      // Create a URL for the Blob and download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `invoice_${id}.pdf`); // Set the file name
      document.body.appendChild(link);
      link.click();

      // Clean up the link after download
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the invoice:", error);
    }
  };
  const generatePaymentStatus = (status, applicationData) => {
    console.log("status", status);
    if (status !== undefined) {
      console.log("status1", status);
      return (
        <Stack py={"60px"} alignItems={"center"} gap={"20px"}>
          <>
            {" "}
            <style>
              {`
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
        
        .poppins-text {
            font-family: 'Poppins', sans-serif;
        }
    `}
            </style>
          </>
          <Box width="100%" display={"flex"} justifyContent={"center"}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: "70px",
                height: "70px",
                borderRadius: "50%",
                backgroundColor:
                  status === "successful"
                    ? "rgba(35, 162, 109, 0.12)"
                    : status === "pending"
                    ? "rgba(255, 121, 0, 0.12)"
                    : "rgba(255, 0, 0, 0.12)",
              }}
            >
              <Box
                sx={{ width: "60px", height: "60px" }}
                display="flex"
                justifyContent={"center"}
                alignItems={"center"}
              >
                {paymentIcon(status)}
              </Box>
            </Box>
          </Box>
          <Typography
            sx={{
              color: "#474747",
              fontFamily: "poppins",
              fontWeight: "400",
              fontSize: "14px",
            }}
          >
            {paymentTitle(status)}
          </Typography>
          <Box
            display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <RobotoTypo sx={{ zIndex: 2, fontSize: "1.4rem" }}>
              {applicationData?.StudentDetails?.payment_details?.currency}
            </RobotoTypo>{" "}
            <RobotoTypo
              sx={{
                fontSize: "2.5rem",
                fontWeight: 700,
                zIndex: 3,
                background: "linear-gradient(90deg, #23074d 0%, #cc5333 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {" "}
              {applicationData?.StudentDetails?.payment_details?.amount /
                100}{" "}
            </RobotoTypo>{" "}
          </Box>
          {status === "successful" && (
            <Button variant="contained" onClick={downloadInvoice}>
              Download Invoice
            </Button>
          )}
        </Stack>
      );
    }
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "", gap: 2 }}>
      <Card sx={{ p: 0, m: 0, width: "75%" }}>
        <CardContent
          sx={{
            p: 0,
            m: 0,
            border: "1px solid #e5e5e5",
            overflowY: "auto",
            height: "100vh",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          <Box
            sx={{
              p: 2,
              borderBottom: "1px solid #e5e5e5",
              display: "flex",
              alignItems: "center",
              gap: 3,
              justifyContent: "flex-start",
            }}
          >
            <ArticleOutlinedIcon sx={{ color: "#177D84" }} />
            <RobotoTypo
              sx={{ fontWeight: 700, fontSize: "1.2rem", color: "#177D84" }}
            >
              Personal Details
            </RobotoTypo>
          </Box>
          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.student_name", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails
                  ?.student_name || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.dob", { returnObjects: true })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails?.dob || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.nationality", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails
                  ?.nationality || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.email", { returnObjects: true })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails?.email || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.documents_held", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails
                  ?.documents_held || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.passport_no", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails
                  ?.passport_number || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.have_you_studied", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails
                  ?.have_you_studied || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.selected_school", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails
                  ?.selected_school || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.academic_level", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails
                  ?.academic_level || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.course_nature", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails
                  ?.course_nature || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.courses_enrolled", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails
                  ?.courses_enrolled || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.mobile_phone_number", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails
                  ?.mobile_number || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.birth_certificate_number", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails
                  ?.birth_certificateNo || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.student_title", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails
                  ?.student_title || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.marriage_status", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails
                  ?.marraige_status || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.home_address", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails
                  ?.home_address || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.singapore_address", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails
                  ?.singapore_address || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.course_start_date", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails
                  ?.course_start_date || "-"}
              </Typography>
            </Box>
          </Box>

          {/* ------------------===================-------------------=================----------------================================---------- */}
          <Box
            sx={{
              mt: 3,
              p: 2,
              borderTop: "1px solid #e5e5e5",
              borderBottom: "1px solid #e5e5e5",
              display: "flex",
              alignItems: "center",
              gap: 3,
              justifyContent: "flex-start",
            }}
          >
            <ArticleOutlinedIcon sx={{ color: "#177D84" }} />
            <RobotoTypo
              sx={{ fontWeight: 700, fontSize: "1.2rem", color: "#177D84" }}
            >
              {t("UniversityApplicationForm.emergency_contact", {
                returnObjects: true,
              })}
            </RobotoTypo>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.name", { returnObjects: true })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails
                  ?.emergency_contact?.name || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.relation", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails
                  ?.emergency_contact?.relation || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.address_postal", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails
                  ?.emergency_contact?.addressAndPostal || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.phone_number", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.personalDetails
                  ?.emergency_contact?.phoneNumber || "-"}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              mt: 3,
              p: 2,
              borderTop: "1px solid #e5e5e5",
              borderBottom: "1px solid #e5e5e5",
              display: "flex",
              alignItems: "center",
              gap: 3,
              justifyContent: "flex-start",
            }}
          >
            <ArticleOutlinedIcon sx={{ color: "#177D84" }} />
            <RobotoTypo
              sx={{ fontWeight: 700, fontSize: "1.2rem", color: "#177D84" }}
            >
              {t(
                "UniversityApplicationForm.family_information.father_info_title",
                { returnObjects: true }
              )}
            </RobotoTypo>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.family_information.name", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.fatherDetails
                  ?.name || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.family_information.nationality", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.fatherDetails
                  ?.nationality || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.family_information.dob", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.fatherDetails
                  ?.dob || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.family_information.occupation", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.fatherDetails
                  ?.occupation || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.family_information.documents_held",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.fatherDetails
                  ?.documents_held || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}></Box>
          </Box>

          <Box
            sx={{
              mt: 3,
              p: 2,
              borderTop: "1px solid #e5e5e5",
              borderBottom: "1px solid #e5e5e5",
              display: "flex",
              alignItems: "center",
              gap: 3,
              justifyContent: "flex-start",
            }}
          >
            <ArticleOutlinedIcon sx={{ color: "#177D84" }} />
            <RobotoTypo
              sx={{ fontWeight: 700, fontSize: "1.2rem", color: "#177D84" }}
            >
              {t(
                "UniversityApplicationForm.family_information.mother_info_title",
                { returnObjects: true }
              )}
            </RobotoTypo>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.family_information.name", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.motherDetails
                  ?.name || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.family_information.nationality", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.motherDetails
                  ?.nationality || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.family_information.dob", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.motherDetails
                  ?.dob || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.family_information.occupation", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.motherDetails
                  ?.occupation || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.family_information.documents_held",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.motherDetails
                  ?.documents_held || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}></Box>
          </Box>

          <Box
            sx={{
              mt: 3,
              p: 2,
              borderTop: "1px solid #e5e5e5",
              borderBottom: "1px solid #e5e5e5",
              display: "flex",
              alignItems: "center",
              gap: 3,
              justifyContent: "flex-start",
            }}
          >
            <ArticleOutlinedIcon sx={{ color: "#177D84" }} />
            <RobotoTypo
              sx={{ fontWeight: 700, fontSize: "1.2rem", color: "#177D84" }}
            >
              {t(
                "UniversityApplicationForm.spouse_information.spouse_information_title",
                { returnObjects: true }
              )}
            </RobotoTypo>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.spouse_information.name", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.spouseDetils
                  ?.name || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.spouse_information.marriage_regno",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.spouseDetils
                  ?.marraige_Regno || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.spouse_information.marriage_date",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.spouseDetils
                  ?.marraige_date || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.spouse_information.divorce_date",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.spouseDetils
                  ?.divorce_date || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.spouse_information.divorce_number",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.spouseDetils
                  ?.divorce_number || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}></Box>
          </Box>

          <Box
            sx={{
              mt: 3,
              p: 2,
              borderTop: "1px solid #e5e5e5",
              borderBottom: "1px solid #e5e5e5",
              display: "flex",
              alignItems: "center",
              gap: 3,
              justifyContent: "flex-start",
            }}
          >
            <ArticleOutlinedIcon sx={{ color: "#177D84" }} />
            <RobotoTypo
              sx={{ fontWeight: 700, fontSize: "1.2rem", color: "#177D84" }}
            >
              {t(
                "UniversityApplicationForm.sibling_information.sibling_information_title",
                { returnObjects: true }
              )}
            </RobotoTypo>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.sibling_information.name", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.sibilingDetails
                  ?.name || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.sibling_information.relationship",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.sibilingDetails
                  ?.natirelationshiponality || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.sibling_information.dob", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.sibilingDetails
                  ?.dob || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.sibling_information.nationality",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.sibilingDetails
                  ?.nationality || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.sibling_information.occupation", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.sibilingDetails
                  ?.occupation || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.sibling_information.documents_held",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.sibilingDetails
                  ?.documents_held || "-"}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              mt: 3,
              p: 2,
              borderTop: "1px solid #e5e5e5",
              borderBottom: "1px solid #e5e5e5",
              display: "flex",
              alignItems: "center",
              gap: 3,
              justifyContent: "flex-start",
            }}
          >
            <ArticleOutlinedIcon sx={{ color: "#177D84" }} />
            <RobotoTypo
              sx={{ fontWeight: 700, fontSize: "1.2rem", color: "#177D84" }}
            >
              {t(
                "UniversityApplicationForm.funding_information.funding_information_title",
                { returnObjects: true }
              )}
            </RobotoTypo>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.funding_information.applicant", {
                  returnObjects: true,
                })}{" "}
                -{" "}
                {t(
                  "UniversityApplicationForm.funding_information.monthly_income",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.fundingDetails
                  ?.applicant_income || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.funding_information.applicant", {
                  returnObjects: true,
                })}{" "}
                -{" "}
                {t("UniversityApplicationForm.funding_information.deposits", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.fundingDetails
                  ?.applicant_deposits || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.funding_information.father", {
                  returnObjects: true,
                })}{" "}
                -{" "}
                {t(
                  "UniversityApplicationForm.funding_information.monthly_income",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.fundingDetails
                  ?.father_income || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.funding_information.father", {
                  returnObjects: true,
                })}{" "}
                -{" "}
                {t("UniversityApplicationForm.funding_information.deposits", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.fundingDetails
                  ?.father_deposits || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.funding_information.mother", {
                  returnObjects: true,
                })}{" "}
                -{" "}
                {t(
                  "UniversityApplicationForm.funding_information.monthly_income",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.fundingDetails
                  ?.mother_income || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.funding_information.mother", {
                  returnObjects: true,
                })}{" "}
                -{" "}
                {t("UniversityApplicationForm.funding_information.deposits", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.fundingDetails
                  ?.mother_deposits || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.funding_information.spouse", {
                  returnObjects: true,
                })}{" "}
                -{" "}
                {t(
                  "UniversityApplicationForm.funding_information.monthly_income",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.fundingDetails
                  ?.spouse_income || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t("UniversityApplicationForm.funding_information.spouse", {
                  returnObjects: true,
                })}{" "}
                -{" "}
                {t("UniversityApplicationForm.funding_information.deposits", {
                  returnObjects: true,
                })}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.familyDetails?.fundingDetails
                  ?.spouse_deposits || "-"}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              mt: 3,
              p: 2,
              borderTop: "1px solid #e5e5e5",
              borderBottom: "1px solid #e5e5e5",
              display: "flex",
              alignItems: "center",
              gap: 3,
              justifyContent: "flex-start",
            }}
          >
            <ArticleOutlinedIcon sx={{ color: "#177D84" }} />
            <RobotoTypo
              sx={{ fontWeight: 700, fontSize: "1.2rem", color: "#177D84" }}
            >
              {t(
                "UniversityApplicationForm.educational_background.educational_background_title",
                { returnObjects: true }
              )}
            </RobotoTypo>
          </Box>

          {applicationData?.StudentDetails?.educationalDetails.map(
            (data, i) => (
              <>
                <Box sx={{ border: "1px solid #e5e5e5", p: 2, m: 2 }}>
                  <Box sx={studentOuterBox}>
                    <Box sx={studentBox}>
                      <Typography sx={studentTitle}>
                        {t(
                          "UniversityApplicationForm.educational_background.school",
                          { returnObjects: true }
                        )}
                      </Typography>
                      <Typography sx={studentContent}>
                        {data?.school || "-"}
                      </Typography>
                    </Box>

                    <Box sx={studentBox}>
                      <Typography sx={studentTitle}>
                        {t(
                          "UniversityApplicationForm.educational_background.highest_education",
                          { returnObjects: true }
                        )}
                      </Typography>
                      <Typography sx={studentContent}>
                        {data?.highest_education || "-"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={studentOuterBox}>
                    <Box sx={studentBox}>
                      <Typography sx={studentTitle}>
                        {t(
                          "UniversityApplicationForm.educational_background.admission_date",
                          { returnObjects: true }
                        )}
                      </Typography>
                      <Typography sx={studentContent}>
                        {data?.admission_date || "-"}
                      </Typography>
                    </Box>

                    <Box sx={studentBox}>
                      <Typography sx={studentTitle}>
                        {t(
                          "UniversityApplicationForm.educational_background.completion_date",
                          { returnObjects: true }
                        )}
                      </Typography>
                      <Typography sx={studentContent}>
                        {data?.completion_date || "-"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={studentOuterBox}>
                    <Box sx={studentBox}>
                      <Typography sx={studentTitle}>
                        {t(
                          "UniversityApplicationForm.educational_background.finished_condition",
                          { returnObjects: true }
                        )}
                      </Typography>
                      <Typography sx={studentContent}>
                        {data?.finished_condition || "-"}
                      </Typography>
                    </Box>

                    <Box sx={studentBox}>
                      <Typography sx={studentTitle}>
                        {t(
                          "UniversityApplicationForm.educational_background.course_nature",
                          { returnObjects: true }
                        )}
                      </Typography>
                      <Typography sx={studentContent}>
                        {data?.course_nature || "-"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={studentOuterBox}>
                    <Box sx={studentBox}>
                      <Typography sx={studentTitle}>
                        {t(
                          "UniversityApplicationForm.educational_background.language",
                          { returnObjects: true }
                        )}
                      </Typography>
                      <Typography sx={studentContent}>
                        {data?.language || "-"}
                      </Typography>
                    </Box>

                    <Box sx={studentBox}>
                      <Typography sx={studentTitle}>
                        {t(
                          "UniversityApplicationForm.educational_background.nation",
                          { returnObjects: true }
                        )}
                      </Typography>
                      <Typography sx={studentContent}>
                        {data?.nation || "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            )
          )}

          <Box
            sx={{
              mt: 3,
              p: 2,
              borderTop: "1px solid #e5e5e5",
              borderBottom: "1px solid #e5e5e5",
              display: "flex",
              alignItems: "center",
              gap: 3,
              justifyContent: "flex-start",
            }}
          >
            <ArticleOutlinedIcon sx={{ color: "#177D84" }} />
            <RobotoTypo
              sx={{ fontWeight: 700, fontSize: "1.2rem", color: "#177D84" }}
            >
              Relavant Details
            </RobotoTypo>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.educational_background.gpa_score",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.otherDetails?.gpa_score ||
                  "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.educational_background.provide_grade",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.otherDetails?.provide_grade ||
                  "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.educational_background.ielts_test",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.otherDetails?.ielts_test ||
                  "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.educational_background.provide_ielts",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.otherDetails?.score || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.educational_background.singapore_address",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.otherDetails
                  ?.singapore_address || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.educational_background.residential_address",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.otherDetails
                  ?.residential_address || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.educational_background.checkin_time",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.otherDetails?.checkin_time ||
                  "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.educational_background.departure_time",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.otherDetails
                  ?.departure_time || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.educational_background.refused_country",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.otherDetails
                  ?.refused_country || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.educational_background.banned_entry",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.otherDetails?.banned_entry ||
                  "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={studentOuterBox}>
            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.educational_background.other_passport",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.otherDetails
                  ?.other_passport || "-"}
              </Typography>
            </Box>

            <Box sx={studentBox}>
              <Typography sx={studentTitle}>
                {t(
                  "UniversityApplicationForm.educational_background.provide_ielts",
                  { returnObjects: true }
                )}
              </Typography>
              <Typography sx={studentContent}>
                {applicationData?.StudentDetails?.otherDetails
                  ?.reason_other_passport || "-"}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              mt: 3,
              p: 2,
              borderTop: "1px solid #e5e5e5",
              borderBottom: "1px solid #e5e5e5",
              display: "flex",
              alignItems: "center",
              gap: 3,
              justifyContent: "flex-start",
            }}
          >
            <ArticleOutlinedIcon sx={{ color: "#177D84" }} />
            <RobotoTypo
              sx={{ fontWeight: 700, fontSize: "1.2rem", color: "#177D84" }}
            >
              {t(
                "UniversityApplicationForm.academic_background.academic_background_title",
                { returnObjects: true }
              )}
            </RobotoTypo>
          </Box>

          {applicationData?.StudentDetails?.academicDetails.map((data, i) => (
            <>
              <Box sx={{ border: "1px solid #e5e5e5", p: 2, m: 2 }}>
                <Box sx={studentOuterBox}>
                  <Box sx={studentBox}>
                    <Typography sx={studentTitle}>
                      {t(
                        "UniversityApplicationForm.academic_background.graduation_school",
                        { returnObjects: true }
                      )}
                    </Typography>
                    <Typography sx={studentContent}>
                      {data?.graduation_school || "-"}
                    </Typography>
                  </Box>

                  <Box sx={studentBox}>
                    <Typography sx={studentTitle}>
                      {t(
                        "UniversityApplicationForm.academic_background.major",
                        { returnObjects: true }
                      )}
                    </Typography>
                    <Typography sx={studentContent}>
                      {data?.major || "-"}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={studentOuterBox}>
                  <Box sx={studentBox}>
                    <Typography sx={studentTitle}>
                      {t(
                        "UniversityApplicationForm.academic_background.admission_time",
                        { returnObjects: true }
                      )}
                    </Typography>
                    <Typography sx={studentContent}>
                      {data?.admission_time || "-"}
                    </Typography>
                  </Box>

                  <Box sx={studentBox}>
                    <Typography sx={studentTitle}>
                      {t(
                        "UniversityApplicationForm.academic_background.graduation_time",
                        { returnObjects: true }
                      )}
                    </Typography>
                    <Typography sx={studentContent}>
                      {data?.graduation_time || "-"}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={studentOuterBox}>
                  <Box sx={studentBox}>
                    <Typography sx={studentTitle}>
                      {t(
                        "UniversityApplicationForm.academic_background.academic_certificate_number",
                        { returnObjects: true }
                      )}
                    </Typography>
                    <Typography sx={studentContent}>
                      {data?.academic_certificateNo || "-"}
                    </Typography>
                  </Box>

                  <Box sx={studentBox}>
                    <Typography sx={studentTitle}>
                      {t(
                        "UniversityApplicationForm.academic_background.highest_education",
                        { returnObjects: true }
                      )}
                    </Typography>
                    <Typography sx={studentContent}>
                      {data?.highest_education || "-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </>
          ))}

          <Box
            sx={{
              mt: 3,
              p: 2,
              borderTop: "1px solid #e5e5e5",
              borderBottom: "1px solid #e5e5e5",
              display: "flex",
              alignItems: "center",
              gap: 3,
              justifyContent: "flex-start",
            }}
          >
            <ArticleOutlinedIcon sx={{ color: "#177D84" }} />
            <RobotoTypo
              sx={{ fontWeight: 700, fontSize: "1.2rem", color: "#177D84" }}
            >
              {t(
                "UniversityApplicationForm.work_experience.work_experience_title",
                { returnObjects: true }
              )}
            </RobotoTypo>
          </Box>

          {applicationData?.StudentDetails?.workExperince.map((data, i) => (
            <>
              <Box sx={{ border: "1px solid #e5e5e5", p: 2, m: 2 }}>
                <Box sx={studentOuterBox}>
                  <Box sx={studentBox}>
                    <Typography sx={studentTitle}>
                      {t(
                        "UniversityApplicationForm.work_experience.company_name",
                        { returnObjects: true }
                      )}
                    </Typography>
                    <Typography sx={studentContent}>
                      {data?.company_name || "-"}
                    </Typography>
                  </Box>

                  <Box sx={studentBox}>
                    <Typography sx={studentTitle}>
                      {t("UniversityApplicationForm.work_experience.nation", {
                        returnObjects: true,
                      })}
                    </Typography>
                    <Typography sx={studentContent}>
                      {data?.nation || "-"}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={studentOuterBox}>
                  <Box sx={studentBox}>
                    <Typography sx={studentTitle}>
                      {t("UniversityApplicationForm.work_experience.position", {
                        returnObjects: true,
                      })}
                    </Typography>
                    <Typography sx={studentContent}>
                      {data?.position || "-"}
                    </Typography>
                  </Box>

                  <Box sx={studentBox}>
                    <Typography sx={studentTitle}>
                      {t(
                        "UniversityApplicationForm.work_experience.entry_time",
                        { returnObjects: true }
                      )}
                    </Typography>
                    <Typography sx={studentContent}>
                      {data?.entry_time || "-"}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={studentOuterBox}>
                  <Box sx={studentBox}>
                    <Typography sx={studentTitle}>
                      {t(
                        "UniversityApplicationForm.work_experience.resignation_time",
                        { returnObjects: true }
                      )}
                    </Typography>
                    <Typography sx={studentContent}>
                      {data?.resignation_time || "-"}
                    </Typography>
                  </Box>

                  <Box sx={studentBox}></Box>
                </Box>
              </Box>
            </>
          ))}
        </CardContent>
      </Card>
      {/* linear-gradient(30deg, #167d84 0%, #38ced9 100%) */}
      <Card
        sx={{
          width: "36%",
          background: "",
          p: 0,
          m: 0,
          border: "1px solid #e5e5e5",
        }}
      >
        <CardContent
          sx={{
            p: 0,
            m: 0,
            width: "100%",
            height: "100% important",
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
          }}
        >
          {applicationData?.StudentDetails?.payment_details ? (
            <>
              {" "}
              {generatePaymentStatus(
                applicationData?.StudentDetails?.payment_details?.status,
                applicationData
              )}{" "}
            </>
          ) : (
            <>
              {" "}
              <>
                <Stack
                  sx={{
                    padding: "18px 30px",
                    backgroundColor: "#e1e1e1",
                    mb: "30px",
                  }}
                >
                  <RobotoTypo
                    sx={{
                      fontWeight: "500",
                      color: "#003B5C",
                      fontSize: "20px",
                      textAlign: "left",
                    }}
                  >
                    Application Fee
                  </RobotoTypo>
                </Stack>
                <Stack px={"40px"} gap="30px">
                  <Box className="table">
                    <table>
                      <tbody>
                        {applicationData?.StudentDetails?.services_selected.map(
                          (row, index) => (
                            <tr key={index}>
                              <td>
                                <RobotoTypo sx={{ fontSize: "16px" }}>
                                  {row?.title_en}
                                </RobotoTypo>
                              </td>
                              <td>
                                <RobotoTypo sx={{ fontSize: "16px" }}>
                                  $ {isNaN(row?.amount_en) ? 0 : row?.amount_en}
                                </RobotoTypo>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </Box>
                </Stack>
              </>{" "}
              {
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    py: "40px",
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    Ready to Pay?
                  </Typography>
                  <Typography variant="body1" textAlign="center" gutterBottom>
                    Click the button below to complete your payment.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ mt: 2 }}
                    disabled={loading}
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Pay Now SGD{" "}
                    {applicationData?.StudentDetails?.application_fee}
                  </Button>
                </Box>
              }
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

export default UniversityView;

import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import CertificationHeader from '../components/Service/CertificationQualification/CertificationHeader';
import CertificationInfo from '../components/Service/CertificationQualification/CertificationInfo';
import CertificationCards from '../components/Service/CertificationQualification/CertificationCards';
import SideOverlap from './sideOverlap';
import Share from '../components/Share';

export default function CertificationOfQualification() {
    return (
        <>
            <Header/>
            <CertificationHeader />
            <CertificationInfo />
            <CertificationCards />
            <Share />
            <Footer />
            <SideOverlap/>
        </>
    )
}
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import DormitoryHeader from "../components/Service/Dormitory/DormitoryHeader";
import DormitoryInfo from "../components/Service/Dormitory/DormitoryInfo";
import DormitoryView from "../components/Service/Dormitory/DormitoryView";
import DormitoryService from "../components/Service/Dormitory/DomitoryService";
import DormitoryAccess from "../components/Service/Dormitory/DormitoryAccess";
import DormitoryLocation from "../components/Service/Dormitory/DormitoryLocation";
import Share from "../components/Share";
import SideOverlap from "./sideOverlap";

export default function Dormitory() {
  return (
    <>
      <Header />
      <DormitoryHeader />
      <DormitoryInfo />
      <DormitoryView />
      <DormitoryService />
      <DormitoryAccess />
      <DormitoryLocation />
      <Share />
      <Footer />
      <SideOverlap />
    </>
  );
}

import { Outlet, Navigate } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import { zhTW } from "date-fns/locale";

export const PrivateRoutes = () => {
  let roleName = localStorage.getItem("role_name");
  console.log("roleName ", roleName);
  return roleName == "Admin" ? <Outlet /> : <Navigate to="/admin/login" />;
};

export const PrivateRoute = () => {
  let roleName = localStorage.getItem("role_name");
  console.log("roleName ", roleName);
  return roleName == "Admin" ? <Navigate to="/admin/carousel" /> : <Outlet />;
};

export const LoginRoute = () => {
  let studentToken = localStorage.getItem("student_token");
  console.log("studentToken ", studentToken);
  return studentToken ? <Navigate to="/" /> : <Outlet />;
};

export function getId(url) {
  var regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  var match = url.match(regExp);

  if (match && match[2].length == 11) {
    return match[2];
  } else {
    return "error";
  }
}

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


 export  const formatToTraditionalChinese = (date) => {
    return format(date, 'PPPPpppp', { locale: zhTW });
  };

 export const extractVideoIdAndType = (url) => {
  if (typeof url !== 'string') {
    console.error('Invalid URL:', url);
    return { videoId: null, type: 'invalid' };
  }

  let videoId = null;
  let type = null;

  // Match YouTube Shorts URL
  const shortsMatch = url.match(/(?:shorts\/|\/)([a-zA-Z0-9_-]{11})/);
  if (shortsMatch) {
    videoId = shortsMatch[1];
    type = 'shorts';
  } else {
    // Match regular YouTube video URL
    const videoMatch = url.match(/(?:watch\?v=|\/v\/|embed\/|\/)([a-zA-Z0-9_-]{11})/);
    if (videoMatch) {
      videoId = videoMatch[1];
      type = 'normal';
    } else {
      type = 'invalid';
    }
  }

  return { videoId, type };
};

import { Box, Typography } from '@mui/material';
import React from 'react';
import { BaskerTypo, RobotoTypo } from './Typographies';

const Logo = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        p: 1,
        px: 2,
        alignItems: 'center',
        height: '80px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          color: 'white',
          alignItems: 'center',
        }}
      >
        <BaskerTypo
          sx={{
            fontSize: { xs: '24px', md: '65px' },
            fontWeight: { xs: 600, md: 400 },
            lineHeight: 1,
          }}
          variant='span'
        >
          DR
        </BaskerTypo>
        <BaskerTypo
          sx={{
            fontSize: '12px',
            lineHeight: { xs: 1.2, md: 1 },
            fontWeight: { xs: 500, md: 600 },
            // display: { xs: 'none', md: 'block' },
            width: { xs: '50px', md: 'auto' },
            textAlign: 'center',
          }}
        >
          Pacific Holdings
        </BaskerTypo>
      </Box>
      <Box
        sx={{
          height: '100%',
          display: { xs: 'none', md: 'block' },
          width: '2px',
          borderLeft: '2px solid white',
        }}
      ></Box>

      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          flexDirection: 'column',
          height: '100%',
          alignItems: 'flex-start',
          color: 'white',
          justifyContent: 'center',
        }}
      >
        <RobotoTypo>Education</RobotoTypo>
        <RobotoTypo>Consultancy</RobotoTypo>
        <RobotoTypo>Singapore</RobotoTypo>
      </Box>
    </Box>
  );
};

export default Logo;

import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import AeisExamHeader from '../components/StudyingAboard/AeisExam/AeisExamHeader';
import AeisExamInfo from '../components/StudyingAboard/AeisExam/AeisExamInfo';
import AeisExamServiceIntro from '../components/StudyingAboard/AeisExam/AeisExamServiceIntro';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';

export default function AeisExam() {
    return (
        <>
            <Header /> 
            <AeisExamHeader />
            <AeisExamInfo />
            <AeisExamServiceIntro/>
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}
import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import InternationalEndrollmentHeader from '../components/StudyingAboard/InternationalEnrollTraining/InternationalEndrollmentHeader';
import InternationalEndrollmentInfo from '../components/StudyingAboard/InternationalEnrollTraining/InternationalEndrollmentInfo';
import InternationalEndrollmentServiceIntro from '../components/StudyingAboard/InternationalEnrollTraining/InternationalEndrollmentServiceIntro';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';

export default function InternationalEnrollment() {
    return (
        <>
            <Header />
            <InternationalEndrollmentHeader />
            
            <InternationalEndrollmentServiceIntro/>
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}
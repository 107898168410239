import React from 'react'
import { Box } from '@mui/material';
import { RobotoTypo, TimesNewTypo } from '../../../utils/Typographies';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from 'react-i18next';
import { RentingConsultationImages } from '../../LandingPage/newsData';
import { makeStyles } from '@mui/styles';
import { ReactComponent as Circle } from '../../../assets/ServicesImages/StudentPassApplicationImages/circle.svg';

const useStyles = makeStyles((theme) => ({
    customCarouselContainer: {
        padding: '36px', // Set padding to 0px for the entire Carousel container
    },
}));

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 600 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};
export default function RentingCarousel() {
    const classes = useStyles();
    const { t } = useTranslation();
    const customArrowStyle = {
        position: 'absolute',
        top: '50%',
        border: 'none',
        width: '30px',
        transform: 'translateY(-50%)',
        backgroundColor: '#FFC424', // Change the background color to yellow
        color: 'white',
        padding: '8px',
        borderRadius: '50%',
        cursor: 'pointer',
        zIndex: 1, // Ensure arrows are above the images
    };
    return (
        <Box sx={{ position: 'relative', background: '#F7F7F7' }}>
            <Box
                sx={{
                    height: '45%',
                    display: { xs: 'none', md: 'none', lg: 'block', xl: 'block', xxl: 'block' },
                    width: "100%",
                    borderRight: '8px solid white',
                    borderBottom: '8px solid white',
                    borderColor: '#B1D0E0',
                    position: 'absolute',
                    top: '0',
                    right: '25%',
                    zIndex: '9',
                }}
            />
            <Box sx={{ display: 'flex', gap: '50px', flexDirection: 'column', justifyContent: 'center', margin: '0 3%' }}>

                <Box sx={{ marginTop: '50px', alignSelf: 'center', backgroundColor: '#003B5C', padding: '8px 24px', borderRadius: '50px', width: 'fit-content' }}>
                    <RobotoTypo sx={{ fontWeight: '700', fontSize: { xs: '18px', sm: '21px', md: '24px' }, minWidth: '150px', color: '#FFFFFF', letterSpacing: '0.02em', textAlign: 'center' }}>
                        {t('service.rentingconsultation.rentingCarousel', { returnObjects: true }).title}
                    </RobotoTypo>
                </Box>
                <Box sx={{ width: { xs: '80%', lg: '95%' }, display: 'flex', flexDirection: 'column', margin: '0 auto', gap: '10px', padding: '20px' }}>
                    <Box sx={{ zIndex: '10' }}>
                        <Carousel
                            className={classes.customCarouselContainer}
                            swipeable={true}
                            draggable={false}
                            showDots={false}
                            responsive={responsive}
                            ssr={true}
                            infinite={true}
                            autoPlay={false}
                            keyBoardControl={true}
                            customTransition="transform 500ms ease-in-out"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={[""]}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                            customLeftArrow={<button style={{ ...customArrowStyle, left: "0px", zIndex: '10000' }}>&lt;</button>}
                            customRightArrow={<button style={{
                                ...customArrowStyle, right: '30px',
                            }}>&gt;</button>}
                        >

                            {RentingConsultationImages?.map((item, index) => (
                                <Box key={index} sx={{
                                    display: 'flex',
                                    width: { sm: '70%', md: '70%', lg: '75%' },
                                    gap: '15px', flexDirection: 'column',
                                    '@media (min-width: 600px)': {
                                        gap: '15px', // Adjust the gap for smaller screens
                                    },
                                }}>

                                    <img src={item.image} alt="" width='100%' height='auto' />
                                </Box>
                            ))}
                        </Carousel>
                    </Box>
                    <Box sx={{ margin: '10px', display: 'flex', alignItems: 'center', gap: '20px', alignSelf: "center" }}>
                        <Box

                        ><Circle /></Box>
                        <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '28px', color: '#242424', fontSize: { xs: '16px', md: '16px', lg: '16px' }, textAlign: 'center' }}>
                            {t('service.rentingconsultation.rentingCarousel', { returnObjects: true }).bulletText}
                        </RobotoTypo>

                    </Box>
                </Box>
            </Box>  <br />  <br />  <br />
        </Box>
    )
}

import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import LongTermPassHeader from '../components/Service/LongTermPassApplication/LongTermPassHeader';
import LongTermPassInfo from '../components/Service/LongTermPassApplication/LongTermPassInfo';
import LongTermApplicationServiceIntro from '../components/Service/LongTermPassApplication/LongTermApplicationServiceIntro';
import Share from '../components/Share';
import SideOverlap from './sideOverlap'

export default function LongTermPass() {
    return (
        <>
            <Header/>
            <LongTermPassHeader />
            <LongTermPassInfo />
            <LongTermApplicationServiceIntro />
            <Share />
            <Footer />
            <SideOverlap/>
        </>
    )
}

import React, { useState } from 'react'
import { Box, TextField, Select, FormControl, MenuItem, InputLabel,  RadioGroup, FormControlLabel, Radio, Button, useMediaQuery } from '@mui/material'
import { RobotoTypo } from '../../../utils/Typographies'
import { makeStyles } from '@mui/styles'
import UploadIcon from '@mui/icons-material/Upload';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
    TextField: {
        "& .MuiInputBase-input": { padding: '10px 10px', borderRadius: '5px', backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset' },
        "& fieldset": { border: "none", }
    },

    selectIcon1: {
        "& .MuiInputBase-input": { padding: '10px 10px', borderRadius: '5px', backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset' },
        "& fieldset": { border: "none", },
        '& .MuiSvgIcon-root.MuiSelect-icon': {
            color: '#177D84', // Set your desired color here
        },
    },
    selectIcon: {
        '& .MuiRadio-root': {
            color: 'transparent',
        },
        // '& .MuiRadio-root.Mui-checked': {
        //     color: "red"
        // }
    },
    label6: {
        "& .MuiFormControlLabel-label": {
            marginRight: "-40px"
        }
    },
    label9: {
        "& .MuiFormControlLabel-label": {
            marginRight: "-20px"
        }
    },
    label11: {
        "& .MuiFormControlLabel-label": {
            marginRight: "-18px"
        }
    },
    label15: {
        "& .MuiFormControlLabel-label": {
            marginRight: "-36px"
        }
    },

    radio: {
        '&$checked': {
            color: 'red',
        },
        '& .MuiIconButton-root': {
            padding: '10px',
            borderRadius: '50%',
            backgroundColor: 'white',
            boxShadow: '2px 2px 4px 0px #00000040 inset',
            border: 'none',
        },
        '& .MuiIconButton-root': {
            padding: '10px',
            borderRadius: '50%',
            backgroundColor: 'white',
            boxShadow: '2px 2px 4px 0px #00000040 inset',
            border: 'none',
        },

    },
    radios: {
        '&$checked': {
            color: 'red', // Set the color when the radio button is checked here
        },
    },
    
}));


export default function AcademicAssesmentForm() {
    const [qualification, setQualification] = React.useState('');
    const classes = useStyles();
    const { t } = useTranslation();
    const isExtraSmallScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));

    const handleAgeChange = (event) => {
        setQualification(event.target.value);
    };


    const ageOptions = ['SSLC', 'HSC', 'BACHELOR DEGREE', 'MASTER DEGREE']

    return (
        <Box sx={{ padding: {xs:'4% 6%',sm:'4%'}, backgroundColor: '#F7F7F7' }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                // gap: '30px',
            }}>
                <Box sx={{display:'flex', gap:'20px', position:'relative'}}>
                    <Box sx={{minWidth:'80px', display: {xs:'none', sm:'flex'}, alignItems: 'center', background: 'white', justifyContent: 'center', border: '2px solid #177D84', width: '80px', height: '80px', borderRadius: '50px' }}>
                        <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '20px', sm: '24px', md: '32px' } }}>01</RobotoTypo>
                        <Box sx={{position:'absolute', border:'1px solid #177d84', height:'83.5%', bottom:0}}/>
                    </Box>
                    <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'30px', mb:4}}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('AcademicAssesment.form', { returnObjects: true }).title1} 
                        </RobotoTypo>
                        <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' } }}>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                 {t('AcademicAssesment.form', { returnObjects: true }).onefield1} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box>
                                </RobotoTypo>
                                <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none', outline: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                            </Box>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                 {t('AcademicAssesment.form', { returnObjects: true }).onefield2} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box>
                                </RobotoTypo>
                                <FormControl fullWidth>
                                <Select
                                    sx={{ marginTop: "10px", }}
                                    className={classes.selectIcon1}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={qualification}
                                    onChange={handleAgeChange}
                                >
                                    {ageOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                                {/* <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} /> */}

                            </Box>
                        </Box>
                     
               
                        <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' } }}>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                 {t('AcademicAssesment.form', { returnObjects: true }).onefield3} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box>
                                </RobotoTypo>
                                <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                            </Box>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                 {t('AcademicAssesment.form', { returnObjects: true }).onefield4} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box>
                                </RobotoTypo>
                                <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' } }}>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                {t('AcademicAssesment.form', { returnObjects: true }).onefield5} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> 
                                </RobotoTypo>
                                <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                            </Box>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                 {t('AcademicAssesment.form', { returnObjects: true }).onefield6} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> 
                                </RobotoTypo>
                                <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row',width:'100%' } }}>
                            <Box sx={{display:'flex', flexDirection:{xs:'column', sm:'row'}, gap:'10px', width:{xs:'100%', sm:'49%'}}}>
                                <Box sx={{ flex: '1', width:{xs:'100%', sm:'50%'} }}>
                                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                    {t('AcademicAssesment.form', { returnObjects: true }).onefield7} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> 
                                    </RobotoTypo>
                                    <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                                </Box>
                                <Box sx={{ flex: '1', width:{xs:'100%', sm:'50%'}  }}>
                                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                    {t('AcademicAssesment.form', { returnObjects: true }).onefield8} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> 
                                    </RobotoTypo>
                                    <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                                </Box>
                                
                            </Box>
                            <Box sx={{ flex: '1', width:{xs:'100%', sm:'50%'}  }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                {t('AcademicAssesment.form', { returnObjects: true }).onefield9} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box>
                                </RobotoTypo>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#177d84', fontSize: '15px', textAlign: 'left' }}>
                                 {t('AcademicAssesment.form', { returnObjects: true }).onefield9Info} 
                                </RobotoTypo>
                                <input
                                    type="file"
                                    accept="*/*"
                                    style={{ display: 'none' }}
                                    id="image-input1"
                                />
                                <label htmlFor="image-input1">
                                    <Button startIcon={<UploadIcon sx={{color:'gray'}}/>} component="span" variant="outlined" sx={{mt:1,textTransform:'none', border:'1px solid gray',color:'grey',backgroundColor:'transparent','&:hover': {backgroundColor: 'transparent',border:'1px solid gray'},}}>
                                    Upload
                                    </Button>
                                </label>
                            </Box>
                        </Box>
                    </Box>  
                </Box>
                <Box sx={{display:'flex', gap:'20px', position:'relative'}}>
                    <Box sx={{minWidth:'80px',display: {xs:'none', sm:'flex'}, alignItems: 'center', background: 'white', justifyContent: 'center', border: '2px solid #177D84', width: '80px', height: '80px', borderRadius: '50px' }}>
                        <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '20px', sm: '24px', md: '32px' } }}>02</RobotoTypo>
                        <Box sx={{position:'absolute', border:'1px solid #177d84', height:'86%', bottom:0}}/>
                    </Box>

                    <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'30px', my:2}}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('AcademicAssesment.form', { returnObjects: true }).title2} 
                        </RobotoTypo>
                        <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' } }}>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                 {t('AcademicAssesment.form', { returnObjects: true }).twofield1} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box>
                                </RobotoTypo>
                                <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                            </Box>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                {t('AcademicAssesment.form', { returnObjects: true }).twofield2} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box>
                                </RobotoTypo>
                                <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' } }}>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                {t('AcademicAssesment.form', { returnObjects: true }).twofield3} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> 
                                </RobotoTypo>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#177d84', fontSize: '15px', textAlign: 'left' }}>
                                {t('AcademicAssesment.form', { returnObjects: true }).twofield3Info} 
                                </RobotoTypo>
                                <input
                                    type="file"
                                    accept="*/*"
                                    style={{ display: 'none' }}
                                    id="image-input2"
                                />
                                <label htmlFor="image-input2">
                                    <Button startIcon={<UploadIcon sx={{color:'gray'}}/>} component="span" variant="outlined" sx={{mt:1,textTransform:'none', border:'1px solid gray',color:'grey',backgroundColor:'transparent','&:hover': {backgroundColor: 'transparent',border:'1px solid gray'},}}>
                                    Upload
                                    </Button>
                                </label>
                            </Box>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                               {t('AcademicAssesment.form', { returnObjects: true }).twofield4}  <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> 
                                </RobotoTypo>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#177d84', fontSize: '15px', textAlign: 'left' }}>
                                {t('AcademicAssesment.form', { returnObjects: true }).twofield4Info} 
                                </RobotoTypo>
                                <input
                                    type="file"
                                    accept="*/*"
                                    style={{ display: 'none' }}
                                    id="image-input3"
                                />
                                <label htmlFor="image-input3">
                                    <Button startIcon={<UploadIcon sx={{color:'gray'}}/>} component="span" variant="outlined" sx={{mt:1,textTransform:'none', border:'1px solid gray',color:'grey',backgroundColor:'transparent','&:hover': {backgroundColor: 'transparent',border:'1px solid gray'},}}>
                                    Upload
                                    </Button>
                                </label>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '30px', flexDirection: { xs: 'column', sm: 'row' } }}>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                {t('AcademicAssesment.form', { returnObjects: true }).twofield5} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> 
                                </RobotoTypo>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#177d84', fontSize: '15px', textAlign: 'left' }}>
                                {t('AcademicAssesment.form', { returnObjects: true }).twofield5Info} 
                                </RobotoTypo>
                                <input
                                    type="file"
                                    accept="*/*"
                                    style={{ display: 'none' }}
                                    id="image-input4"
                                />
                                <label htmlFor="image-input4">
                                    <Button startIcon={<UploadIcon sx={{color:'gray'}}/>} component="span" variant="outlined" sx={{mt:1,textTransform:'none', border:'1px solid gray',color:'grey',backgroundColor:'transparent','&:hover': {backgroundColor: 'transparent',border:'1px solid gray'},}}>
                                    Upload
                                    </Button>
                                </label>
                            </Box>
                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>
                                {t('AcademicAssesment.form', { returnObjects: true }).twofield6}  <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box>
                                </RobotoTypo>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#177d84', fontSize: '15px', textAlign: 'left' }}>
                                {t('AcademicAssesment.form', { returnObjects: true }).twofield6Info} 
                                </RobotoTypo>
                                {/* <TextField className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} /> */}
                                <input
                                    type="file"
                                    accept="*/*"
                                    style={{ display: 'none' }}
                                    id="image-input5"
                                />
                                <label htmlFor="image-input5">
                                    <Button startIcon={<UploadIcon sx={{color:'gray'}}/>} component="span" variant="outlined" sx={{mt:1,textTransform:'none', border:'1px solid gray',color:'grey',backgroundColor:'transparent','&:hover': {backgroundColor: 'transparent',border:'1px solid gray'},}}>
                                    Upload
                                    </Button>
                                </label>
                            </Box>
                        </Box>
                        <Box sx={{ flex: '1', }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('AcademicAssesment.form', { returnObjects: true }).twofield7} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>* </Box> 
                            </RobotoTypo>
                            <TextField
                                className={classes.TextField}
                                // variant="outlined"
                                multiline
                                rows={4}
                                InputProps={{ style: { border: 'none', height: '100px', padding: '10px 0' } }}
                                sx={{ flex: '1', width: '100%', marginTop: "10px", padding: '10px 0' }}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ textAlign: 'center' }}>
                    <Button disableElevation variant='contained' sx={{
                        mt: 5, borderRadius: '50px', textAlign: 'center', backgroundColor: '#FFC424',
                        '&:hover': {
                            backgroundColor: '#FFC424',
                        },
                    }} >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px', padding: '6px 5px' }}>
                            <RobotoTypo sx={{ color: '#FFFFFF', letterSpacing: '0.02em', textTransform: 'none', lineHeight: '32px', fontWeight: '700', fontSize: { xs: 18, sm: 18, md: 21, lg: 21 } }}>{t('AcademicAssesment.form', { returnObjects: true }).submitBtn}</RobotoTypo>
                        </Box>
                    </Button>
                </Box>

            </Box>
        </Box >
    )
}

import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import RendingHeader from "../components/Service/RentingConsultation/RendingHeader";
import RentingInfo from "../components/Service/RentingConsultation/RentingInfo";
import RentingService from "../components/Service/RentingConsultation/RentingService";
import RentingCarousel from "../components/Service/RentingConsultation/RentingCarousel";
import RentingForm from "../components/Service/RentingConsultation/RentingForm";
import Share from "../components/Share";
import SideOverlap from "./sideOverlap";

export default function RentingConsultation() {
  return (
    <>
      <Header />
      <RendingHeader />

      <RentingService />
      <RentingCarousel />
      <RentingForm />
      <Share />
      <Footer />
      <SideOverlap />
    </>
  );
}

import React from 'react'
import { Box } from '@mui/material';
import { ReactComponent as Arrows } from '../../../assets/ServicesImages/StudentPassApplicationImages/arrow.svg';


export default function Arrowline() {
    return (
        <>
            {/* <Box sx={{ position: 'relative', top: '135px' }}> */}


            {/* </Box> */}
            <Box
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block', xxl: 'block' },
                    height: { lg: '49%', xl: '47%' },
                    width: { lg: '81%', xl: '78%' },
                    borderRight: 'hidden !important',
                    borderBottom: '3px solid #B1D0E0',
                    borderTop: 'hidden !important',
                    // borderBottomWidth: '150%',
                    borderLeft: '3px solid #B1D0E0',
                    borderStyle: 'dashed',
                    borderColor: '#B1D0E0',
                    position: 'absolute',
                    top: { lg: '-36px', xl: '-35px' },
                    right: { lg: '15%', xl: '19.5%' },
                    zIndex: '99',
                }}
            />
            <Box
                sx={{

                    display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block', xxl: 'block' },


                    position: 'absolute',
                    left: { lg: '3.7%', xl: '2.1%' },
                    zIndex: '99',
                    transform: 'rotate(270deg)'
                }}
            ><Arrows /></Box>
            {/* <Box
                sx={{
                    height: '200px',
                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block', xxl: 'block' },
                    height: { lg: '48%', xl: '45%' },
                    width: { lg: '81%', xl: '78%' },
                    borderRight: 'hidden !important',
                    borderBottom: '3px solid #B1D0E0',
                    borderTop: 'hidden !important',

                    // borderBottomWidth: '150%',
                    borderLeft: 'hidden !important',
                    borderStyle: 'dashed',
                    borderColor: '#B1D0E0',
                    position: 'absolute',
                    top: { lg: '-28px', xl: '-35px' },
                    right: { lg: '15%', xl: '19.5%' },
                    zIndex: '99',
                }}
            /> */}
        </>
    )
}

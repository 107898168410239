import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSelector } from "react-redux";
import { RobotoTypo } from "../utils/Typographies";

const ExtraCurricularActivityTable = () => {
  const { t } = useTranslation();
  const [activitiesList, setActivitiesList] = useState([]);
  const [data, setData] = useState([]);

  const language = useSelector((state) => state.language.language);
  const getActivity = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/activities-all`
      );
      console.log("response get ", response);
      if (response.status == 200) {
        if (Array.isArray(response?.data)) {
          setActivitiesList(response.data);
          getData(response.data);
        } else {
          return;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getData = (data) => {
    console.log("language ", language);
    const languageData = data.map((item) => {
      if (language == "en") {
        return item.en;
      } else {
        return item.ch;
      }
    });
    setData(languageData);
  };
  useEffect(() => {
    getActivity();
  }, []);
  useEffect(() => {
    if (activitiesList?.length != 0) {
      getData(activitiesList);
    }
  }, [language]);
  return (
    <Box sx={{ px: "5%", py: "40px" }}>
      <RobotoTypo
        sx={{ fontWeight: 900, fontSize: "24px", my: "20px", color: "#003B5C" }}
      >
        {t("latestActivities")}
      </RobotoTypo>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
             
              <TableCell sx={{ fontSize: "16px" }}>{t("EventName")}</TableCell>
              <TableCell sx={{ fontSize: "16px" }}>{t("Date")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <TableRow key={row.id}>
               
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ExtraCurricularActivityTable;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RouteChangeHandler = () => {
  const location = useLocation();

  useEffect(() => {
    console.log('locationss ',location.pathname)
    if (location.pathname != '/login' && location.pathname != '/register' && location.pathname != '/otp') {
      console.log('Clearing localStorage');
      localStorage.removeItem('schoolType')
      localStorage.removeItem('schoolRoute')
    }
  }, [location]);

  return null;
};

export default RouteChangeHandler;

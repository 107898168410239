import React, { useEffect } from 'react'
import { Box } from '@mui/material';
import { RobotoTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import MapContainer from './MapContainer';

export default function DormitoryLocation() {

    const { t } = useTranslation();

    return (
        <Box sx={{ position: 'relative', background: '#F7F7F7',  }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ marginTop: '50px', display: 'flex', backgroundColor: '#003B5C', padding: '8px 0px', borderRadius: '50px', width: 'fit-content' }}>
                    <RobotoTypo sx={{ fontWeight: '700', fontSize: { xs: '18px', sm: '20px', md: '22px' }, minWidth: '150px', color: '#FFFFFF', letterSpacing: '0.02em', textAlign: 'center' }}>
                        {t('service.dormitory.DormitoryLocation', { returnObjects: true }).title}
                    </RobotoTypo>
                </Box>
            </Box><br /><br />
            
            <MapContainer />

        </Box >
    )
}

import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PropertyHeader from '../components/Service/PropertyPurchase/PropertyHeader';
import PropertyInfo from '../components/Service/PropertyPurchase/PropertyInfo';
import Share from '../components/Share';
import SideOverlap from './sideOverlap'

export default function PropertyPurchase() {
    return (
        <>
            <Header/>
            <PropertyHeader />
            <PropertyInfo />
            <Share />
            <Footer />
            <SideOverlap/>
        </>
    )
}
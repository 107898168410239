import React, { useEffect, useState } from "react";
import Admin from "../Dashboard";
import { Box, Button, TextField } from "@mui/material";
import { RobotoTypo } from "../../../utils/Typographies";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import axios from "axios";
import {
  DateTimeRangePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const AddExtraCurricularActivities = () => {
  const [value, setValue] = useState([null, null]);
  const [data, setData] = useState({
    eventName_en: "",
    eventName_ch: "",
    date_en: "",
    date_ch: "",
  });
  const [dataError, setDataError] = useState({
    eventName_en: "",
    eventName_ch: "",
    date_en: "",
    date_ch: "",
  });
  let { id } = useParams();
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };
  const validate = () => {
    let newErrors = [],
      errorMessges = {};
    if (data.eventName_en == "") {
      setDataError((prev) => ({
        ...prev,
        eventName_en: "Event Name is required",
      }));
      newErrors.push(false);
    } else {
      setDataError((prev) => ({ ...prev, eventName_en: "" }));
      newErrors.push(true);
    }

    if (data.eventName_en == "") {
      setDataError((prev) => ({
        ...prev,
        eventName_ch: "Event Name is required",
      }));
      newErrors.push(false);
    } else {
      setDataError((prev) => ({ ...prev, eventName_ch: "" }));
      newErrors.push(true);
    }
    if (data.date_en == "") {
      setDataError((prev) => ({
        ...prev,
        date_en: "Date is required",
      }));
      newErrors.push(false);
    } else {
      setDataError((prev) => ({ ...prev, date_en: "" }));
      newErrors.push(true);
    }
    if (data.date_ch == "") {
      setDataError((prev) => ({
        ...prev,
        date_ch: "Date is required",
      }));
      newErrors.push(false);
    } else {
      setDataError((prev) => ({ ...prev, date_ch: "" }));
      newErrors.push(true);
    }

    let errorStatus = newErrors.includes(false);
    return !errorStatus;
  };

  console.log(data, "data");
  const addEvent = async (reqData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/activities`,
        reqData,
        {
          headers: {
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 201) {
        navigate(`/admin/ExtraCurricularActivities`);
      }
    } catch (error) {
      if (error.response.status == 403) {
        localStorage.clear();
        navigate("/admin/login");
      }
    }
  };

  const getEventById = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/activitiesById/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 200) {
        const eventData = response?.data;
        console.log("getpersonData ", eventData);
        setData({
          eventName_en: eventData?.en.title,
          eventName_ch: eventData?.ch.title,
          date_en: eventData?.en.date,
          date_ch: eventData?.ch.date,
        });
        setValue(eventData.date);
      }
    } catch (error) {
      if (error.response.status == 403) {
        localStorage.clear();
        navigate("/admin/login");
      }
    }
  };

  const updateEvent = async (reqData) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/activities-update/${id}`,
        reqData,
        {
          headers: {
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response) {
        navigate(`/admin/ExtraCurricularActivities`);
      }
    } catch (error) {
      if (error.response.status == 403) {
        localStorage.clear();
        navigate("/admin/login");
      }
    }
  };

  useEffect(() => {
    if (id) {
      getEventById(id);
    }
  }, []);
  const handleSubmit = () => {
    const validation = validate();
    if (validation) {
      const form = {
        en_title: data.eventName_en,
        ch_title: data.eventName_ch,
        en_date: data.date_en,
        ch_date: data.date_ch,
      };
      if (id) {
        updateEvent(form);
      } else {
        addEvent(form);
      }
    }
  };
  return (
    <Admin>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Box sx={{ display: "flex", gap: "50px" }}>
          <Box sx={{ width: "50%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 20, sm: 24 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              English
            </RobotoTypo>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Event Name
            </RobotoTypo>
            <TextField
              name="eventName_en"
              value={data.eventName_en}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.eventName_en != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                {dataError.eventName_en}
              </RobotoTypo>
            )}
          </Box>
          <Box sx={{ width: "50%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 24 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Chinese
            </RobotoTypo>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Event Name
            </RobotoTypo>
            <TextField
              name="eventName_ch"
              value={data.eventName_ch}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.eventName_ch != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                {dataError.eventName_ch}
              </RobotoTypo>
            )}
          </Box>
        </Box>
        <Box display="flex" sx={{ width: "100%", gap: "50px" }}>
          <Box sx={{ width: "50%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Date
            </RobotoTypo>

            <TextField
              name="date_en"
              value={data.date_en}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />

            {dataError.date_en != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                {dataError.date_en}
              </RobotoTypo>
            )}
          </Box>
          <Box sx={{ width: "50%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Date
            </RobotoTypo>

            <TextField
              name="date_ch"
              value={data.date_ch}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />

            {dataError.date_ch != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                {dataError.date_ch}
              </RobotoTypo>
            )}
          </Box>
        </Box>
        <Button
          component="span"
          onClick={handleSubmit}
          variant="contained"
          sx={{
            alignSelf: "center",
            mt: 1,
            width: "fit-content",
            backgroundColor: "#177D84",
            "&:hover": { backgroundColor: "#177D84" },
          }}
        >
          {id ? "Edit" : "Add"}
        </Button>
      </Box>
    </Admin>
  );
};

export default AddExtraCurricularActivities;

import React from 'react'
import { Box } from '@mui/material';
import { RobotoTypo, TimesNewTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Circle } from '../../../assets/ServicesImages/StudentPassApplicationImages/circle.svg';

export default function SchoolBenefit({data}) {
    console.log('data?.benefits ',data?.benefits)
    const { t } = useTranslation();
    return (
        <Box sx={{ position: 'relative', background: '#B1D0E0' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                <Box sx={{ width: '100%', display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', padding: { xs: '50px 0', md: '100px 0' } }}>
                <Box sx={{mb:4,  display:'flex', justifyContent:'center', alignItems:'center', backgroundColor: '#003B5C', padding: '8px 12px', borderRadius: '50px', width:'fit-content' }
                } >
                    <RobotoTypo sx={{ letterSpacing: '0.02em', fontWeight: '700', color: 'white', fontSize: { xs: '20px', sm: '22px', md: '22px', lg: '22px' }, textAlign: 'left' }}>
                    {t('School.SchoolBenefit', { returnObjects: true }).title}
                    </RobotoTypo><br />
                </ Box>
                    <Box sx={{
                        zIndex: '10000',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'start',
                        flexDirection: 'column',
                        gap: '20px',
                        width: {xs:'80%', md:'50%'},
                        padding: '50px 30px',
                        backgroundColor: 'white',
                        borderRadius: '50px'
                    }}>
                        {/* {t('service.studentPassApplication.studentApplicationProcessBullets.bullets', { returnObjects: true }).map((item, index) => ( */}
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                {/* <Box

                                ><Circle /></Box> */}
                                <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '16px', md: '16px', lg: '16px' }, textAlign: 'left' }}>
                                    {}
                                    {<p dangerouslySetInnerHTML={{ __html: data?.benefits }} />}
                                </RobotoTypo>

                            </Box>
                        {/* // ))} */}
                        {/* <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: '16px', textAlign: 'left' }}>
                            {t('service.studentPassApplication.studentApplicationProcessBullets', { returnObjects: true }).finalText}
                        </RobotoTypo> */}



                    </Box>
                </Box>
            </Box>
        </Box>
    )
}



import React from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import { RobotoTypo, TimesNewTypo } from "../../../utils/Typographies";
import { useTranslation } from "react-i18next";
import { ReactComponent as Circle } from "../../../assets/ServicesImages/StudentPassApplicationImages/circle.svg";
import { useNavigate } from "react-router-dom";

export default function InternationalEndrollmentServiceIntro() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box sx={{ position: "relative", background: "#B1D0E0" }}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box
          sx={{
            borderBottomRightRadius: {
              xs: "0",
              md: "0",
              lg: "0",
              xl: "50px",
              xxl: "50px",
            },
            // padding: { xs: '0', sm: "0", md: '0', lg: "40px", xl: '40px' },
            padding: "40px 0",
            zIndex: 10000,
            height: { xs: "11%", sm: "11%", md: "11%", lg: "15%", xl: "15%" },
            width: { xs: "100%", sm: "100%", md: "100%", lg: "30%", xl: "30%" },
            position: {
              xs: "relative",
              sm: "relative",
              md: "relative",
              lg: "absolute",
              xl: "absolute",
            },
            left: "0",
            top: "0",
            background: "#F5DFBA",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* {t('aboutUs.why_dr', { returnObjects: true }).map((item, index) => ( */}
          <Box
            // key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <RobotoTypo
              sx={{
                fontWeight: "700",
                color: "#003B5C",
                fontSize: { xs: "22px", sm: "25px", md: "28px", lg: "30px" },
                textAlign: "center",
              }}
            >
              <RobotoTypo
                sx={{
                  mt: 2,
                  fontWeight: "800",
                  letterSpacing: "0.03em",
                  lineHeight: "25px",
                  color: "#242424",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {
                  t("enrollmentTrainingCourseInformation.header", {
                    returnObjects: true,
                  }).title
                }
              </RobotoTypo>
            </RobotoTypo>
            <TimesNewTypo
              sx={{
                fontWeight: "700",
                color: "#177D84",
                fontSize: { xs: "18px", md: "22px", lg: "24px" },
                textAlign: "center",
              }}
            >
              {/* {t('EntrollmentTraining.serviceIntro', { returnObjects: true }).title2} */}
            </TimesNewTypo>
          </Box>
          {/* ))} */}
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: { xs: "50px 0", md: "100px 0", lg: "160px 0" },
          }}
        >
          <Box
            sx={{
              zIndex: "10000",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              flexDirection: "column",
              gap: "20px",
              width: { xs: "80%", lg: "50%" },
              padding: "50px 30px",
              backgroundColor: "white",
              borderRadius: "50px",
            }}
          >
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {/* First row */}
                  <TableRow>
                    <TableCell
                      style={{
                        backgroundColor: "#8db3e2",
                        height: 80,
                        border: "4px solid white",
                        textAlign: "center",
                      }}
                    >
                      <RobotoTypo sx={{ fontWeight: "700", color: "black" }}>
                      入学年级
                      </RobotoTypo>
                      <RobotoTypo sx={{ fontWeight: "700", color: "black" }}>
                        Level
                      </RobotoTypo>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#8db3e2",
                        height: 80,
                        border: "4px solid white",
                        textAlign: "center",
                      }}
                    >
                      <RobotoTypo sx={{ fontWeight: "700", color: "black" }}>
                      科目
                      </RobotoTypo>
                      <RobotoTypo sx={{ fontWeight: "700", color: "black" }}>
                      Subject
                      </RobotoTypo>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#8db3e2",
                        height: 80,
                        border: "4px solid white",
                        textAlign: "center",
                      }}
                    >
                      <RobotoTypo sx={{ fontWeight: "700", color: "black" }}>
                      收费
                      </RobotoTypo>
                      <RobotoTypo sx={{ fontWeight: "700", color: "black" }}>
                        Charges
                      </RobotoTypo>
                    </TableCell>
                  </TableRow>
                  {/* Second and Third row */}
                  <TableRow>
                    <TableCell
                      rowSpan={2}
                      style={{
                        backgroundColor: "#daeef3",
                        border: "4px solid white",
                        textAlign: "center",
                      }}
                    >
                      <RobotoTypo sx={{ fontWeight: "500", color: "black" }}>
                      小学-中学
                      </RobotoTypo>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#daeef3",
                        border: "4px solid white",
                        textAlign: "center",
                      }}
                    >
                      <RobotoTypo sx={{ fontWeight: "500", color: "black" }}>
                      基础培训课程 (24小时)
                      </RobotoTypo>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#daeef3",
                        border: "4px solid white",
                        textAlign: "center",
                      }}
                    >
                      <RobotoTypo sx={{ fontWeight: "500", color: "black" }}>
                      新币$3,500
                      </RobotoTypo>
                    </TableCell>
                  </TableRow>
                  {/* Fourth and Fifth row */}
                  <TableRow>
                    <TableCell
                      style={{
                        backgroundColor: "#daeef3",
                        border: "4px solid white",
                        textAlign: "center",
                      }}
                    >
                      <RobotoTypo sx={{ fontWeight: "500", color: "black" }}>
                      标准培训课程(48小时)
                      </RobotoTypo>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#daeef3",
                        border: "4px solid white",
                        textAlign: "center",
                      }}
                    >
                      <RobotoTypo sx={{ fontWeight: "500", color: "black" }}>
                      新币$6,500
                      </RobotoTypo>
                    </TableCell>
                  </TableRow>
                 
                </TableBody>
              </Table>
            </TableContainer>
            <RobotoTypo
              sx={{
                mt: 2,
                fontWeight: "800",
                letterSpacing: "0.03em",
                lineHeight: "25px",
                color: "#242424",
                fontSize: "20px",
                textAlign: "left",
              }}
            >
              {
                t(
                  "enrollmentTrainingCourseInformation.applicationRequirement",
                  { returnObjects: true }
                ).title
              }
            </RobotoTypo>
            <RobotoTypo
              sx={{
                mt: 2,
                fontWeight: "400",
                letterSpacing: "0.03em",
                lineHeight: "25px",
                color: "#242424",
                fontSize: "16px",
                textAlign: "left",
              }}
            >
              {
                t(
                  "enrollmentTrainingCourseInformation.applicationRequirement",
                  { returnObjects: true }
                ).description1
              }
            </RobotoTypo>
            <RobotoTypo
              sx={{
                fontWeight: "400",
                letterSpacing: "0.03em",
                lineHeight: "25px",
                color: "#242424",
                fontSize: "16px",
                textAlign: "left",
              }}
            >
              {
                t(
                  "enrollmentTrainingCourseInformation.applicationRequirement",
                  { returnObjects: true }
                ).description2
              }
            </RobotoTypo>
            <RobotoTypo
              sx={{
                fontWeight: "400",
                letterSpacing: "0.03em",
                lineHeight: "25px",
                color: "#242424",
                fontSize: "16px",
                textAlign: "left",
              }}
            >
              {
                t(
                  "enrollmentTrainingCourseInformation.applicationRequirement",
                  { returnObjects: true }
                ).description3
              }
            </RobotoTypo>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: { lg: "relative", xl: "absolute" },
          left: "0px",
          right: "0px",
          margin: "auto",
          bottom: "25px",
          mt: { xs: 0, xl: 6 },
          mb: { xs: 4, xl: 0 },
          padding: "0 20px 20px",
        }}
      >
       
      </Box>
    </Box>
  );
}

import React from 'react'
import { Box, Button } from '@mui/material';
import { RobotoTypo } from '../../../utils/Typographies';
import { ReactComponent as DownloadArrow } from '../../../assets/ServicesImages/DrAppImages/downloadArrow.svg';
import { ReactComponent as AppleIcon } from '../../../assets/ServicesImages/DrAppImages/AppleIcon.svg';
import { ReactComponent as PlaystoreIcon } from '../../../assets/ServicesImages/DrAppImages/PlaystoreIcon.svg';
import { ReactComponent as AppGalleryIcon } from '../../../assets/ServicesImages/DrAppImages/AppGalleryIcon.svg';

export default function DownloadOptions() {
    return (
        <Box sx={{ display: 'flex', alignSelf: 'center', gap: '30px', flexDirection: { xs: 'column', md: 'row' } }}>
            <Button sx={{ border: '1px solid #177D84', borderRadius: '17px', padding: '4px 13px', color: '#177D84', cursor: 'pointer' }} variant='oulined' endIcon={<DownloadArrow />}>Download Now</Button>
            <Box sx={{ display: 'flex', gap: '10px', flexDirection: { xs: 'column', sm: 'row' } }}>
                <Button sx={{
                    borderRadius: '5px', color: '#FFFFFF', cursor: 'pointer', backgroundColor: '#000000', padding: '4px 13px',
                    '&:hover': {
                        backgroundColor: '#000000',
                    },
                }} variant='oulined' startIcon={<AppleIcon />}><Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <RobotoTypo sx={{ textTransform: 'none', fontWeight: '400', letterSpacing: '0.03em', lineHeight: '12px', color: '#FFFFFF', fontSize: '8px', textAlign: 'left' }}>Download on the</RobotoTypo>
                        <RobotoTypo sx={{ textTransform: 'none', fontWeight: '500', letterSpacing: '0.03em', lineHeight: '12px', color: '#FFFFFF', fontSize: '14px', textAlign: 'left' }}>App Store</RobotoTypo>
                    </Box></Button>

                <Button sx={{
                    borderRadius: '5px', color: '#FFFFFF', cursor: 'pointer', backgroundColor: '#000000', padding: '4px 13px',
                    '&:hover': {
                        backgroundColor: '#000000',
                    },
                }} variant='oulined' startIcon={<PlaystoreIcon />}><Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <RobotoTypo sx={{ textTransform: 'none', fontWeight: '400', letterSpacing: '0.03em', lineHeight: '12px', color: '#FFFFFF', fontSize: '8px', textAlign: 'left' }}>ANDROID APP ON</RobotoTypo>
                        <RobotoTypo sx={{ textTransform: 'none', fontWeight: '500', letterSpacing: '0.03em', lineHeight: '12px', color: '#FFFFFF', fontSize: '14px', textAlign: 'left' }}>Google Play</RobotoTypo>
                    </Box></Button>

                <Button sx={{
                    borderRadius: '5px', color: '#FFFFFF', cursor: 'pointer', backgroundColor: '#000000', padding: '4px 13px',
                    '&:hover': {
                        backgroundColor: '#000000',
                    },
                }} variant='oulined' startIcon={<AppGalleryIcon />}><Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <RobotoTypo sx={{ textTransform: 'none', fontWeight: '400', letterSpacing: '0.03em', lineHeight: '12px', color: '#FFFFFF', fontSize: '8px', textAlign: 'left' }}>EXPLORE IT ON</RobotoTypo>
                        <RobotoTypo sx={{ textTransform: 'none', fontWeight: '500', letterSpacing: '0.03em', lineHeight: '12px', color: '#FFFFFF', fontSize: '14px', textAlign: 'left' }}>App Galery</RobotoTypo>
                    </Box></Button>

            </Box>

        </Box>
    )
}

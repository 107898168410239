import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { Box, Button } from "@mui/material";
import AboutHeader from "../components/AboutUs/AboutHeader";
import Enquire from "../components/AboutUs/Enquire";
import Services from "../components/Services";
import Teams from "../components/AboutUs/Teams";
import Footer from "../components/Footer";
import Share from "../components/Share";
import SideOverlap from "./sideOverlap";


const Aboutus = () => {
  return (
    <>
      <Header />
      <AboutHeader />
      <Enquire />
      <Services />
      <Teams />
      <Share />
      <Footer />
      <SideOverlap />
    </>
  );
};

export default Aboutus;
